import React, { FC, useEffect, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import moment from 'moment'

import CoordinatesMap from '@/components/CoordinatesMap'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button'
import { ButtonEdit } from '@/components/Ui/ButtonEdit'
import { getCurrentClientStepType } from '@/modules/Customer/utils/getCurrentClientStepType'
import api from '@/services/api'
import { RootState } from '@/store'
import { BillCreators } from '@/store/ducks/Bill'
import { Creators } from '@/store/ducks/Clients'
import { ClientStepTypeEnum } from '@/store/ducks/Clients/types'
import { insertPhoneMask } from '@/utils/insertNumberMask/insertPhoneMask'
import { TextField } from '@material-ui/core'

import { ClientDetailsEdit } from './Edit'
import * as S from './styles'

const ClientDetails: FC = () => {
  const client = useSelector((state: RootState) => state.Client.client)
  const billDetails = useSelector((state: RootState) => state.Bill.billDetails)
  const isLoading = useSelector((state: RootState) => state.Client.isLoading)
  const isEditClientDetails = useSelector(
    (state: RootState) => state.Client.isEditClientDetails,
  )
  const clientStep = getCurrentClientStepType(client.steps)
  const isClientStepInstalation = clientStep === ClientStepTypeEnum.INSTALATION

  const dispatch = useDispatch()

  const [openCoordinatesDialog, setOpenCoordinatesDialog] = useState(false)

  const [loginCodeLoading, setLoginCodeLoading] = useState<boolean>(false)
  const [loginCode, setLoginCode] = useState<string>('')

  const clientType = client?.type?.type

  const phone = client.phone.substr(2, client.phone.length)

  const generateLoginCode = () => {
    setLoginCodeLoading(true)
    api
      .get(`/admin/customers/passwords/${client.id}`)
      .then(({ data }) => {
        if (data.code) setLoginCode(data.code)
      })
      .finally(() => {
        setLoginCodeLoading(false)
      })
  }

  const updateCoordinates = (values: Record<string, unknown>) => {
    if (!client.technical_visit?.id) {
      toast.error('Não há visita técnica para este cliente')
      return
    }

    const coordinates = `${values.lat}, ${values.lng}`
    api
      .patch(
        `/admin/customers/scheduling/${client.technical_visit?.id}/coordinates`,
        {
          coordinates,
        },
      )
      .then(res => {
        if (res.data.id) {
          toast.success('Atualizado com sucesso')
          dispatch(Creators.getClientRequest({ id: client.id }))
          setOpenCoordinatesDialog(false)
        }
      })
      .catch(() => toast.error('Erro ao salvar informações'))
  }

  useEffect(() => {
    dispatch(Creators.getClientRequest({ id: client.id }))
    dispatch(BillCreators.getBillDetailsRequest())
  }, [dispatch, client.id, isEditClientDetails])

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <S.Container>
        <S.LabelContainer>
          <label>Dados do Cliente</label>
          {!isEditClientDetails && (
            <ButtonEdit
              onClick={() => dispatch(Creators.setIsEditClientDetails(true))}
            />
          )}
        </S.LabelContainer>
        {isEditClientDetails ? (
          <ClientDetailsEdit />
        ) : (
          <>
            <S.ContainerClient>
              <S.ClientDataBox>
                <S.ContainerColumn>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Nome: </S.Font> {client.name}
                    </S.Fields>
                  </S.FieldsLabel>
                </S.ContainerColumn>
                <S.ContainerColumn>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Telefone:</S.Font> {insertPhoneMask(phone)}
                    </S.Fields>
                  </S.FieldsLabel>
                </S.ContainerColumn>
                <S.ContainerColumn>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Email:</S.Font> {client.email}
                    </S.Fields>
                  </S.FieldsLabel>
                </S.ContainerColumn>
              </S.ClientDataBox>
              <S.ClientCodeBox>
                {loginCode && (
                  <TextField
                    disabled
                    variant="outlined"
                    label="Código de login"
                    inputProps={{ readonly: true }}
                    value={loginCode}
                  />
                )}
                <Button
                  onClick={generateLoginCode}
                  loading={loginCodeLoading}
                  disabled={loginCodeLoading}
                >
                  Gerar código de login
                </Button>
              </S.ClientCodeBox>
            </S.ContainerClient>
            <hr />
            <S.LabelContainer style={{ marginTop: 20 }}>
              <label>Informações da UC</label>
            </S.LabelContainer>
            <S.LabelContainer>
              <label>Titularidade</label>
            </S.LabelContainer>
            <S.ContainerGeneral>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Nome do Titular: </S.Font>
                    {billDetails.bill_owner?.name || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>{clientType} Titular:</S.Font>{' '}
                    {billDetails.bill_owner?.document_value ? (
                      <>
                        {billDetails.bill_owner?.document_value?.replace(
                          /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                          '$1.$2.$3-$4',
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Data de Nasc. do Titular: </S.Font>
                    {billDetails.bill_owner?.date_of_birth
                      ? moment(billDetails.bill_owner?.date_of_birth).format(
                          'DD/MM/YYYY',
                        )
                      : '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Telefone do Titular: </S.Font>
                    {billDetails.bill_owner?.phone || '-'}
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
            </S.ContainerGeneral>
            <S.LabelContainer>
              <label>Endereço</label>
            </S.LabelContainer>
            <S.ContainerGeneral>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>CEP: </S.Font>
                    {billDetails.bill_owner?.postal_code || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Endereço: </S.Font>
                    {billDetails.bill_owner?.address || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Número: </S.Font>
                    {billDetails.bill_owner?.address_number || '-'}
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Bairro: </S.Font>
                    {billDetails.bill_owner?.address_neighborhood || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Cidade: </S.Font>
                    {billDetails.bill_owner?.city || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Estado: </S.Font>
                    {billDetails.bill_owner?.state || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Tipo de Unidade: </S.Font>
                    {client.unit_type}
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
            </S.ContainerGeneral>
            <S.LabelContainer>
              <label>Projeto</label>
            </S.LabelContainer>
            <S.ContainerGeneral>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Potência kWp: </S.Font>
                    {client?.selected_client_plan?.total_potential || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Técnico Vinculado: </S.Font>
                    {client.unit_type}
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Coordenadas: </S.Font>
                    {client?.technical_visit?.coordinates ? (
                      <a
                        href={`https://maps.google.com/?q=${client?.technical_visit?.coordinates}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {client?.technical_visit?.coordinates}
                      </a>
                    ) : (
                      '-'
                    )}
                    <BsPencil
                      size={19}
                      onClick={() => setOpenCoordinatesDialog(true)}
                      style={{ marginLeft: '10px', cursor: 'pointer' }}
                    />
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
            </S.ContainerGeneral>
            <S.LabelContainer>
              <label>Informações da Conta de Luz</label>
            </S.LabelContainer>
            <S.ContainerGeneral>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Código do Cliente: </S.Font>
                    {billDetails?.client_code || '-'}
                  </S.Fields>
                </S.FieldsLabel>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Concessionária: </S.Font>
                    {billDetails?.dealership || '-'}
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
              <S.ContainerColumn>
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Número UC: </S.Font>
                    {billDetails?.consumer_unit || '-'}
                  </S.Fields>
                </S.FieldsLabel>
              </S.ContainerColumn>
            </S.ContainerGeneral>
            <br />
            {isClientStepInstalation && (
              <S.ContainerButton>
                <Button
                  onClick={() => dispatch(Creators.confirmInstalationRequest())}
                >
                  Confirmar instalação
                </Button>
              </S.ContainerButton>
            )}
          </>
        )}
        <CoordinatesMap
          onSubmit={updateCoordinates}
          defaultCoords={
            client?.technical_visit?.coordinates
              ? {
                  lat: parseFloat(
                    client?.technical_visit?.coordinates.split(', ')[0],
                  ),
                  lng: parseFloat(
                    client?.technical_visit?.coordinates.split(', ')[1],
                  ),
                }
              : undefined
          }
          onCloseDialog={() => setOpenCoordinatesDialog(false)}
          opened={openCoordinatesDialog}
        />
      </S.Container>
    </>
  )
}

export { ClientDetails }
