import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Redirect } from 'react-router-dom'

import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'

import { IPermission } from '@/@types/Permission'
import { Configuration } from '@/modules/Configuration/pages/main'
import { CustomerCreate } from '@/modules/Customer/pages/Customers/Create'
import { Customers } from '@/modules/Customer/pages/Customers/Customers'
import { Installation } from '@/modules/Customer/pages/Installation'
import { ClientEdit } from '@/modules/Customer/pages/Main'
import { ProjectsSupplies } from '@/modules/Customer/pages/ProjectsSupplies'
import { Financing } from '@/modules/Financing/pages/Financing'
import { Notifications } from '@/modules/Notifications/pages/main/NotificationsMain'
import { UserCreate } from '@/modules/User/pages/Create'
import { UserEdit } from '@/modules/User/pages/Edit'
import { Users } from '@/modules/User/pages/Users'
import { VisitEdit } from '@/modules/Visit/pages/Edit'
import { Visits } from '@/modules/Visit/pages/Visits/Visits'
import { RootState } from '@/store'

import ForgetPassword from '../modules/Auth/pages/ForgotPassword'
import SignIn from '../modules/Auth/pages/SignIn'
import Route from './Route'

interface RouterProps {
  history: History
}

const Routes = (props: RouterProps) => {
  const { history } = props

  const permission = useSelector((state: RootState) => state.Auth.role)

  const hasTechnicianPermission = permission === IPermission.TECHNICIAN
  const hasBIVisitorPermission = permission === IPermission.BIVISITOR

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/forgot-password" exact component={ForgetPassword} />
        <Route
          path="/customer"
          exact
          component={() =>
            // eslint-disable-next-line no-nested-ternary
            hasTechnicianPermission ? (
              <Redirect to="/visit" />
            ) : hasBIVisitorPermission ? (
              <Redirect to="/businessintelligence" />
            ) : (
              <Customers />
            )
          }
          isPrivate
        />
        <Route path="/financing" exact isPrivate component={Financing} />
        <Route
          path="/customer/:id/:section"
          exact
          component={ClientEdit}
          isPrivate
        />
        <Route
          path="/customer/create_customer"
          exact
          component={CustomerCreate}
          isPrivate
        />
        <Route path="/visit" exact component={Visits} isPrivate />
        <Route
          path="/projects_supplies"
          exact
          component={ProjectsSupplies}
          isPrivate
        />
        <Route path="/installation" exact component={Installation} isPrivate />
        <Route path="/configs" exact component={Configuration} isPrivate />
        <Route path="/visit/:id/edit" exact component={VisitEdit} isPrivate />
        <Route
          path="/notifications"
          exact
          component={Notifications}
          isPrivate
        />
        <Route path="/users" exact isPrivate component={Users} />
        <Route path="/users/:id/edit" exact component={UserEdit} isPrivate />
        <Route path="/users/create" exact component={UserCreate} isPrivate />
      </Switch>
    </ConnectedRouter>
  )
}

export default Routes
