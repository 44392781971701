/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import api from '@/services/api'
import { toast } from 'react-toastify'

import moment from 'moment'

import { ReportStepStatusEnum } from '@/@types/clientReportSteps/clientReportSteps'
import { IObjectLiteral } from '@/@types/common/object-literal'
import { IPermission } from '@/@types/Permission'
import { TechnicalVisitStatusEnum } from '@/@types/visit'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import { ReportOne } from '@/components/ReportForm/ReportOne'
import { ReportThree } from '@/components/ReportForm/ReportThree'
import { ReportTwo } from '@/components/ReportForm/ReportTwo'
import { ApprovalReport } from '@/components/ReportForm/ApprovalReport'
import { Table } from '@/components/Table/Table'
import Button from '@/components/Ui/Button/index'
import { GenericModal } from '@/components/Ui/GenericModal/GenericModal'
import { ModalJustifyTechnical } from '@/components/Ui/ModalJustifyTechnical'
import StatusCard from '@/components/Ui/Status/StatusCard'
import Steps from '@/components/Ui/Steps'
import { SelectVisit } from '@/modules/Visit/components/SelectVisit/SelectVisit'
import history from '@/services/history'
import { RootState } from '@/store'
import { ReportCreators } from '@/store/ducks/ClientReport'
import { Creators as CreatorsClient } from '@/store/ducks/Clients'
import { Creators } from '@/store/ducks/Visit'
import { insertPostalCodeMask } from '@/utils/insertNumberMask/insertPostalCodeMask'
import { getStatusVisitStyle } from '@/utils/statusVisitStyle'

import {
  Container,
  Header,
  ContainerClient,
  ContainerColumn,
  Fields,
  Font,
  ContainerSelect,
  Select,
  LabelSelect,
  FieldsLabel,
  ContainerTable,
  Status,
  GroupButton,
  ContainerReport,
  ContainerSteps,
} from './styles'
import { ReportFive } from '@/components/ReportForm/ReportFive'

interface IVisitEdit {
  id: string
}

interface ISelectedTechnician {
  value: string
  label: string
}

const VisitEdit: React.FC<RouteComponentProps<IVisitEdit>> = ({ match }) => {
  const { id } = match.params

  const dispatch = useDispatch()

  const technicians = useSelector((state: RootState) => state.Visit.technicians)

  const permission = useSelector((state: RootState) => state.Auth.role)

  const client = useSelector((state: RootState) => state.Client.client)

  const isEditReportTwo = useSelector(
    (state: RootState) => state.ClientReport.isEditReportTwo,
  )

  const ddd = client.technical_visit?.phone.substr(0, 2)
  const phone = client.technical_visit?.phone.substr(
    2,
    client.technical_visit?.phone.length,
  )

  const phoneClient = client?.phone.substr(2, client.phone.length)

  const [selectedItem, setSelectedItem] = useState<ISelectedTechnician>()
  const [isOpenedModalCancel, setIsOpenedModalCancel] = useState<boolean>(false)
  const [IsOpenedModalJustify, setIsOpenedModalJustify] = useState<boolean>(
    false,
  )
  const [IsOpenedModalTechnician, setIsOpenedModalTechnician] = useState<
    boolean
  >(false)

  const [justifyText, setJustifyText] = useState<string>()
  const [technicansSelect, setTechnicansSelect] = useState([
    { value: '', label: '' },
  ])

  const isLoading = useSelector(
    (state: RootState) =>
      state.Client.isLoading || state.ClientReport.isLoading,
  )
  const isEditReportOne = useSelector(
    (state: RootState) => state.ClientReport.isEditReportOne,
  )
  const isEditReportThree = useSelector(
    (state: RootState) => state.ClientReport.isEditReportThree,
  )
  const isEditApprovalReport = useSelector(
    (state: RootState) => state.ClientReport.isEditApprovalReport,
  )
  const isEditReportFive = useSelector(
    (state: RootState) => state.ClientReport.isEditReportFive,
  )
  const reports = useSelector((state: RootState) => state.ClientReport.reports)

  useEffect(() => {
    dispatch(ReportCreators.getReportThreeRequest())
  }, [dispatch])

  const [
    isOpenedModalCancelScheduling,
    setIsOpenedModalCancelScheduling,
  ] = useState<boolean>(false)

  const [isOpenedModalScheduling, setIsOpenedModalScheduling] = useState<
    boolean
  >(false)

  interface IOption {
    label: string
    value: number
  }

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      width: 100,
    },
    {
      title: 'Hora',
      dataIndex: 'hour',
      key: 'hour',
      width: 100,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      width: 200,
    },
    {
      title: 'Endereço',
      dataIndex: 'address',
      key: 'address',
      width: 100,
    },
    {
      title: 'Número',
      dataIndex: 'number',
      key: 'number',
      width: 100,
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      key: 'city',
      width: 100,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      width: 100,
    },
    {
      title: 'Técnico',
      dataIndex: 'technician',
      key: 'technician',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 200,
    },
    {
      title: '',
      dataIndex: 'cancel',
      key: 'cancel',
      width: 100,
    },
  ]

  const handleButtonCancelEditPage = () => {
    setIsOpenedModalCancel(true)
  }

  const clientData = [
    {
      key: '1',
      date: moment(client.technical_visit?.schedule).format('DD/MM/YYYY'),
      hour: moment(client.technical_visit?.schedule).format('HH:mm'),
      phone: client.technical_visit?.phone && `(${ddd}) ${phone}`,
      address: client.technical_visit?.address,
      neighborhood: client.technical_visit?.neighborhood,
      number: client.technical_visit?.number,
      city: client.technical_visit?.city,
      state: client.technical_visit?.state,
      technician: selectedItem?.label,
      status: (
        <StatusCard
          style={getStatusVisitStyle(client.technical_visit?.status)}
        />
      ),
      cancel: (
        <BsThreeDots
          style={{ cursor: 'pointer' }}
          onClick={handleButtonCancelEditPage}
        />
      ),
    },
  ]

  const handleChangeSelect = (event: any) => {
    setSelectedItem({ value: event.value, label: event.label })
  }

  const handleButtonConfirmEditPage = () => {
    if (selectedItem) setIsOpenedModalTechnician(true)
  }

  const handleCloseCancelVisit = () => {
    setIsOpenedModalCancel(false)
  }

  const handleConfirmCancelVisit = () => {
    setIsOpenedModalCancel(false)
    setIsOpenedModalJustify(true)
  }

  const handleCloseJustify = () => {
    setIsOpenedModalJustify(false)
  }

  const handleConfirmJustify = () => {
    const cancelVisit = {
      scheduleId: client.technical_visit?.id,
      justifyCancel: justifyText,
    }

    dispatch(Creators.visitCancelRequest(cancelVisit))
    setIsOpenedModalJustify(false)
  }

  const handleOnChange = (e: any) => {
    setJustifyText(e.target.value)
  }

  const handleConfirmTechnician = () => {
    const linkTechnician = {
      technicianId: selectedItem?.value,
      scheduleId: client.technical_visit?.id,
    }

    dispatch(Creators.visitLinkTechnicianRequest(linkTechnician))
    setIsOpenedModalTechnician(false)
  }

  const handleCloseTechnician = () => {
    setIsOpenedModalTechnician(false)
  }

  const handleCancel = () => {
    history.push('/visit')
  }

  useEffect(() => {
    if (permission === IPermission.ADMIN)
      dispatch(Creators.visitTechnicianRequest())

    dispatch(CreatorsClient.getClientRequest({ id }))
  }, [dispatch, id, permission])

  useEffect(() => {
    if (technicians) {
      const selectTechnicians = technicians?.map(data => {
        return { value: data.id, label: data.fullname }
      })
      setTechnicansSelect(selectTechnicians)
    }
  }, [technicians])

  useEffect(() => {
    if (
      client.technical_visit?.status !==
      TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN
    )
      dispatch(ReportCreators.getReportsRequest())
    // eslint-disable-next-line
  }, [
    client.id,
    isEditReportOne,
    isEditReportFive,
    isEditReportTwo,
    isEditReportThree,
    isEditApprovalReport,
  ])

  const reportStepStatus: IObjectLiteral<'concluded' | 'pending'> = {
    APPROVED: 'concluded',
    PENDING: 'pending',
  }

  const reportOne = reports?.find(item => item.step === 1)
  const reportTwo = reports?.find(item => item.step === 2)
  const approvalReport = reports?.find(item => item.step === 4)
  const reportThree = reports?.find(item => item.step === 3)
  const reportFive = reports?.find(item => item.step === 5)

  const handleFinishedVisit = useCallback(() => {
    try {
      const { subscription_lending, technical_visit } = client
      if (!technical_visit?.id)
        throw new Error('Erro atualizando o status da visita técnica!')

      const payload = { status: TechnicalVisitStatusEnum.FULFILLED }
      api.patch(
        `admin/customers/scheduling/${technical_visit.id}/status`,
        payload,
      )

      toast.success('Status da visita técnica atualizada com sucesso!')
      window.location.reload()
    } catch (error) {
      toast.error((error as ErrorEvent).message)
    }
  }, [client])

  const groupButtons = () => {
    if (permission === IPermission.ADMIN)
      return (
        <GroupButton>
          <Button
            size="default"
            color="cancel"
            type="button"
            onClick={handleCancel}
          >
            Cancelar
          </Button>

          <Button
            type="button"
            color="primary"
            size="default"
            onClick={handleButtonConfirmEditPage}
          >
            Vincular Técnico
          </Button>
        </GroupButton>
      )

    if (
      (permission === IPermission.TECHNICIAN ||
        permission === IPermission.FRANCHISEE) &&
      client.technical_visit?.status ===
        TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN
    ) {
      return (
        <GroupButton>
          <Button
            size="default"
            color="cancelWithoutWidth"
            type="button"
            onClick={() => setIsOpenedModalCancelScheduling(true)}
          >
            Negar Agendamento
          </Button>

          <Button
            type="button"
            color="primary"
            size="default"
            onClick={() => setIsOpenedModalScheduling(true)}
          >
            Confirmar Agendamento
          </Button>
        </GroupButton>
      )
    }
    return (
      <GroupButton>
        <Button
          type="button"
          size="default"
          color="cancel"
          onClick={() => history.push('/visit')}
        >
          Voltar
        </Button>
        {client.technical_visit?.status !== 'FULFILLED' && (
          <Button
            onClick={handleFinishedVisit}
            type="button"
            color="primary"
            size="default"
          >
            {isLoading ? 'Finalizando visita...' : 'Finalizar Visita Técnica'}
          </Button>
        )}
      </GroupButton>
    )
  }

  const handleCancelScheduling = () => {
    if (client.technical_visit?.id) {
      dispatch(
        Creators.visitCancelSchedulingRequest({
          scheduleId: client.technical_visit.id,
        }),
      )
      setIsOpenedModalCancelScheduling(false)
    }
  }

  const handleConfirmScheduling = () => {
    if (client.technical_visit?.id) {
      dispatch(
        Creators.visitConfirmSchedulingRequest({
          scheduleId: client.technical_visit.id,
        }),
      )

      setIsOpenedModalScheduling(false)
    }
  }

  const handleClickReport = () => {
    if (isEditReportFive) return <ReportFive />
    if (isEditReportOne) return <ReportOne />
    if (isEditReportTwo) return <ReportTwo clientId={id} />
    if (isEditApprovalReport) return <ApprovalReport clientId={id} />

    return <ReportThree />
  }

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <GenericModal
        label="Deseja realmente cancelar essa visita?"
        openModal={isOpenedModalCancel}
        onClickConfirm={handleConfirmCancelVisit}
        onClose={handleCloseCancelVisit}
      />

      <ModalJustifyTechnical
        onChange={handleOnChange}
        onClose={handleCloseJustify}
        onClickConfirm={handleConfirmJustify}
        openModal={IsOpenedModalJustify}
        label="Informe a justificativa do cancelamento"
      />

      <GenericModal
        label="Deseja realmente vincular este técnico?"
        openModal={IsOpenedModalTechnician}
        onClickConfirm={handleConfirmTechnician}
        onClose={handleCloseTechnician}
      />

      <GenericModal
        label="Deseja realmente recusar o agendamento?"
        openModal={isOpenedModalCancelScheduling}
        onClickConfirm={handleCancelScheduling}
        onClose={() => setIsOpenedModalCancelScheduling(false)}
      />

      <GenericModal
        label="Deseja realmente confirmar o agendamento?"
        openModal={isOpenedModalScheduling}
        onClickConfirm={handleConfirmScheduling}
        onClose={() => setIsOpenedModalScheduling(false)}
      />

      <PageContent
        title="Visita Técnica"
        breadcrumbsItems={[
          {
            name: 'Visita Técnica',
          },
          {
            name: 'Detalhes',
          },
        ]}
      >
        <Container>
          <Header>
            <h1>{client.name}</h1>
            <Status>
              <StatusCard
                style={getStatusVisitStyle(client.technical_visit?.status)}
                width="auto"
              />
            </Status>
          </Header>
          <ContainerClient>
            <ContainerColumn>
              <FieldsLabel>
                <Fields>
                  <Font>Cliente:</Font> {client.name}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Data:</Font>{' '}
                  {moment(client.technical_visit?.schedule).format(
                    'DD/MM/YYYY',
                  )}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Hora:</Font>{' '}
                  {moment(client.technical_visit?.schedule).format('HH:mm')}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Telefone:</Font> {client.phone && `(${ddd}) ${phone}`}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Telefone 2:</Font>{' '}
                  {client.phone && `(${ddd}) ${phoneClient}`}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>CEP:</Font>{' '}
                  {client.technical_visit?.cep &&
                    insertPostalCodeMask(client.technical_visit?.cep)}
                </Fields>
              </FieldsLabel>
            </ContainerColumn>
            <ContainerColumn>
              <FieldsLabel>
                <Fields>
                  <Font>Endereço:</Font> {client.technical_visit?.address}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Número:</Font> {client.technical_visit?.number}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Complemento:</Font> {client.technical_visit?.complement}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Bairro:</Font> {client.technical_visit?.neighborhood}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Cidade:</Font> {client.technical_visit?.city}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Estado:</Font> {client.technical_visit?.state}
                </Fields>
              </FieldsLabel>
            </ContainerColumn>

            <ContainerColumn>
              <FieldsLabel>
                <Fields>
                  <Font>Coordenadas:</Font>{' '}
                  {client?.technical_visit?.coordinates ? (
                    <a
                      href={`https://maps.google.com/?q=${client?.technical_visit?.coordinates}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {client?.technical_visit?.coordinates}
                    </a>
                  ) : (
                    '-'
                  )}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Potência:</Font>{' '}
                  {client?.selected_client_plan?.total_potential} kWp
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Nº de módulos do projeto:</Font>{' '}
                  {client?.client_project_info?.number_of_modules}
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Potência dos módulos:</Font>{' '}
                  {client?.client_project_info?.module_power} Wp
                </Fields>
              </FieldsLabel>
              <FieldsLabel>
                <Fields>
                  <Font>Concessionária:</Font> {client?.bill?.dealership}
                </Fields>
              </FieldsLabel>
            </ContainerColumn>
            {permission === IPermission.ADMIN && (
              <ContainerSelect>
                <LabelSelect>
                  <Font>Técnico: </Font>
                </LabelSelect>
                <Select>
                  <SelectVisit
                    onChange={handleChangeSelect}
                    width="140px"
                    payload={technicansSelect}
                    valueSelect={selectedItem?.value}
                  />
                </Select>
              </ContainerSelect>
            )}
          </ContainerClient>
          {permission === IPermission.ADMIN && (
            <ContainerTable>
              <Table columns={columns} data={clientData} />
            </ContainerTable>
          )}

          {isEditReportTwo ||
          isEditReportThree ||
          isEditReportOne ||
          isEditReportFive ||
          isEditApprovalReport
            ? handleClickReport()
            : (permission === IPermission.TECHNICIAN ||
                permission === IPermission.FRANCHISEE) &&
              client.technical_visit?.status !==
                TechnicalVisitStatusEnum.UNCONFIRMED_APPOINTMENT &&
              client.technical_visit?.status !==
                TechnicalVisitStatusEnum.WAITING_FOR_TECHNICIAN && (
                <>
                  <ContainerReport>
                    <h1>Relatórios</h1>
                    <ContainerSteps>
                      <div>
                        <Steps
                          descriptionSteps="Relatório Fotográfico"
                          titleSteps="Etapa 1"
                          type={
                            reportStepStatus[
                              reportOne?.status || ReportStepStatusEnum.PENDING
                            ]
                          }
                          onClick={() =>
                            dispatch(ReportCreators.setIsReportOne(true))
                          }
                        />
                      </div>
                      <div>
                        <Steps
                          descriptionSteps="Relatório Discritivo"
                          titleSteps="Etapa 2"
                          type={
                            reportStepStatus[
                              reportTwo?.status || ReportStepStatusEnum.PENDING
                            ]
                          }
                          onClick={() =>
                            dispatch(
                              ReportCreators.setIsEditReportTwoTechnician(true),
                            )
                          }
                        />
                      </div>
                      {permission !== IPermission.FRANCHISEE && (
                        <div>
                          <Steps
                            descriptionSteps="Aprovação"
                            titleSteps="Etapa 3"
                            type={
                              reportStepStatus[
                                approvalReport?.status ||
                                  ReportStepStatusEnum.PENDING
                              ]
                            }
                            onClick={() =>
                              dispatch(ReportCreators.setIsApprovalReport(true))
                            }
                          />
                        </div>
                      )}
                    </ContainerSteps>
                  </ContainerReport>
                </>
              )}
          {!isEditReportOne &&
            !isEditReportFive &&
            !isEditReportTwo &&
            !isEditReportThree &&
            !isEditApprovalReport &&
            groupButtons()}
        </Container>
      </PageContent>
    </>
  )
}

export { VisitEdit }
