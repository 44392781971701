export default {
  black: '#000000',
  white: '#ffffff',
  gray: {
    100: '#f6f6f6',
    150: '#f2f4f7',
    200: '#f9f9f9',
    250: '#a3b1cc',
    300: '#d8d8d8',
    400: '#cfcfcf',
    500: '#6c6c6c',
    550: '#707070',
    600: '#3b3b3b',
    700: '#1a1818',
  },
  blue: {
    100: '#5c93c9',
    200: '#195ab4',
  },
  pink: '#ffced3',
  lightBlue: '#BCCCE9',
  red: {
    100: '#ffbec3',
    200: '#ff041a',
    300: '#ff0000',
    400: '#ffc7cc',
  },
  orange: {
    100: '#ffe2cf',
    200: '#ff7600',
  },
  green: {
    100: '#cff3bc',
    200: '#43cc2a',
    300: '#43cc00',
  },
  primary: '#0B2859',
  yellow: {
    100: '#FFFBE6',
    200: '#FFF5BF',
    300: '#FFEE99',
    400: '#FFE24D',
    500: '#FFD500',
    600: '#E6C000',
    700: '#998000',
    800: '#736000',
    900: '#4D4000',
    1000: '#FFFBE1',
  },
}
