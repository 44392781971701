import React, { useState, useEffect, FC, useCallback, useMemo } from 'react'
import { FiMenu } from 'react-icons/fi'

import styled from 'styled-components'

import LogoBTG from '@/assets/images/logoBTG.svg'
import theme from '@/assets/styles/theme'
import Footer from '@/components/Footer'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import { useWindowSize } from '@/hooks/useWindowSize'

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas: 'header' 'main' 'footer';
  height: 100vh;
  overflow-x: hidden;

  @media only screen and (min-width: 46.875em) {
    display: grid;
    grid-template-columns: 350px calc(100% - 350px);
    grid-template-rows: 105px 1fr 50px;
    grid-template-areas: 'sidenav header' 'sidenav main' 'sidenav footer';
    height: 100vh;
  }
`

export const Hambuguer = styled.div`
  width: 100%;
  min-height: 4rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-left: 1.5rem;
  position: fixed;
  z-index: 1000;
  cursor: pointer;
  background-color: ${theme.colors.white};
`

const Main = styled.main`
  background-color: ${theme.colors.gray[100]};
  grid-area: main;
  padding: 0 30px;

  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 0 15px;
  }
`

export const Logo = styled.div`
  align-items: center;
  justify-content: center;
  /* background-color: ${theme.colors.primary}; */
  display: flex;
  height: 1rem;
`

const DefaultLayout: FC = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true)
  const [isUpbarOpen, setIsUpbarOpen] = useState<boolean>(false)

  const { width } = useWindowSize()

  const isSmallView = useMemo(() => !!width && width < 750, [width])

  useEffect(() => {
    setIsUpbarOpen(isSmallView)
    setIsSidebarOpen(!isSmallView)
  }, [isSmallView])

  const handleSidebarVisibility = useCallback(() => {
    if (isSmallView) setIsSidebarOpen(false)
  }, [isSmallView])

  return (
    <Container>
      {isUpbarOpen && (
        <Hambuguer>
          <FiMenu size={25} onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
          <Logo>
            <img src={LogoBTG} alt="BancoBTG" height="30rem" />
          </Logo>
        </Hambuguer>
      )}
      <Header />
      <Sidebar onClick={handleSidebarVisibility} isOpen={isSidebarOpen} />
      <Main>{children}</Main>
      <Footer />
    </Container>
  )
}

export default DefaultLayout
