import { toast } from 'react-toastify'

import { call, put, select } from 'redux-saga/effects'

import api from '@/services/api'
import history from '@/services/history'
import { RootState } from '@/store'

import { Creators } from '../../ducks/Users'
import {
  IEditUserRequestPayload,
  IListUserQuery,
  ICreateUserRequestPayload,
} from '../../ducks/Users/types'

const errors: { [key: number]: string } = {
  1017: 'CPF inválido',
  2002: 'Senha muito fraca. É necessário que a senha contenha letras maiúsculas e minúsculas, números e caracteres especiais.',
  1009: 'As senhas não coincidem',
  4000: 'O CPF já está sendo usado',
  4005: 'Você não pode editar seu próprio status',
  4006: 'Você não pode editar sua permissão',
}

export function* editUser({ editUser }: { editUser: IEditUserRequestPayload }) {
  const getUserId = (state: RootState) => state.User.user.id
  const userId = yield select(getUserId)

  try {
    yield call(api.put, `/admin/users/${userId}`, editUser)
    yield put(Creators.editUserSuccess())
    toast.success('Usuário editado com sucesso')
    history.push('/users')
  } catch (error) {
    toast.error(
      errors[error.response.data.code] || 'Não foi possível editar o usuário',
    )
    yield put(Creators.editUserFailure())
  }
}
export function* getUsers(query: IListUserQuery) {
  try {
    const { data: users } = yield call(
      api.get,
      `/admin/users?limit=7&page=${query.page}&search=${query.search}`,
    )
    yield put(Creators.getUsersSuccess(users))
  } catch (error) {
    toast.error('Não foi possível listar os usuários')
    yield put(Creators.getUsersFailure())
  }
}

export function* createUser({
  newUser,
}: {
  newUser: ICreateUserRequestPayload
}) {
  try {
    yield call(api.post, '/admin/users', newUser)
    yield put(Creators.createUserSuccess())
    toast.success('Usuário criado com sucesso')
    history.push('/users')
  } catch (error) {
    toast.error('Erro ao criar usuário!')
    yield put(Creators.createUserFailure())
  }
}

export function* getUser({ id }: { id: string }) {
  try {
    const { data: user } = yield call(api.get, `/admin/users/${id}`)
    yield put(Creators.getUserSuccess(user))
  } catch (error) {
    toast.error('Não foi possível carregar o usuário')
    yield put(Creators.getUserFailure())
  }
}
