/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { eq, isEmpty, last } from 'lodash'
import styled from 'styled-components'

import { IObjectLiteral } from '@/@types/common/object-literal'
import { IPermission } from '@/@types/Permission'
import theme from '@/assets/styles/theme'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PortalFileInput from '@/components/PortalFileInput'
import Button from '@/components/Ui/Button'
import { ButtonEdit } from '@/components/Ui/ButtonEdit'
import Input from '@/components/Ui/Form/Input'
import { GenericModal } from '@/components/Ui/GenericModal'
import { Select } from '@/components/Ui/Select'
import api from '@/services/api'
import { RootState } from '@/store'
import { BillCreators } from '@/store/ducks/Bill'
import { Creators } from '@/store/ducks/Clients'
import { Creators as CreatorsVisit } from '@/store/ducks/Visit'
import { insertBRNumberMask } from '@/utils/insertNumberMask/insertBRNumberMask'
import { Switch } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'

import { EditClientBoard } from './EditClientBoard'
import { EditClientCommissionInstallationCost } from './EditClientCommissionInstallationCost'
import { EditClientEnergyConsumption } from './EditClientEnergyConsumption'
import { EditClientTariff } from './EditClientTariff'
import { PlanGroupProposal } from './PlanGroupProposal'
import * as S from './styles'

export type ProposalType = {
  id: string
  created_at: string
  updated_at: string
  power_of_the_project: number
  client_id: string
  distributor_kit_cost: number
  sale_value: number
  franchisee_commission: number
  final_value: number
  final_installed_wp: number
  value_kit_for_customer: number
  nf_value: number
  tax_solar21: number
  contribution_margin: number
  total_amount_receivable_franchisee: number
  inverter_quantity: number
  inverter_power: number
  inverter_brand: string
  inverter_warranty: string
  module_quantity: number
  module_brand: string
  module_power: number
  annual_production: number
  annual_savings: number
  payback: number
  module_warranty: number
  type: string
  can_edit?: boolean
}

type UpdateProjectInfoType = {
  [key: string]: string | undefined
  structure?: string
  new_standard_justify?: string
  technical_comment?: string
}

const Styles = {
  FormTechnicalComment: styled(Form)`
    margin-top: 30px;
    input {
      background-color: #f6f6f6;
    }
  `,
  ContainerForm: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    button {
      margin-left: 10px;
    }
  `,
}

type Item = { value: string; label: string }

const ReportFour = ({ isDisabled }: { isDisabled: boolean }) => {
  const dispatch = useDispatch()

  const [isProposalSent, setIsProposalSent] = useState<boolean>(false)
  const [fileUrl, setFileUrl] = useState<string | undefined>(undefined)
  const [proposalToUpload, setProposalToUpload] = useState<File | null>(null)
  const [proposal, setProposal] = useState<ProposalType | undefined>()
  const [hasFinalProposal, setHasFinalProposa] = useState<boolean>(false)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [proposalSimulation, setProposalSimulation] = useState<any>()

  const commentFormRef = useRef<FormHandles>(null)
  const newConsumptionFormRef = useRef<FormHandles>(null)

  const permission = useSelector((state: RootState) => state.Auth.role)
  const isFranchisee = permission === IPermission.FRANCHISEE
  const isAdmin = permission === IPermission.ADMIN

  let { user_id: userId } = useSelector((state: RootState) => state.Auth)

  if (userId == null) userId = ''

  const {
    client,
    clientProjectInfo,
    isLoading,
    isEditClientTariff,
    isEditClientEnergyConsumption,
    isEditClientBoard,
    clientSimulatedPlanValues,
    freeMonths: free,
  } = useSelector((state: RootState) => state.Client)
  const {
    amount: billAmount,
    new_consumption_media: newAvgConsumption,
    id: billId,
  } = useSelector((state: RootState) => state.Bill.billDetails)

  const technicalVisitStep =
    client.steps?.find(step => step.type === 'TECHNICAL_VISIT')?.status ===
    'APPROVED'

  const [typeKit, setTypeKit] = useState<string>('')
  const [optionsTypes, setOptionsTypes] = useState<Item[]>([])
  const [signatureContract, setSignatureContract] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [hasNewAvg, setHasNewAvg] = useState<boolean>(!!newAvgConsumption)
  const [newAvgValue, setNewAvgValue] = useState<number>(newAvgConsumption || 0)
  const [disableAvgForm, setDisableAvgForm] = useState<boolean>(false)
  const [hasFreeMonth, setHasFreeMonth] = useState<boolean>(!eq(free?.max, 0))
  const [justifyStandard, setJustifyStandard] = useState<string>('')
  const [structure, setStructure] = useState<string>('')
  const [technicalComment, setTechnicalComment] = useState<string>('')
  const [specificProduction, setspecificProduction] = useState<boolean>(false)
  const [kit_price, setKitPrice] = useState<string | undefined>('')
  const [installationMaterialCost, setInstallationMaterialCost] = useState<
    string | undefined
  >('')

  useEffect(() => {
    if (!client) return

    setKitPrice(
      client?.client_project_info?.commission.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    )
    setInstallationMaterialCost(
      client?.client_project_info?.installation_material_cost.toLocaleString(
        'pt-BR',
        {
          style: 'currency',
          currency: 'BRL',
        },
      ),
    )
  }, [client])

  useEffect(() => {
    if (isEmpty(proposal)) {
      api
        .get(`admin/customers/${client.id}/proposal`)
        .then(({ data }) => {
          const filteredData = data.filter(
            (item: ProposalType) => item.type === 'INITIAL',
          )
          setProposal(last(filteredData))
          const finalProposa = data.filter(
            (item: ProposalType) => item.type === 'FINAL',
          )
          setHasFinalProposa(!!finalProposa.length)
        })
        .catch(() => toast.error('Erro ao rucuperar a proposta!'))
    }
  }, [proposal, client])

  useEffect(() => {
    const loadKitTypes = async () => {
      if (!proposalSimulation) return

      const res = await api.get(
        `admin/get_kit_types/${clientProjectInfo?.structure}/${proposalSimulation.power_of_the_project}/${client.id}`,
      )

      const newOptions = res.data.map(
        (e: { inverterBrand: string; moduleBrand: string }) => {
          const newItem: Item = {
            value: `${e.inverterBrand} - ${e.moduleBrand}`,
            label: `Inversor: ${e.inverterBrand} / Módulo: ${e.moduleBrand}`,
          }
          return newItem
        },
      )

      setOptionsTypes(newOptions)
    }

    loadKitTypes()
  }, [proposalSimulation, clientProjectInfo, client])

  useEffect(() => {
    if (isEmpty(proposal)) {
      api
        .get(`admin/customers/${client.id}/proposal`)
        .then(({ data }) => {
          const filteredData = data.filter(
            (item: ProposalType) => item.type === 'INITIAL',
          )
          setProposal(last(filteredData))
          const btgData = data.filter(
            (item: ProposalType) => item.type === 'BTG',
          )
          setFileUrl(btgData[0]?.pdf_link)
          setIsProposalSent(btgData.length)
        })
        .catch(() => toast.error('Proposal retrieving did not work!'))
    }
  }, [proposal, client])

  useEffect(() => {
    if (!proposalToUpload) return

    const formData = new FormData()
    formData.append('file', proposalToUpload)
    api
      .post(`/admin/uploads/proposal/${client.id}`, formData)
      .then(() => toast.success('Arquivo enviado com sucesso!'))
      .catch(() => toast.error('O arqiuvo não pode ser enviado...'))
  }, [proposalToUpload, client.id])

  useEffect(() => {
    if (!clientProjectInfo) return

    const {
      structure,
      technical_comment,
      new_standard_justify,
    } = clientProjectInfo

    setStructure(structure ?? '')
    setTechnicalComment(technical_comment ?? '')
    setJustifyStandard(new_standard_justify ?? '')
  }, [clientProjectInfo])

  useEffect(() => {
    if (client.technical_comments) setComment(client.technical_comments)
  }, [client])

  useEffect(() => {
    if (
      !isEditClientTariff &&
      !isEditClientEnergyConsumption &&
      !isEditClientBoard
    ) {
      dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
      dispatch(BillCreators.getBillDetailsRequest())
    }
  }, [
    client.id,
    dispatch,
    isEditClientTariff,
    isEditClientEnergyConsumption,
    isEditClientBoard,
  ])

  useEffect(() => {
    if (billAmount) {
      const clientSimulationData: IObjectLiteral<number> = {}

      if (clientProjectInfo)
        Object.entries(clientProjectInfo).forEach(([key, value]) => {
          if (value) clientSimulationData[key] = value
        })

      dispatch(
        Creators.getClientSimulationByClientIdRequest({
          ...clientSimulationData,
          id: client.id,
          bill_value: billAmount,
        }),
      )
    }
  }, [billAmount, clientProjectInfo, dispatch, client])

  useEffect(() => {
    api.get(`/admin/check_signers/${client.id}`).then(({ data }) => {
      setSignatureContract(data.is_sign.is_signed)
    })
  }, [client])

  useEffect(() => {
    dispatch(Creators.getClientFreeMonthRequest())
  }, [dispatch])

  useEffect(() => {
    setHasFreeMonth(!eq(free?.max, 0))
  }, [free])

  const editForm = useCallback(() => {
    if (isEditClientTariff) {
      return (
        <S.ContainerLegal>
          <S.LabelContainer>
            <label>Tarifas</label>
            {!isEditClientTariff && (
              <ButtonEdit
                disabled={(hasFinalProposal && isFranchisee) || isDisabled}
                onClick={() => dispatch(Creators.setIsEditClientTariff(true))}
              />
            )}
          </S.LabelContainer>

          <EditClientTariff
            onClickToCancel={() =>
              dispatch(Creators.setIsEditClientTariff(false))
            }
          />
        </S.ContainerLegal>
      )
    }

    if (isEditClientEnergyConsumption) {
      return (
        <S.ContainerLegal>
          <S.LabelContainer>
            <label>Consumo de Energia</label>
            {!isEditClientEnergyConsumption && (
              <ButtonEdit
                disabled={isDisabled}
                onClick={() =>
                  dispatch(Creators.setIsEditClientEnergyConsumption(true))
                }
              />
            )}
          </S.LabelContainer>

          <EditClientEnergyConsumption
            onClickToCancel={() =>
              dispatch(Creators.setIsEditClientEnergyConsumption(false))
            }
          />
        </S.ContainerLegal>
      )
    }
    return (
      <S.ContainerLegal>
        <S.LabelContainer>
          {client.subscription_lending !== 'LENDING' || specificProduction ? (
            <label>Info de Projeto</label>
          ) : (
            <label>Custos</label>
          )}
          {!isEditClientBoard && (
            <ButtonEdit
              disabled={isDisabled}
              onClick={() => {
                setspecificProduction(false)
                dispatch(Creators.setIsEditClientBoard(true))
              }}
            />
          )}
        </S.LabelContainer>

        {client.subscription_lending !== 'LENDING' && (
          <EditClientBoard
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}

        {client.subscription_lending === 'LENDING' && !specificProduction && (
          <EditClientCommissionInstallationCost
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}

        {client.subscription_lending === 'LENDING' && specificProduction && (
          <EditClientBoard
            onClickToCancel={() => {
              dispatch(Creators.setIsEditClientBoard(false))
              setspecificProduction(false)
            }}
          />
        )}
      </S.ContainerLegal>
    )
  }, [
    dispatch,
    client.subscription_lending,
    isEditClientBoard,
    isEditClientEnergyConsumption,
    isEditClientTariff,
    specificProduction,
    hasFinalProposal,
    isFranchisee,
    isDisabled,
  ])

  const changeComment = useCallback(() => {
    api
      .put(`/admin/customers/technical_comment/${client.id}`, { comment })
      .then(({ data }) => {
        if (data.affected) toast.success('Atualizado')
      })
  }, [client, comment])

  const clearNewConsumption = useCallback(() => {
    setDisableAvgForm(true)
    api
      .put(`/admin/customers/${billId}/new_consumption_media`, {
        new_consumption_media: null,
      })
      .then(({ data }) => {
        if (data.affected) {
          toast.success('Media atualizada')
          dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
        }
      })
      .finally(() => setDisableAvgForm(false))
  }, [billId, dispatch, client])

  const onChangeSwitchNewConsumption = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setHasNewAvg(prev => {
        if (prev) clearNewConsumption()

        return e.target.checked
      })
    },
    [clearNewConsumption],
  )

  const submitNewConsumptionMediaValue = () => {
    /* eslint-disable */
    const new_consumption_media = newConsumptionFormRef.current?.getFieldValue(
      'new_consumption_media_value',
    )
    setDisableAvgForm(true)

    api
      .put(`/admin/customers/${billId}/new_consumption_media`, {
        new_consumption_media,
      })
      .then(({ data }) => {
        if (data.affected) {
          toast.success('Media atualizada')
          dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
        }
      })
      .finally(() => setDisableAvgForm(false))

    /* eslint-enable */
  }

  const generatePlans = useCallback(() => {
    // eslint-disable-next-line
    let payload: any = {
      bill_value: client.bill.amount,
      solar21_tax: 0,
      not_compensated_tax: 0,
      dealership_tax: clientProjectInfo?.dealership_tax,
      dry_tax: 0,
      kwh_availability: 0,
      concurrency_factor: clientProjectInfo?.concurrency_factor,
      module_power: 470,
      total_losses: 0.2,
      franchisee_commission_percent: clientProjectInfo?.commission,
      installation_material_cost: clientProjectInfo?.installation_material_cost,
      structure_type: clientProjectInfo?.structure,
      specific_production: clientProjectInfo?.specific_production,
      optimizer_quantity: clientProjectInfo?.optimizer_quantity,
    }

    if (typeKit !== '') {
      const split = typeKit.split(' - ')
      // eslint-disable-next-line prefer-destructuring
      payload.inverter_brand = split[0]
      // eslint-disable-next-line prefer-destructuring
      payload.module_brand = split[1]
    }
    api
      .post(`admin/customers/${client.id}/proposal_final/false`, payload)
      .then(() => {
        toast.success('Plano gerado com sucesso!')
      })
      .catch(() => toast.error('Erro ao gerar o plano!'))
      .finally(() => {
        dispatch(CreatorsVisit.setIsReportFour(false))
      })
  }, [client, clientProjectInfo, dispatch, typeKit])

  const optionsSelect = [
    { value: 'Cerâmica', label: 'Cerâmica' },
    { value: 'Fibrocimento', label: 'Fibrocimento' },
    { value: 'Metálica', label: 'Metálica' },
  ] as const

  useEffect(() => {
    api
      .post(`admin/customers/${client.id}/proposal_final/true`, {
        bill_value: client.bill.amount,
        solar21_tax: 0,
        not_compensated_tax: 0,
        dealership_tax: clientProjectInfo?.dealership_tax,
        dry_tax: 0,
        kwh_availability: 0,
        concurrency_factor: clientProjectInfo?.concurrency_factor,
        module_power: 470,
        total_losses: 0.2,
        franchisee_commission_percent: clientProjectInfo?.commission,
        installation_material_cost:
          clientProjectInfo?.installation_material_cost,
        structure_type: clientProjectInfo?.structure,
        specific_production: clientProjectInfo?.specific_production,
        optimizer_quantity: clientProjectInfo?.optimizer_quantity,
      })
      .then(({ data }) => {
        setProposalSimulation(data.proposal)
      })
  }, [clientProjectInfo, client.bill.amount, client.id, typeKit])

  const saveProjectInfo = (data: UpdateProjectInfoType) => {
    api
      .patch(`/admin/update/project_info/${client.id}`, data)
      .then(() => {
        const successMessages = [
          { key: 'structure', message: 'Estrutura salva com sucesso!' },
          {
            key: 'new_standard_justify',
            message: 'Justificativa salva com sucesso!',
          },
          {
            key: 'technical_comment',
            message: 'Observação salva com sucesso!',
          },
        ]

        successMessages.forEach(({ key, message }) => {
          if (data[key]) {
            toast.success(message)
          }
        })

        dispatch(Creators.getClientProjectInfoRequest({ id: client.id }))
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error('Error updating clients project info: ', err))
  }

  const handleOpenFile: () => void = useCallback(() => {
    if (!fileUrl)
      toast.error('Ainda não existe um arquivo para ser visualizado!')
    window.open(fileUrl, '_blank')
  }, [fileUrl])

  return (
    <>
      {isLoading && <FullScreenLoader />}

      <S.Container>
        <div style={{ marginTop: '25px' }}>
          <S.Header style={{ marginTop: '20px' }}>
            <h1>Relatório - Etapa 3</h1>
          </S.Header>
        </div>

        {isEditClientTariff ||
        isEditClientEnergyConsumption ||
        isEditClientBoard ? (
          editForm()
        ) : (
          <>
            <S.ContainerLegal>
              <S.LabelContainer>
                <label>Tarifas e Consumo de Energia</label>
                {(isFranchisee && !signatureContract && !technicalVisitStep) ||
                isAdmin ? (
                  <ButtonEdit
                    disabled={isDisabled}
                    onClick={() =>
                      dispatch(Creators.setIsEditClientTariff(true))
                    }
                  />
                ) : (
                  ''
                )}
              </S.LabelContainer>

              <S.ContainerTariff>
                <S.ContainerColumnTariffGG>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Tarifa não compensada:</S.Font> R${' '}
                      {clientProjectInfo?.not_compensated_tax
                        ?.toFixed(2)
                        .replace('.', ',')}
                    </S.Fields>
                  </S.FieldsLabel>
                  {client.subscription_lending === 'SUBSCRIPTION' ? (
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Tarifa Solar21:</S.Font> R${' '}
                        {clientProjectInfo?.solar21_tax
                          ?.toFixed(2)
                          .replace('.', ',')}
                      </S.Fields>
                    </S.FieldsLabel>
                  ) : (
                    <div />
                  )}
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Média (kWh):</S.Font>{' '}
                      {clientSimulatedPlanValues &&
                        insertBRNumberMask(
                          clientSimulatedPlanValues?.energy_consumption.average,
                        )}
                    </S.Fields>
                  </S.FieldsLabel>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Total (kWh):</S.Font>{' '}
                      {clientSimulatedPlanValues &&
                        insertBRNumberMask(
                          clientSimulatedPlanValues?.energy_consumption.total,
                        )}
                    </S.Fields>
                  </S.FieldsLabel>
                </S.ContainerColumnTariffGG>
                <S.ContainerColumnTariffGG>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Tarifa concessionária: </S.Font> R${' '}
                      {clientProjectInfo?.dealership_tax
                        ?.toFixed(2)
                        .replace('.', ',')}
                    </S.Fields>
                  </S.FieldsLabel>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Disponibilidade (kWh):</S.Font>{' '}
                      {clientProjectInfo?.kwh_availability}
                    </S.Fields>
                  </S.FieldsLabel>
                  <S.FieldsLabel>
                    <S.Fields>
                      <S.Font>Fator de Simultaneidade (%):</S.Font>{' '}
                      {clientProjectInfo?.concurrency_factor &&
                        `${clientProjectInfo?.concurrency_factor * 100} %`}
                    </S.Fields>
                  </S.FieldsLabel>
                </S.ContainerColumnTariffGG>
              </S.ContainerTariff>
            </S.ContainerLegal>
            <S.ContainerLegal>
              <S.LabelContainer>
                <label>
                  Cliente optante por nova média de consumo?{' '}
                  <S.SlipSwitch
                    style={{ color: theme.colors.primary }}
                    checked={hasNewAvg}
                    onChange={onChangeSwitchNewConsumption}
                    disabled={(hasFinalProposal && isFranchisee) || isDisabled}
                  />
                </label>
              </S.LabelContainer>

              {hasNewAvg && (
                <Styles.FormTechnicalComment
                  ref={newConsumptionFormRef}
                  onSubmit={submitNewConsumptionMediaValue}
                  style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Styles.ContainerForm>
                    <Input
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(e: ChangeEvent<any>) =>
                        setNewAvgValue(e.target.value)
                      }
                      disabled={disableAvgForm || isDisabled}
                      name="new_consumption_media_value"
                      value={newAvgValue}
                      autoComplete="new-password"
                      label="Nova média de consumo (kWh)"
                      width="100%"
                      type="text"
                    />

                    <Button
                      disabled={isDisabled}
                      onClick={submitNewConsumptionMediaValue}
                      loading={!newAvgValue || disableAvgForm}
                      size="default"
                      type="button"
                    >
                      Salvar
                    </Button>
                  </Styles.ContainerForm>
                  <Styles.ContainerForm>
                    <Input
                      // eslint-disable-next-line
                      onChange={(e: any) => setJustifyStandard(e.target.value)}
                      name="newconsumption_justify"
                      value={justifyStandard}
                      autoComplete="new-password"
                      label="Justificativa para novo padrão de consumo"
                      width="100%"
                      type="text"
                      disabled={isDisabled}
                    />

                    <Button
                      onClick={() =>
                        saveProjectInfo({
                          new_standard_justify: justifyStandard,
                        })
                      }
                      loading={!justifyStandard}
                      size="default"
                      type="button"
                      disabled={isDisabled}
                    >
                      Salvar
                    </Button>
                  </Styles.ContainerForm>
                </Styles.FormTechnicalComment>
              )}
            </S.ContainerLegal>
            {/* /new consumption */}
            <S.ContainerLegal>
              {[
                'aab26718-5c70-4953-ada9-ae3cf6cafc8a',
                'c1cd2c83-6133-4a11-9e37-5196761ff289',
                '52521cc3-3aba-471e-b75e-0051dcb6637d',
                '446cca4a-84d9-403b-975d-7983883369cc',
                '446cca4a-84d9-403b-975d-7983883369cc',
                'ac84a385-80ff-4efb-8dc2-af39428a61f2',
              ].includes(userId) ? (
                <S.LabelContainer>
                  {client.subscription_lending === 'SUBSCRIPTION' ? (
                    <label>
                      Mensalidade grátis
                      <Switch
                        disabled={isDisabled}
                        color="primary"
                        checked={hasFreeMonth}
                        onChange={() =>
                          setHasFreeMonth(currentState => !currentState)
                        }
                      />
                    </label>
                  ) : (
                    ''
                  )}
                </S.LabelContainer>
              ) : (
                ''
              )}
            </S.ContainerLegal>
            {client.subscription_lending === 'SUBSCRIPTION' ? (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>Info de Projeto</label>
                  {(isFranchisee &&
                    !signatureContract &&
                    !technicalVisitStep) ||
                  isAdmin ? (
                    <ButtonEdit
                      disabled={isDisabled}
                      onClick={() => {
                        setspecificProduction(false)
                        dispatch(Creators.setIsEditClientBoard(true))
                      }}
                    />
                  ) : (
                    ''
                  )}
                </S.LabelContainer>

                <S.ContainerTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Potência da placa (Wp):</S.Font>{' '}
                        {clientProjectInfo?.module_power}
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Número de módulos do projeto:</S.Font>{' '}
                        {clientProjectInfo?.number_of_modules}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Número de módulos sugerido:</S.Font>{' '}
                        {clientSimulatedPlanValues &&
                          clientSimulatedPlanValues.photovoltaic_system
                            .suggested_number_of_project_modules}
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Perdas totais (%):</S.Font>{' '}
                        {clientProjectInfo?.total_losses &&
                          `${clientProjectInfo.total_losses * 100}`}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Potência do inversor (kW):</S.Font>{' '}
                        {clientProjectInfo?.inverter_power &&
                          `${clientProjectInfo.inverter_power}`}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                </S.ContainerTariff>
              </S.ContainerLegal>
            ) : (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>Info de Projeto</label>
                  {!isFranchisee ? (
                    <ButtonEdit
                      disabled={isDisabled}
                      onClick={() => {
                        setspecificProduction(true)
                        dispatch(Creators.setIsEditClientBoard(true))
                      }}
                    />
                  ) : null}
                </S.LabelContainer>

                <S.ContainerTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Produção específica:</S.Font>{' '}
                        {(clientProjectInfo?.specific_production || 0)
                          .toString()
                          .replace('.', ',')}{' '}
                        kWh/kWp.ano
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Número de otimizadores:</S.Font>{' '}
                        {(clientProjectInfo?.optimizer_quantity || 0)
                          .toString()
                          .replace('.', ',')}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                </S.ContainerTariff>
              </S.ContainerLegal>
            )}
            {client.subscription_lending === 'LENDING' ? (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>Custos</label>
                  {(isFranchisee &&
                    !signatureContract &&
                    !technicalVisitStep) ||
                  isAdmin ? (
                    <ButtonEdit
                      disabled={
                        (hasFinalProposal && isFranchisee) || isDisabled
                      }
                      onClick={() => {
                        setspecificProduction(false)
                        dispatch(Creators.setIsEditClientBoard(true))
                      }}
                    />
                  ) : (
                    ''
                  )}
                </S.LabelContainer>
                <S.ContainerTariff>
                  <S.ContainerColumnTariff>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Valor do kit no distribuidor:</S.Font>{' '}
                        {kit_price}
                      </S.Fields>
                    </S.FieldsLabel>
                    <S.FieldsLabel>
                      <S.Fields>
                        <S.Font>Valor do serviço:</S.Font>{' '}
                        {installationMaterialCost}
                      </S.Fields>
                    </S.FieldsLabel>
                  </S.ContainerColumnTariff>
                </S.ContainerTariff>
              </S.ContainerLegal>
            ) : (
              ''
            )}
            <Styles.FormTechnicalComment
              ref={commentFormRef}
              onSubmit={changeComment}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Styles.ContainerForm>
                <Select
                  placeholder="Selecione..."
                  label="Tipo de estrutura"
                  size="custom"
                  isDisabled={technicalVisitStep || isDisabled}
                  defaultValue={optionsSelect[0]}
                  options={optionsSelect}
                  value={optionsSelect?.find(
                    state => state.label === structure,
                  )}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(state: any) => setStructure(state.label)}
                  isSearchable={false}
                />
                {(isFranchisee && !signatureContract && !technicalVisitStep) ||
                isAdmin ? (
                  <Button
                    style={{ marginTop: 20 }}
                    onClick={() => saveProjectInfo({ structure })}
                    loading={!structure}
                    size="default"
                    type="button"
                    disabled={(hasFinalProposal && isFranchisee) || isDisabled}
                  >
                    Salvar
                  </Button>
                ) : (
                  ''
                )}
              </Styles.ContainerForm>
              {proposalSimulation && (
                <Styles.ContainerForm>
                  <Select
                    placeholder="Selecione..."
                    label="Sistemas Equivalentes"
                    size="custom"
                    isDisabled={technicalVisitStep || isDisabled}
                    defaultValue={optionsTypes[0]}
                    options={optionsTypes}
                    value={optionsTypes?.find(state => state.value === typeKit)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(state: any) => setTypeKit(state.value)}
                    isSearchable={false}
                  />
                </Styles.ContainerForm>
              )}
              <div style={{ height: 40 }} />
              <Styles.ContainerForm>
                <Input
                  // eslint-disable-next-line
                  onChange={(e: any) => setTechnicalComment(e.target.value)}
                  name="technical_comments"
                  value={technicalComment}
                  autoComplete="new-password"
                  label="Observações"
                  width="100%"
                  type="text"
                  disabled={isDisabled}
                />
                {(isFranchisee && !signatureContract && !technicalVisitStep) ||
                isAdmin ? (
                  <Button
                    onClick={() =>
                      saveProjectInfo({ technical_comment: technicalComment })
                    }
                    loading={!technicalComment}
                    size="default"
                    type="button"
                    disabled={(hasFinalProposal && isFranchisee) || isDisabled}
                  >
                    Salvar
                  </Button>
                ) : (
                  ''
                )}
              </Styles.ContainerForm>
            </Styles.FormTechnicalComment>
            {clientSimulatedPlanValues && (
              <S.ContainerLegal>
                <S.LabelContainer>
                  <label>Simulação da proposta final</label>
                </S.LabelContainer>
                {client.subscription_lending === 'LENDING' &&
                (clientProjectInfo?.specific_production || 1) > 500 ? (
                  <PlanGroupProposal
                    simulationValues={
                      proposalSimulation != null ? proposalSimulation : null
                    }
                  />
                ) : (
                  ''
                )}
              </S.ContainerLegal>
            )}
            <S.ProposalContainer>
              <S.TitleProposal>Proposta</S.TitleProposal>
              <PortalFileInput
                header={
                  isProposalSent
                    ? 'Proposta enviada com sucesso!'
                    : 'Envie a sua proposta:'
                }
                text="Arraste ou clique aqui para fazer upload"
                mimes={['PDF', 'JPG', 'PNG']}
                onChange={setProposalToUpload}
                hasFile={!!proposalToUpload}
                isFileSent={isProposalSent}
                isDisabled={isDisabled}
              />
            </S.ProposalContainer>
            {isProposalSent ? (
              <S.FileContainer>
                <S.HeaderText>Visualizar arquivo enviado:</S.HeaderText>
                <Button size="small" type="button" onClick={handleOpenFile}>
                  <VisibilityIcon style={{ color: theme.colors.white }} />
                </Button>
              </S.FileContainer>
            ) : null}
            <S.GroupButton>
              <Button
                full
                size="default"
                type="button"
                onClick={() => dispatch(CreatorsVisit.setIsReportFour(false))}
              >
                Voltar
              </Button>
              <Button
                full
                color="primary"
                size="default"
                disabled={
                  (hasFinalProposal && isFranchisee) ||
                  isDisabled ||
                  !proposalSimulation?.final_value
                }
                onClick={() => setIsOpenModal(true)}
              >
                Finalizar proposta
              </Button>
            </S.GroupButton>
          </>
        )}
        {isOpenModal && (
          <GenericModal
            onClose={() => setIsOpenModal(false)}
            openModal={isOpenModal}
            onClickConfirm={() => {
              generatePlans()
            }}
            label="Finalizar a proposta é uma ação irreversível. Deseja continuar?"
          />
        )}
      </S.Container>
    </>
  )
}

export { ReportFour }
