/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

// import SolarLogo from '@/assets/images/logo.svg'

import FeaturesCard from '../FeaturesCard/FeaturesCard'
import { BigRowData } from './data'
// import {  SmallRowData } from './data'
import BigRow from './fragments/BigRow'
// import SmallRow from './fragments/SmallRow'

const DetailsCard = ({ simulationValues }: any) => {
  return (
    <>
      <FeaturesCard header="Detalhes dos equipamentos">
        {BigRowData(simulationValues).map(item => (
          <BigRow
            key={`BigRow-${item.iconAlt}-${item.iconTitle}-${item.marca}`}
            icon={item.icon}
            iconAlt={item.iconAlt}
            iconTitle={item.iconTitle}
            marca={item.marca}
            garantia={item.garantia}
            potencia={item.potencia}
            multiplier={item.multiplier}
          />
        ))}
      </FeaturesCard>
      {/* <FeaturesCard header="Benefícios" icon={SolarLogo}>
        {SmallRowData().map(item => (
          <SmallRow
            key={`SmallRow-${item.iconAlt}-${item.icon}-${item.description}`}
            icon={item.icon}
            iconAlt={item.iconAlt}
            description={item.description}
            months={item.months}
            monthDescription={item.monthDescription}
          />
        ))}
      </FeaturesCard> */}
    </>
  )
}

export default DetailsCard
