import React, { FC } from 'react'

const CheckIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.20001C6.12 1.20001 1.2 6.12001 1.2 12C1.2 17.88 6.12 22.8 12 22.8C17.88 22.8 22.8 18 22.8 12C22.8 6.00001 18 1.20001 12 1.20001ZM10.44 16.2L6.6 12.36L7.8 11.16L10.44 13.8L16.2 8.04001L17.4 9.24001L10.44 16.2Z"
        fill="#10B981"
      />
      <path
        opacity="0.01"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.44 16.2L6.60001 12.36L7.80001 11.16L10.44 13.8L16.2 8.04001L17.4 9.24001L10.44 16.2Z"
        fill="#10B981"
      />
    </svg>
  )
}

export default CheckIcon
