import { ClientStepStatusEnum } from '@/@types/client/client'
import { ClientStepTypeEnum, IClientStep } from '@/store/ducks/Clients/types'

export const getCurrentClientStepType = (steps?: IClientStep[]) => {
  const currentStep = steps?.find(
    step => step.status === ClientStepStatusEnum.PENDING,
  )
  const currentType = currentStep?.type || ClientStepTypeEnum.CONCLUIDO

  return currentType
}
