/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import Button from '@/components/Ui/Button/index'
import Input from '@/components/Ui/Form/Input'
import { RadioButtons } from '@/components/Ui/RadioButtons'
import { StepDetailTitle } from '@/components/Ui/StepDetailTitle'
import api from '@/services/api'
import { RootState } from '@/store'
import { ReportCreators as ClientReportCreators } from '@/store/ducks/ClientReport'
import { FormHandles, Scope } from '@unform/core'
import { Form } from '@unform/web'

import {
  Container,
  Header,
  ContainerButton,
  StepContainer,
  InvisibleInput,
} from './styles'

interface ApprovalReport {
  clientId: string
  isDisabled?: boolean
}

interface IFormApprovalReport {
  steps: []
}

const ApprovalReport: React.FC<ApprovalReport> = ({
  clientId,
  isDisabled = false,
}) => {
  const dispatch = useDispatch()

  const formRef = useRef<FormHandles>(null)

  const [reportData, setReportData] = useState<any>()

  const isLoading = useSelector(
    (state: RootState) => state.ClientReport.isLoading,
  )

  const handleSubmit = useCallback(
    async (approvalReport: IFormApprovalReport) => {
      const editApprovalReport = {
        status: 'APPROVED',
        ...approvalReport,
      }
      api
        .patch(`/admin/customers/${clientId}/reports/4`, editApprovalReport)
        .catch(() => toast.error('Erro ao tentar concluir o relatório!'))
        .finally(() =>
          dispatch(ClientReportCreators.setIsApprovalReport(false)),
        )
    },
    [clientId, dispatch],
  )

  useEffect(() => {
    api
      .get(`/admin/customers/${clientId}/reports/4`)
      .then(({ data }) => setReportData(data))
      .catch(() => toast.error('Erro ao buscar relatórios'))
  }, [clientId])

  useEffect(() => {
    formRef.current?.setData({ steps: reportData?.steps })
  }, [reportData])

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container>
        <Header>
          <h1>Relatório - Etapa 3</h1>
        </Header>
        <Form onSubmit={handleSubmit} ref={formRef}>
          {reportData?.steps.map(
            (
              item: {
                step: { toString: () => string }
                description: string
                texts: any[]
                choices: any[]
              },
              stepIndex: any,
            ) => {
              return (
                <StepContainer>
                  <Scope path={`steps[${stepIndex}]`}>
                    <StepDetailTitle
                      number={item.step.toString()}
                      title={item.description}
                    >
                      {item.texts?.length
                        ? item.texts.map((text, textIndex) => {
                            return (
                              <Scope path={`texts[${textIndex}]`}>
                                <div>
                                  <InvisibleInput
                                    name="id"
                                    value={text.id}
                                    disabled={isDisabled}
                                  />
                                  <Input
                                    style={{ minHeight: '0px', height: '54px' }}
                                    disabled={isDisabled}
                                    multiline
                                    name="value"
                                    placeholder={text.description}
                                  />
                                </div>
                              </Scope>
                            )
                          })
                        : item.choices.map((choice, choiceIndex) => {
                            return (
                              <Scope path={`choices[${choiceIndex}]`}>
                                <InvisibleInput
                                  name="id"
                                  value={choice.id}
                                  disabled={isDisabled}
                                />
                                <div>
                                  <RadioButtons
                                    disabled={isDisabled}
                                    name="options"
                                    options={choice.options.map(
                                      (option: {
                                        id: any
                                        description: any
                                        is_selected: any
                                      }) => {
                                        return {
                                          id: option.id,
                                          label: option.description,
                                          is_selected: option.is_selected,
                                        }
                                      },
                                    )}
                                  />
                                </div>
                              </Scope>
                            )
                          })}
                    </StepDetailTitle>
                  </Scope>
                </StepContainer>
              )
            },
          )}
          {isDisabled ? (
            <ContainerButton>
              <Button
                full
                size="default"
                color="primary"
                type="button"
                onClick={() =>
                  dispatch(ClientReportCreators.setIsApprovalReport(false))
                }
              >
                Voltar
              </Button>
            </ContainerButton>
          ) : (
            <ContainerButton>
              <Button
                full
                size="default"
                color="cancel"
                type="button"
                onClick={() =>
                  dispatch(ClientReportCreators.setIsApprovalReport(false))
                }
              >
                Cancelar
              </Button>

              <Button full type="submit" color="primary" size="default">
                Concluir Etapa 3
              </Button>
            </ContainerButton>
          )}
        </Form>
      </Container>
    </>
  )
}

export { ApprovalReport }
