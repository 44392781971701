import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { IObjectLiteral } from '@/@types/common/object-literal'
import { IPermission } from '@/@types/Permission'
import { HeaderTab, ITab } from '@/components/HeaderTab'
import PageContent from '@/components/PageContent'
import StatusCard from '@/components/Ui/Status/StatusCard'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Clients'

import { getClientStepStyle } from '../../utils/getClientStepStyle'
import { getCurrentClientStepType } from '../../utils/getCurrentClientStepType'
import { Bill } from './Bill'
import { ClientDetails } from './ClientDetails'
import { Installation } from './Installation'
import { Container, Header, Name, ClientInfoContainer } from './styles'
import { TechnicalVisit } from './TechnicalVisit'

interface IClient {
  id: string
  section: string
}

const ClientEdit: React.FC<RouteComponentProps<IClient>> = ({ match }) => {
  const permission = useSelector((state: RootState) => state.Auth.role)
  const [, setIsLending] = useState(false)
  const isFranchisee = permission === IPermission.FRANCHISEE

  const { id, section } = match.params

  const tabHeaderOptions: IObjectLiteral = {
    details: 'Detalhes',
    initialProposal: 'Proposta Inicial',
    finalProposal: 'Proposta Final',
    installation: 'Instalação',
  }

  const tabs: ITab[] = [
    { name: 'Detalhes', value: 'details' },
    { name: 'Proposta Inicial', value: 'initialProposal' },
    { name: 'Proposta Final', value: 'finalProposal' },
    { name: 'Instalação', value: 'installation' },
  ]

  const filteredTabs = tabs.filter(tab => {
    if (isFranchisee) {
      return (
        tab.value !== 'simulation' &&
        tab.value !== 'signature' &&
        tab.value !== 'databook' &&
        tab.value !== 'paymentHistory' &&
        tab.value !== 'activityLog' &&
        tab.value !== 'customerSuccess' &&
        tab.value !== 'vert'
      )
    }

    return true
  })

  const [breadcrumbs, setBreadcrumbs] = useState<string>(
    tabHeaderOptions[section],
  )
  const dispatch = useDispatch()
  const client = useSelector((state: RootState) => state.Client.client)

  const currentStepType = getCurrentClientStepType(client.steps)

  useEffect(() => {
    dispatch(Creators.getClientRequest({ id }))
    if (client.subscription_lending === 'LENDING') setIsLending(true)
  }, [dispatch, id, client.subscription_lending])

  const pageTabs: IObjectLiteral<React.ReactNode> = {
    details: <ClientDetails />,
    initialProposal: <Bill />,
    finalProposal: <TechnicalVisit />,
    installation: <Installation />,
  }

  return (
    <PageContent
      title="Clientes"
      breadcrumbsItems={[
        {
          name: 'Clientes',
        },
        {
          name: breadcrumbs,
        },
      ]}
    >
      <Container>
        <Header>
          <ClientInfoContainer>
            <Name>{client.name}</Name>
            {currentStepType && (
              <StatusCard
                style={getClientStepStyle(currentStepType)}
                width="auto"
              />
            )}
          </ClientInfoContainer>
          <HeaderTab
            floaded
            tabNames={filteredTabs}
            tabSelected={breadcrumbs}
            onTabClick={tab => {
              setBreadcrumbs(tab.name)
              history.push(`/customer/${id}/${tab.value}`)
            }}
          />
        </Header>
        <>{pageTabs[section]}</>
      </Container>
    </PageContent>
  )
}

export { ClientEdit }
