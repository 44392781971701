import theme from '@/assets/styles/theme'
import { ClientStepTypeEnum } from '@/store/ducks/Clients/types'

type IStepStyle = {
  [x in ClientStepTypeEnum]: {
    text: string
    color: string
    backgroundColor: string
  }
}

export const getClientStepStyle = (stepType: ClientStepTypeEnum) => {
  if (stepType === ClientStepTypeEnum.BILL) {
    return {
      text: 'Novos leads',
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
    }
  }
  if (stepType === ClientStepTypeEnum.PROPOSAL_INITIAL) {
    return {
      text: 'Prosposta initial',
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
    }
  }
  if (stepType === ClientStepTypeEnum.TECHNICAL_VISIT) {
    return {
      text: 'Prosposta final',
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
    }
  }
  if (stepType === ClientStepTypeEnum.INSTALATION) {
    return {
      text: 'Instalação',
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
    }
  }
  if (stepType === ClientStepTypeEnum.CONCLUIDO) {
    return {
      text: 'Ativo',
      color: theme.colors.white,
      backgroundColor: theme.colors.green[300],
    }
  }
  return {
    text: 'Outro',
    color: theme.colors.white,
    backgroundColor: theme.colors.orange[200],
  }
}
