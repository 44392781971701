/* eslint-disable */
import React, { useCallback, useEffect, useState, ChangeEvent, FC } from 'react'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import { MdFileDownload, MdVisibility } from 'react-icons/md'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { isEmpty, last } from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import { Select } from '@/components/Ui/Select'
import { StatusEnum } from '@/@types/bill'
import { TypeDocumentEnum } from '@/@types/client/client'
import { IObjectLiteral } from '@/@types/common/object-literal'
import { IPermission } from '@/@types/Permission'
import ClientCredit from '@/components/ClientCredit'
import { QuodRecord } from '@/components/ClientCredit/ScoreInfo/ScoreInfo.types'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { Table } from '@/components/Table/Table'
import Alert from '@/components/Ui/Alert'
import Button from '@/components/Ui/Button'
import { ButtonEdit } from '@/components/Ui/ButtonEdit'
import { DropdownBootstrap } from '@/components/Ui/Dropdown'
import Input from '@/components/Ui/Form/Input'
import NumberFormat from 'react-number-format'
import { ModalJustify } from '@/components/Ui/ModalJustify'
import { useState as useCustomState } from '@/hooks/useState'
import api from '@/services/api'
import { RootState } from '@/store'
import { BillCreators } from '@/store/ducks/Bill'
import { Creators as ClientsCreators } from '@/store/ducks/Clients'
import { insertCnpjMask } from '@/utils/insertNumberMask/insertCnpjMask'
import { insertCpfMask } from '@/utils/insertNumberMask/insertCpfMask'
import { insertPostalCodeMask } from '@/utils/insertNumberMask/insertPostalCodeMask'
import { LinearProgress, TextField } from '@material-ui/core'
import { Form } from '@unform/web'

import { PlanGroupProposal } from '../TechnicalVisit/ReportFour/PlanGroupProposal'
import { BillEdit } from './Edit'
import * as S from './styles'
import styled from 'styled-components'
import { GenericModal } from '@/components/Ui/GenericModal'

type StatusTypes = 'approved' | 'pending' | 'reproved'

function NumberFormatCustom(props: {
  [x: string]: any
  name?: any
  inputRef?: any
  onChange?: any
}) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={2}
      prefix="R$"
    />
  )
}

const Styles = {
  FormTechnicalComment: styled(Form)`
    margin-top: 30px;
    input {
      background-color: #f6f6f6;
    }
  `,
  ContainerForm: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 15px;
  `,
}

export type ProposalType = {
  id: string
  created_at: string
  updated_at: string
  power_of_the_project: number
  client_id: string
  distributor_kit_cost: number
  sale_value: number
  franchisee_commission: number
  final_value: number
  final_installed_wp: number
  value_kit_for_customer: number
  nf_value: number
  tax_solar21: number
  contribution_margin: number
  total_amount_receivable_franchisee: number
  inverter_quantity: number
  inverter_power: number
  inverter_brand: string
  inverter_warranty: string
  module_quantity: number
  module_brand: string
  module_power: number
  annual_production: number
  annual_savings: number
  payback: number
  module_warranty: number
  type: string
  can_edit?: boolean
}

interface IBillStatus {
  id: string
  status: StatusTypes
  justification_disapproved?: string
}

enum ClientBillStatusEnum {
  approved = 'Aprovado',
  pending = 'Em análise',
  reproved = 'Reprovado',
}

type Item = { value: string; label: string }

function PercentageMaskInput(props: any) {
  return (
    <InputMask
      mask="99,99"
      style={{ width: '100%', border: 'none' }}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

function InstalationMaskInput(props: any) {
  return (
    <InputMask
      mask="0,99"
      style={{ width: '100%', border: 'none' }}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

const Bill: FC = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(
    (state: RootState) => state.Bill.loading.get || state.Bill.loading.put,
  )
  const billDetails = useSelector((state: RootState) => state.Bill.billDetails)
  const billFiles = useSelector((state: RootState) => state.Bill.billFiles)
  const isEditBillDetails = useSelector(
    (state: RootState) => state.Bill.isEditBillDetails,
  )
  const clientType = useSelector(
    (state: RootState) => state.Client.client.type.type,
  )
  const client = useSelector((state: RootState) => state.Client.client)

  const partnership = useSelector(
    (state: RootState) => state.Client.partnership,
  )
  const permission = useSelector((state: RootState) => state.Auth.role)
  const isFranchisee = permission === IPermission.FRANCHISEE

  const isNaturalPerson = clientType === TypeDocumentEnum.CPF
  const billDocument: { [key: string]: StatusTypes } = {
    APPROVED: 'approved',
    DISAPPROVED: 'reproved',
    PENDING: 'pending',
  }

  const [billFileSelectOptions, setBillFilesSelectOptions] = useState<
    IBillStatus[]
  >([])
  const [billFileIdToDelete, setBillFileIdToDelete] = useState<string | null>(
    null,
  )
  const [loadingScore, setLoadingScore] = useState<boolean>(false)
  const [loadingPutDoc, setLoadingPutDoc] = useState<boolean>(false)
  const [typeKit, setTypeKit] = useState<string>('')
  const [optionsSelect, setOptionsSelect] = useState<Item[]>([])
  const [justifyText, setJustifyText] = useState<string>('')
  const [proposal, setProposal] = useState<ProposalType | undefined>()
  const [canEdit, setCanEdit] = useState<boolean | undefined>(true)
  const [directSales, setDirectSales] = useState<boolean>(false)
  const [proposalButton, setProposalButton] = useState<boolean>(false)
  const [generateProposalButton, setgenerateProposalButton] = useState<boolean>(
    false,
  )
  const [kit_price, setKitPrice] = useState('')
  const [installationMaterialCost, setInstallationMaterialCost] = useState('')
  const [dealershipTax, setDealershipTax] = useState<number>(0)
  const [disableBillEdit, setDisableBillEdit] = useState<boolean | undefined>(
    false,
  )
  const [disableBtgApproval, setDisableBtgApproval] = useState<
    boolean | undefined
  >(false)
  const [proposalToUpload, setProposalToUpload] = useState<File | null>(null)
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  interface IScore {
    score: number
    json?: QuodRecord
    provider_company: 'BOA_VISTA' | 'QUOD'
  }

  interface CustomState {
    quodData: IScore
  }

  const { state, setState } = useCustomState<CustomState>({
    quodData: { score: 0, provider_company: 'BOA_VISTA' },
  })

  interface IOption {
    label: string
    value: number
  }

  const options: IOption[] = [
    {
      label: 'Você mora em cidade ou bairro que ainda não atendemos',
      value: 1,
    },
    {
      label: 'O valor médio da sua conta de luz é inferior a R$ 300/mês',
      value: 2,
    },
    {
      label: 'Você mora em apartamento',
      value: 3,
    },
    {
      label:
        'A avaliação prévia do seu telhado não passou na análise do nosso time técnico',
      value: 4,
    },
    {
      label: 'Você não passou nos critérios da nossa política de crédito',
      value: 5,
    },
    {
      label:
        'Sua conta de luz está ilegível/incompleta. Por favor, envie um novo arquivo.',
      value: 6,
    },
    {
      label: 'Sua conta de luz está em nome de um CNPJ.',
      value: 7,
    },
  ]

  const columns = [
    {
      title: 'Nome do Arquivo',
      dataIndex: 'fileName',
      key: 'fileName',
      width: 150,
    },
    {
      title: 'Tipo do Documento',
      dataIndex: 'documentType',
      key: 'documentType',
      width: 100,
    },
    {
      title: 'Data do Documento',
      dataIndex: 'documentDate',
      key: 'documentDate',
      width: 100,
    },
    {
      title: '',
      dataIndex: 'icons',
      key: 'icons',
      width: 50,
    },
  ]

  const getFilename = (url: string): string => {
    return url.split('/')[url.split('/').length - 1]
  }

  const [loadingUpload, setLoadingUpload] = useState(false)

  let { user_id: userId } = useSelector((state: RootState) => state.Auth)

  if (userId == null) userId = ''

  const isauthuserId = [
    'aab26718-5c70-4953-ada9-ae3cf6cafc8a',
    'c1cd2c83-6133-4a11-9e37-5196761ff289',
    '52521cc3-3aba-471e-b75e-0051dcb6637d',
    '446cca4a-84d9-403b-975d-7983883369cc',
    '8287aaa7-9028-4583-9a6f-069eb9d1d115',
    '9c15eaaa-60d1-4bd8-913e-28ca669f6190',
    '89e20893-1808-42d4-8062-dd8ef99b975b',
    '934145ac-40c8-4db1-9f69-e2bebfddb93f',
    'ac84a385-80ff-4efb-8dc2-af39428a61f2',
    'b3c5acd4-2219-406f-972a-fe576c3a0359',
  ].includes(userId)

  const manualUploadBill = (file: File) => {
    setLoadingUpload(true)
    const fd = new FormData()
    fd.append('file', file, file.name)
    api
      .put(`/admin/customers/bills/${client.id}/manual_upload`, fd)
      .then(res => {
        if (res.data.status) {
          toast.success('Conta atualizada')
          dispatch(BillCreators.getBillDetailsRequest())
          dispatch(BillCreators.getBillFilesRequest())
        }
      })
      .finally(() => setLoadingUpload(false))
  }

  const manualUploadEmployeeCard = (file: File) => {
    setLoadingUpload(true)
    const fd = new FormData()
    fd.append('file', file, file.name)
    api
      .post(`/admin/client-partnership/${client.id}/emproyee-card`, fd)
      .then(() => {
        toast.success('Carteirinha atualizada')
        dispatch(ClientsCreators.getClientPartnershipRequest())
      })
      .finally(() => setLoadingUpload(false))
  }

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return
    const file = event.target.files[0]
    manualUploadBill(file)
  }

  const handleEmployeeCardUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.files) return
    const file = event.target.files[0]
    manualUploadEmployeeCard(file)
  }

  const getDropdownOptions = useCallback(() => {
    const opts = []

    if (
      (state?.quodData?.score >= 300 && (isauthuserId || isFranchisee)) ||
      directSales
    ) {
      opts.push({
        label: 'Aprovado',
        value: 'approved',
      })
    }
    opts.push({
      label: 'Reprovado',
      value: 'reproved',
    })

    return opts
  }, [state, isauthuserId, isFranchisee, directSales])

  const download = (url: string) => {
    fetch(url, {
      method: 'GET',
      headers: {},
    }).then(response => {
      response.arrayBuffer().then(buffer => {
        const newUrl = window.URL.createObjectURL(new Blob([buffer]))
        const link = document.createElement('a')
        link.href = newUrl
        link.setAttribute('download', getFilename(url))
        document.body.appendChild(link)
        link.click()
      })
    })
  }

  const billHandled = billFiles?.map(billFile => {
    const currentBillFileStatus = billFileSelectOptions.find(
      bill => bill.id === billFile.id,
    )?.status
    const defaultLabel =
      ClientBillStatusEnum[currentBillFileStatus ?? 'pending'] ?? 'Em análise'

    return {
      fileName: billFile.name,
      documentType: 'Conta de Luz',
      documentDate: moment(billFile.created_at).format('DD/MM/YYYY'),
      status: (
        <DropdownBootstrap
          items={getDropdownOptions()}
          defaultLabel={defaultLabel}
          isDisabled={billFile.status !== StatusEnum.PENDING} // send this to false to let the button work
          handleSelect={(...args) => {
            const statusClicked = args[0]
            const billDocumentsHandled = billFileSelectOptions.map(
              billFileSelectOptionsItem => {
                if (billFileSelectOptionsItem.id === billFile.id) {
                  return {
                    id: billFile.id,
                    status: statusClicked,
                  }
                }
                return billFileSelectOptionsItem
              },
            )

            if (statusClicked === 'reproved') setBillFileIdToDelete(billFile.id)

            setBillFilesSelectOptions(billDocumentsHandled)
          }}
          value={currentBillFileStatus}
        />
      ),
      icons: (
        <S.ContainerIcons>
          <MdFileDownload
            style={{ cursor: 'pointer' }}
            onClick={() => download(billFile.url)}
          />

          <a
            href={billFile.url}
            // eslint-disable-next-line
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          >
            <MdVisibility style={{ cursor: 'pointer' }} />
          </a>
          <S.MUITooltip
            title={billFile.justification_disapproved}
            placement="top"
            disableHoverListener={!billFile.justification_disapproved}
          >
            <S.InfoIcon hasTooltip={!!billFile.justification_disapproved} />
          </S.MUITooltip>
        </S.ContainerIcons>
      ),
    }
  })

  const handleUpdateSubscription = useCallback(
    async checked => {
      const status = checked ? 'LENDING' : 'SUBSCRIPTION'

      await api
        .patch(`/admin/subscription_lending/${client.id}/${status}`)
        .catch(error => console.error(error))
      window.location.reload()
    },
    [directSales, client],
  )

  const handleUpdateBillFiles = () => {
    const status: IObjectLiteral<StatusEnum> = {
      approved: StatusEnum.APPROVED,
      reproved: StatusEnum.DISAPPROVED,
      pending: StatusEnum.PENDING,
    }

    const billFileSelectOptionsData = billFileSelectOptions
      .filter(billFileSelectOption =>
        billFiles.some(
          billFile =>
            billFile.id === billFileSelectOption.id &&
            billFile.status === StatusEnum.PENDING,
        ),
      )
      .map(bill => ({
        ...bill,
        status: status[bill.status],
      }))

    dispatch(BillCreators.editBillFileRequest(billFileSelectOptionsData))
  }

  const handleConfirmJustify = () => {
    const billConfirmJustify = billFileSelectOptions.map(bill => {
      if (bill.id === billFileIdToDelete) {
        return {
          ...bill,
          justification_disapproved: justifyText,
        }
      }
      return bill
    })

    setBillFilesSelectOptions(billConfirmJustify)
    setBillFileIdToDelete(null)
  }

  const setInitialBillFiles = () => {
    setBillFilesSelectOptions(
      billFiles.map(billFile => {
        return {
          id: billFile.id,
          status: billDocument[billFile.status],
        }
      }),
    )
    setJustifyText('')
  }

  const submitCreteProposal = async () => {
    let payload: any = {
      not_compensated_tax: 0,
      dealership_tax: dealershipTax,
      dry_tax: 0,
      kwh_availability: 0,
      concurrency_factor: 0.5,
      module_power: 470,
      total_losses: 0.2,
      franchisee_commission_percent: parseFloat(kit_price.replaceAll('R$', '')),
      installation_material_cost: parseFloat(
        installationMaterialCost.replaceAll('R$', ''),
      ),
    }

    if (typeKit !== '') {
      const split = typeKit.split(' - ')
      payload.inverter_brand = split[0]
      payload.module_brand = split[1]
    }

    await api.post(`admin/customers/${client.id}/proposal`, payload)

    window.location.reload()
  }

  const loadKitTypes = async () => {
    if (!proposal) return
    const res = await api.get(
      `admin/get_kit_types/Cerâmica/${proposal.power_of_the_project}/${client.id}`,
    )

    const newOptions = res.data.map((e: any) => {
      const newItem: Item = {
        value: `${e.inverterBrand} - ${e.moduleBrand}`,
        label: `Inversor: ${e.inverterBrand} / Módulo: ${e.moduleBrand}`,
      }
      return newItem
    })

    setOptionsSelect(newOptions)
  }

  useEffect(() => {
    if (client && client.steps) {
      const { steps } = client
      const technicalVisitStatus = steps.find(
        step => step.type === 'TECHNICAL_VISIT',
      )?.status
      setDisableBillEdit(
        technicalVisitStatus && technicalVisitStatus === 'APPROVED',
      )
      const initialProposalStatus = steps.find(
        step => step.type === 'PROPOSAL_INITIAL',
      )?.status
      setDisableBtgApproval(
        (initialProposalStatus && initialProposalStatus === 'APPROVED') ||
          (technicalVisitStatus && technicalVisitStatus === 'APPROVED'),
      )
    }
  }, [client])

  useEffect(() => {
    loadKitTypes()
  }, [proposal])

  const getDataBill = async () => {
    try {
      const userSchema = Yup.object().shape({
        bill_owner: Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          document_value: Yup.string().required(
            'Número do documento é obrigatório',
          ),
          postal_code: Yup.string()
            .required('CEP obrigatório')
            .min(8, 'CEP inválido'),
          address: Yup.string().required('Endereço obrigatório'),
          address_number: Yup.string().required('Número obrigatório'),
          address_complement: Yup.string(),
          address_neighborhood: Yup.string().required('Bairro obrigatório'),
          city: Yup.string().required('Cidade obrigatório'),
          state: Yup.string().required('Estado obrigatório'),
        }),
        consumer_unit: Yup.string().required('Unidade de consumo obrigatório'),
        type_consumer_class: Yup.string().required(
          'Classe consumidora obrigatório',
        ),
        amount: Yup.number().required('Valor da conta obrigatório'),
        client_code: Yup.string().required('Código do cliente obrigatório'),
        expiration_month: Yup.string().required(
          'Mês de vencimento obrigatório',
        ),
        power_pattern: Yup.string().required('Tipo de alimentação obrigatório'),
      })

      await userSchema.validate(billDetails, {
        abortEarly: true,
      })
      setgenerateProposalButton(true)
    } catch (err) {
      setgenerateProposalButton(false)
    }
  }

  const checkFilesStatus = async () => {
    for (let i = 0; i < billFileSelectOptions.length; i += 1) {
      if (billFileSelectOptions[i].status === 'approved') {
        setProposalButton(true)
      }
    }
  }

  useEffect(() => {
    if (isEmpty(proposal)) {
      api
        .get(`admin/customers/${client.id}/proposal`)
        .then(({ data }) => {
          const filteredData = data.filter(
            (item: ProposalType) => item.type === 'INITIAL',
          )
          setProposal(last(filteredData))
        })
        .catch(err => console.error('Proposal retrieving did not work: ', err))
    } else if (proposal) {
      const {
        inverter_brand,
        module_brand,
        distributor_kit_cost,
        nf_value,
      } = proposal
      setCanEdit(proposal?.can_edit)
      setTypeKit(`${inverter_brand} - ${module_brand}`)
      setKitPrice(distributor_kit_cost.toFixed(2).replace('.', ','))
      setInstallationMaterialCost(nf_value.toFixed(2).replace('.', ','))
    }
  }, [proposal, client])

  const loadDealershipTax = async () => {
    const res = await api.get(`/admin/customers/${client.id}/project-infos`)

    setDealershipTax(res.data.dealership_tax)
  }

  useEffect(() => {
    loadDealershipTax()
  }, [])

  useEffect(() => {
    checkFilesStatus()
  }, [billFileSelectOptions])

  useEffect(() => {
    getDataBill()
    setDirectSales(client?.subscription_lending === 'LENDING')
  }, [client])

  useEffect(() => {
    dispatch(BillCreators.getBillDetailsRequest())
  }, [dispatch, isEditBillDetails])

  useEffect(() => {
    if (billDetails?.id) dispatch(BillCreators.getBillFilesRequest())
    // eslint-disable-next-line
  }, [billDetails.id])

  useEffect(() => {
    setInitialBillFiles()
    // eslint-disable-next-line
  }, [billFiles])

  useEffect(() => {
    dispatch(ClientsCreators.getClientPartnershipRequest())
  }, [dispatch])

  const translateConsumerType = (type?: string) => {
    let final
    if (type === 'RESIDENTIAL') final = 'Residencial'
    if (type === 'COMERCIAL') final = 'Comercial'
    if (!type) final = ''
    return final
  }

  const getScoreData = useCallback(
    ({ force = false }: { force?: boolean }): void => {
      setLoadingScore(true)
      api
        .get(`/admin/score/${client.id}`, { params: { force: +force } })
        .then(({ data }) => {
          setState({ quodData: data })
        })
        .catch(() => toast.error('Houve um erro ao consultar o Score'))
        .finally(() => setLoadingScore(false))
    },
    [client],
  )

  // eslint-disable-next-line
  const onSubmitScoreDocument = (formValues: any) => {
    const { cpf } = formValues
    if (!cpf || cpf.replace(/\D/g, '').length !== 11) {
      return toast.error('Verificar documento informado')
    }

    setLoadingPutDoc(true)

    api
      .put(`/admin/customers/bills/${client.id}/document`, {
        document_value: cpf.replace(/\D/g, ''),
      })
      .then(res => {
        if (res.data.affected) dispatch(BillCreators.getBillDetailsRequest())
        toast.success('Documento atualizado com sucesso')
        // eslint-disable-next-line
      })
      .catch(() => toast.error('Erro ao atualizar cadastro do cliente'))
      .finally(() => setLoadingPutDoc(true))
  }

  useEffect(() => {
    getScoreData({ force: false })
  }, [getScoreData])

  useEffect(() => {
    if (!proposalToUpload) return

    const formData = new FormData()
    formData.append('file', proposalToUpload)
    api
      .post(`/admin/uploads/proposal/${client.id}`, formData)
      .then(() => toast.success('Arquivo enviado com sucesso!'))
      .catch(() => toast.error('O arqiuvo não pode ser enviado...'))
  }, [proposalToUpload, client.id])

  const changeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setDirectSales(checked)
    handleUpdateSubscription(checked)
  }

  const handleCommissionChange = (event: ChangeEvent<HTMLInputElement>) =>
    setKitPrice(event.target.value)

  const handleInstallationChange = (event: ChangeEvent<HTMLInputElement>) =>
    setInstallationMaterialCost(event.target.value)

  const handleFranchiseRate = async () => {
    let payload: any = {
      not_compensated_tax: 0,
      dealership_tax: dealershipTax,
      dry_tax: 0,
      kwh_availability: 0,
      concurrency_factor: 0.5,
      module_power: 470,
      total_losses: 0.2,
      franchisee_commission_percent: parseFloat(kit_price.replaceAll('R$', '')),
      installation_material_cost: parseFloat(
        installationMaterialCost.replaceAll('R$', ''),
      ),
    }

    if (typeKit !== '') {
      const split = typeKit.split(' - ')
      payload.inverter_brand = split[0]
      payload.module_brand = split[1]
    }

    if (
      new Date(proposal?.updated_at || 0).getDate() !== new Date().getDate()
    ) {
      delete payload.inverter_brand
      delete payload.module_brand
    }

    await api
      .post(`admin/customers/${client.id}/proposal`, payload)
      .then(() => {
        toast.success('Simulação realizada com sucesso!')
        window.location.reload()
      })
      .catch(() => toast.error('Erro ao simular'))
  }

  const handleClickButton = (id: string) => {
    const hiddenInput = document.getElementById(id)
    if (!hiddenInput) return

    hiddenInput.click()
  }

  const handleFinish = useCallback(() => {
    const finalInstalledWp = proposal?.final_installed_wp || 0

    if (finalInstalledWp > 7) {
      toast.error(
        'Preço muito acima do praticado atualmente. Reavalie os valores!',
      )
      return
    }

    api
      .put(`/admin/proposals_finish/${client.id}`)
      .then(() => {
        toast.success('Proposta inicial finalizada!')
        window.location.reload()
      })
      .catch((error: any) => {
        toast.error('Ops... erro ao finalizar a proposta!')
        console.error('Error finishing initial proposal:', error)
      })
  }, [client.id, proposal?.final_installed_wp])

  const handleApprove = useCallback(() => {
    api
      .put(`/admin/proposals/approved_btg/${client.id}`)
      .then(() => {
        toast.success('Proposta inicial aprovada!')
        setTimeout(() => {
          window.location.href = `/customer/${client.id}/finalProposal`
        }, 5000)
      })
      .catch((err: any) => {
        toast.error('Ops... erro ao aprovar a proposta!')
        console.error('Error approving initial proposal: ', err)
      })
  }, [client])

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <S.Container>
        {billDetails ? (
          <>
            {isEditBillDetails ? (
              <BillEdit />
            ) : (
              <>
                <Alert text="Aprovação realizada via OCR. Favor conferir os dados coletados." />
                <S.ContainerTitle>
                  <S.Title>Dados da conta de luz</S.Title>
                  {!disableBillEdit && (isauthuserId || isFranchisee) ? (
                    <ButtonEdit
                      disabled={canEdit === false}
                      onClick={() =>
                        dispatch(BillCreators.setIsEditBillDetails(true))
                      }
                    />
                  ) : (
                    ''
                  )}
                </S.ContainerTitle>
                <S.ContainerBillInfo>
                  <S.BillInfoColumn>
                    <S.Fields>
                      <S.Font>Nome do Titular: </S.Font>
                      {billDetails?.bill_owner?.name}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>{clientType}: </S.Font>
                      {isNaturalPerson
                        ? insertCpfMask(billDetails?.bill_owner?.document_value)
                        : insertCnpjMask(
                            billDetails?.bill_owner?.document_value,
                          )}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>CEP: </S.Font>
                      {insertPostalCodeMask(
                        billDetails?.bill_owner?.postal_code,
                      )}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Endereço: </S.Font>
                      {billDetails?.bill_owner?.address}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Número: </S.Font>
                      {billDetails?.bill_owner?.address_number}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Estado: </S.Font> {billDetails?.bill_owner?.state}
                    </S.Fields>
                  </S.BillInfoColumn>
                  <S.BillInfoColumn>
                    <S.Fields>
                      <S.Font>Cidade: </S.Font> {billDetails?.bill_owner?.city}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Média Histórico de Consumo (kWh): </S.Font>
                      {billDetails?.consumption_history_kwh_values?.average &&
                        `${billDetails?.consumption_history_kwh_values?.average.toFixed(
                          2,
                        )} kWh`}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Total Histórico de Consumo (kWh): </S.Font>
                      {billDetails?.consumption_history_kwh_values?.total &&
                        `${billDetails?.consumption_history_kwh_values?.total.toFixed(
                          2,
                        )} kWh`}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Código do Cliente: </S.Font>
                      {billDetails?.client_code || (
                        <BsFillExclamationCircleFill color="red" />
                      )}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Número da UC: </S.Font>
                      {billDetails?.consumer_unit || (
                        <BsFillExclamationCircleFill color="red" />
                      )}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Classe Consumo: </S.Font>
                      {translateConsumerType(billDetails.type_consumer_class)}
                    </S.Fields>
                  </S.BillInfoColumn>
                  <S.BillInfoColumn>
                    <S.Fields>
                      <S.Font>Mês de Vencimento: </S.Font>
                      {billDetails?.expiration_month}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Valor da Conta: </S.Font>
                      {billDetails?.amount && `R$ ${billDetails?.amount}`}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Nome da Concessionária: </S.Font>
                      {billDetails?.dealership}
                    </S.Fields>
                    <S.Fields>
                      <S.Font>Tarifa concessionária: </S.Font> {dealershipTax}
                    </S.Fields>
                  </S.BillInfoColumn>
                </S.ContainerBillInfo>
                <S.TableWrapper>
                  <Table columns={columns} data={billHandled} />
                </S.TableWrapper>
                <Button
                  disabled={loadingUpload || canEdit === false}
                  onClick={() => handleClickButton('got-bill-file-upload')}
                >
                  Envio manual da conta de luz
                  <input
                    id="got-bill-file-upload"
                    onChange={handleFileUpload}
                    type="file"
                    hidden
                  />
                </Button>
                {client?.subscription_lending === 'LENDING' && directSales ? (
                  <div style={{ marginTop: '2rem' }}>
                    <S.Title>Edite a proposta inicial</S.Title>
                    {proposal && (
                      <Styles.ContainerForm>
                        <Select
                          placeholder="Selecione..."
                          label="Sistemas Equivalentes"
                          size="custom"
                          defaultValue={optionsSelect[0]}
                          options={optionsSelect}
                          value={optionsSelect?.find(
                            state => state.value === typeKit,
                          )}
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          onChange={(state: any) => setTypeKit(state.value)}
                          isSearchable={false}
                          isDisabled={disableBillEdit || canEdit === false}
                        />
                      </Styles.ContainerForm>
                    )}
                    <S.StyledTextFieldWrapper>
                      <S.StyledTextFieldContainer>
                        <S.CustomInputLabel htmlFor="valorKitDistribuidor">
                          Valor do kit no distribuidor:
                        </S.CustomInputLabel>
                        <S.MuiInput
                          disabled={canEdit === false}
                          variant="outlined"
                          value={kit_price}
                          onChange={handleCommissionChange}
                          id="valorKitDistribuidor"
                          name="valorKitDistribuidor"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </S.StyledTextFieldContainer>
                      <S.StyledTextFieldContainer>
                        <S.CustomInputLabel htmlFor="valorServico">
                          Valor serviço:
                        </S.CustomInputLabel>
                        <S.MuiInput
                          disabled={canEdit === false}
                          variant="outlined"
                          value={installationMaterialCost}
                          onChange={handleInstallationChange}
                          id="valorServico"
                          name="valorServico"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </S.StyledTextFieldContainer>
                    </S.StyledTextFieldWrapper>
                    <S.ButtonWrapper>
                      <Button
                        full
                        onClick={handleFranchiseRate}
                        loading={false}
                        disabled={canEdit === false}
                      >
                        Simular Proposta Inicial
                      </Button>
                    </S.ButtonWrapper>
                    {proposal && (
                      <S.SpecificProductionContainer>
                        <S.Title>Produção específica:</S.Title>
                        {(
                          proposal.annual_production /
                          proposal.power_of_the_project
                        ).toFixed(0)}
                        kWh/kWp.ano
                      </S.SpecificProductionContainer>
                    )}
                    <S.Title>Proposta que vai aparecer para o cliente</S.Title>
                    {proposal && (
                      <PlanGroupProposal simulationValues={proposal} />
                    )}
                  </div>
                ) : null}
                <>
                  <S.ContainerTitle>
                    <S.Title>Avaliação de Crédito</S.Title>
                  </S.ContainerTitle>
                  {!billDetails?.bill_owner?.document_value && (
                    <S.FormDocumentScore onSubmit={onSubmitScoreDocument}>
                      <InputMask mask="999.999.999-99" disabled={loadingPutDoc}>
                        {() => (
                          <Input
                            name="cpf"
                            label="CPF (será aplicado também à Conta de Luz) *"
                            width="50%"
                            placeholder="Digite o CPF"
                          />
                        )}
                      </InputMask>
                      <Button
                        type="submit"
                        style={{ marginLeft: 10 }}
                        loading={loadingPutDoc}
                      >
                        Salvar CPF
                      </Button>
                    </S.FormDocumentScore>
                  )}
                  <Button
                    disabled={canEdit === false}
                    onClick={() => getScoreData({ force: true })}
                    loading={!billDetails?.bill_owner?.document_value}
                  >
                    Executar agora
                  </Button>
                  {loadingScore && <LinearProgress />}
                  {!!state?.quodData?.score && (
                    <ClientCredit scoreData={state?.quodData} />
                  )}

                  <hr style={{ marginTop: 10, marginBottom: 20 }} />
                </>
                {partnership.id ? (
                  <S.PartnershipContainer>
                    <S.ContainerTitle>
                      <S.Title>Cliente com Parceria</S.Title>
                    </S.ContainerTitle>
                    <S.TableContainer>
                      <S.TableHeaderContainer>Arquivo</S.TableHeaderContainer>
                      {partnership.url ? (
                        <S.FileContainer>
                          <a
                            href={partnership.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ver arquivo
                          </a>
                          {isauthuserId || isFranchisee ? (
                            <Button
                              onClick={() =>
                                dispatch(
                                  ClientsCreators.confirmPartnershipRequest(),
                                )
                              }
                              color="success"
                              size="small"
                              disabled={partnership.is_validated}
                            >
                              {partnership.is_validated
                                ? 'Arquivo aprovado'
                                : 'Aprovar'}
                            </Button>
                          ) : (
                            ''
                          )}
                        </S.FileContainer>
                      ) : (
                        <S.FileContainer>
                          <span>O Cliente ainda não enviou um arquivo</span>
                        </S.FileContainer>
                      )}
                    </S.TableContainer>
                    <Button
                      disabled={loadingUpload || !!partnership.url}
                      onClick={() => handleClickButton('employee-card-upload')}
                    >
                      Envio manual da carteirinha do funcionário
                      <input
                        id="employee-card-upload"
                        onChange={handleEmployeeCardUpload}
                        type="file"
                        hidden
                      />
                    </Button>
                  </S.PartnershipContainer>
                ) : null}
                {loadingUpload && <LinearProgress />}
                <S.ContainerButtons>
                  <Button
                    color="primary"
                    size="default"
                    loading={false}
                    onClick={() => setIsOpenModal(true)}
                    disabled={canEdit === false}
                  >
                    Finalizar Proposta 
                  </Button>
                  {!isFranchisee && (
                    <Button
                      disabled={disableBtgApproval}
                      color="primary"
                      size="default"
                      onClick={handleApprove}
                    >
                      Aprovado BTG
                    </Button>
                  )}
                </S.ContainerButtons>
                <ModalJustify
                  options={options}
                  label="Selecione a justificativa que melhor representa o motivo de reprovação do cliente"
                  onChange={event => setJustifyText(event.target.value)}
                  onClose={() => setBillFileIdToDelete(null)}
                  onClickConfirm={handleConfirmJustify}
                  openModal={!!billFileIdToDelete}
                  value={justifyText}
                />
              </>
            )}
          </>
        ) : (
          <S.NoBillContainer>
            <h1>
              Não existe uma conta de luz. Deseja enviar uma conta de luz
              manualmente?
            </h1>
            <Button
              full
              disabled={loadingUpload}
              onClick={() => handleClickButton('bill-file-upload')}
            >
              Envio manual da conta de luz
              <input
                id="bill-file-upload"
                onChange={handleFileUpload}
                type="file"
                hidden
              />
            </Button>
          </S.NoBillContainer>
        )}
        {isOpenModal && (
          <GenericModal
            onClose={() => setIsOpenModal(false)}
            openModal={isOpenModal}
            onClickConfirm={handleFinish}
            label="Finalizar a proposta é uma ação irreversível. Deseja continuar?"
          />
        )}
      </S.Container>
    </>
  )
}

export { Bill }
