/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect, useRef } from 'react'
// import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import Switch from 'react-switch'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import { UserRoleEnum, UserStatusEnum } from '@/@types/user'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { Select } from '@/components/Ui/Select'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Users/user'
import { FormHandles } from '@unform/core'

import {
  Container,
  FormLineInput,
  CheckBoxContainer,
  ContainerButton,
  SelectContainer,
  ContainerForm,
  ContainerInput,
  SwitchContainer,
  SelectSwitchContanier,
  Form,
} from './styles'

const options = [
  { value: UserRoleEnum.FRANCHISEE, label: 'Integrador' },
  { value: UserRoleEnum.ADMIN, label: 'Administrador' },
]

interface FormData {
  fullname: string
  email: string
  role: UserRoleEnum
  status: UserStatusEnum
  password: string
  confirm_password: string
  cpf: string
  phone: string
}
interface IUser {
  id: string
}

type InitialFormData = {
  cpf?: string
  phone?: string
  fullname?: string
  email?: string
}

const UserEdit: React.FC<RouteComponentProps<IUser>> = ({ match }) => {
  const { id } = match.params
  const dispatch = useDispatch()

  const userFormRef = useRef<FormHandles>(null)
  const userData = useSelector((state: RootState) => state.User.user)
  const [selectedRole, setSelectedRole] = useState<UserRoleEnum | undefined>(
    userData?.role,
  )
  const [selectedStatus, setSelectedStatus] = useState<UserStatusEnum>(
    userData?.status,
  )

  const [initialData, setInitialData] = useState<InitialFormData>()
  const isLoading = useSelector(
    (state: RootState) => state.User.loading.put || state.User.loading.get,
  )

  const isCurrentStatus = selectedStatus === 'ACTIVE'
  const [isChecked, setIsChecked] = useState(isCurrentStatus)

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        const names = data.fullname.split(' ')

        const { email, password, confirm_password, phone, cpf } = data

        // eslint-disable-next-line
        const userProfile: any = {
          firstname: names[0],
          lastname: names[names.length - 1],
          email,
          role: selectedRole,
          status: selectedStatus,
          cpf: cpf.replace(/\D/g, ''),
          phone: phone ? `55${phone.replace(/[^0-9]/g, '')}` : null,
        }

        if (password) userProfile.password = password
        if (confirm_password) userProfile.confirm_password = confirm_password

        const userSchema = Yup.object().shape({
          email: Yup.string().email().required('Email obrigatório'),
          firstname: Yup.string().required('Nome obrigatório'),
          cpf: Yup.string().required('CPF obrigatório'),
        })

        await userSchema.validate(userProfile, {
          abortEarly: false,
        })
        dispatch(Creators.editUserRequest(userProfile))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toast.error(err.errors[0])
        }
      }
    },
    /* eslint-disable-next-line */
    [selectedRole, selectedStatus, dispatch],
  )

  useEffect(() => {
    dispatch(
      Creators.getUserRequest({
        id,
      }),
    )
  }, [dispatch, id])

  useEffect(() => {
    setIsChecked(isCurrentStatus)
    // eslint-disable-next-line
  }, [isCurrentStatus])

  useEffect(() => {
    const { cpf, phone, email, firstname, lastname } = userData

    const initial: InitialFormData = {
      cpf: cpf || '',
      phone: phone ? phone?.replace('55', '') : '',
      email,
      fullname: `${firstname} ${lastname}`,
    }

    setInitialData(initial)
    userFormRef.current?.setData(initial)
    // eslint-disable-next-line
  }, [userData])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => setSelectedRole(e.value)

  const handleClick = () => {
    setIsChecked(!isChecked)
    setSelectedStatus(UserStatusEnum[isChecked ? 'DISABLED' : 'ACTIVE'])
  }

  const handleCancel = () => history.push('/users')

  return (
    <PageContent
      title="Editar Usuário"
      breadcrumbsItems={[
        {
          name: 'Usuários',
        },
        {
          name: 'Editar Usuário',
        },
      ]}
    >
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <Container>
          <Form
            ref={userFormRef}
            initialData={initialData}
            onSubmit={handleSubmit}
          >
            <ContainerForm>
              <FormLineInput>
                <ContainerInput>
                  <Input
                    autoComplete="new-password"
                    name="fullname"
                    label="Nome completo *"
                    width="100%"
                  />
                </ContainerInput>
                <SelectSwitchContanier>
                  <SelectContainer>
                    <Select
                      label="Perfil *"
                      size="custom"
                      options={options}
                      value={options.find(
                        option => option.value === selectedRole,
                      )}
                      onChange={handleChange}
                      isSearchable={false}
                    />
                  </SelectContainer>
                </SelectSwitchContanier>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput>
                  <Input
                    name="password"
                    autoComplete="new-password"
                    label="Senha"
                    width="100%"
                    type="password"
                    placeholder="Digite sua senha"
                    note="Minimo de 8 caracteres com letras e números"
                  />
                </ContainerInput>
                <ContainerInput>
                  <Input
                    name="confirm_password"
                    label="Confirme sua Senha"
                    width="100%"
                    type="password"
                    placeholder="Digite sua senha"
                    note="Confirme a senha"
                  />
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput>
                  <Input
                    hasMask
                    mask="999.999.999-99"
                    name="cpf"
                    label="CPF *"
                    width="100%"
                    placeholder="Digite o número do documento"
                    defaultValue={userData?.cpf}
                  />
                </ContainerInput>
                <ContainerInput>
                  <Input name="email" label="Email *" width="100%" />
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput>
                  <Input
                    hasMask
                    mask="(99) 9 9999-9999"
                    name="phone"
                    label="Celular"
                    width="100%"
                    type="text"
                    placeholder="Digite o celular com DDD"
                  />
                </ContainerInput>
                <SelectSwitchContanier>
                  <SwitchContainer>
                    <CheckBoxContainer>
                      <label>Status *</label>
                      <Switch
                        onChange={handleClick}
                        checked={isChecked}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        offColor="#FF0000"
                        onColor="#FFD500"
                      />
                    </CheckBoxContainer>
                    {!isChecked ? <p>Bloqueado</p> : <p>Ativo</p>}
                  </SwitchContainer>
                </SelectSwitchContanier>
              </FormLineInput>
            </ContainerForm>
            <ContainerButton>
              <Button
                full
                size="default"
                color="cancel"
                type="button"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button full size="default" color="primary" type="submit">
                Salvar alterações
              </Button>
            </ContainerButton>
          </Form>
        </Container>
      )}
    </PageContent>
  )
}

export { UserEdit }
