/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react'
// import { toast } from 'react-toastify'

// import api from '@/services/api'
// import { RootState } from '@/store'
import { insertBRNumberMask } from '@/utils/insertNumberMask/insertBRNumberMask'

import * as BS from '../../styles'
import CostInfos from './CostInfos/CostInfos'
import DetailsCard from './DetailsCard'
import FeaturesCard from './FeaturesCard/FeaturesCard'
import BigNumberCard, {
  PositionEnum,
} from './GeneralDataCards/BigNumberCard/BigNumberCard'
import * as S from './styles'

const GroupPresentation = ({ simulationValues }: any) => {
  // const { client } = useSelector((state: RootState) => state.Client)
  // const [isLoading, setIsLoading] = useState(false)

  // const downloadPDF = async () => {
  //   try {
  //     const { data } = await api.get(`admin/customers/${client.id}/proposal`)
  //     const filteredLinks = data
  //       .filter(
  //         (item: { type: string }) => item.type === simulationValues?.type,
  //       )
  //       .map((item: { pdf_link: string }) => item.pdf_link)

  //     simulationValues.pdf_link = filteredLinks[0] ? filteredLinks[0] : null
  //   } catch (error) {
  //     console.error('Failed to fetch proposal data:', error)
  //     simulationValues.pdf_link = null
  //   }

  //   if (simulationValues?.pdf_link) {
  //     try {
  //       setIsLoading(true)
  //       const response = await fetch(simulationValues?.pdf_link)
  //       const blob = await response.blob()
  //       const url = URL.createObjectURL(blob)
  //       const link = document.createElement('a')
  //       link.href = url
  //       link.download = `${client.name} - Proposta ${
  //         simulationValues?.type === 'INITIAL' ? 'Inicial' : 'Final'
  //       }.pdf`
  //       link.click()
  //       URL.revokeObjectURL(url)
  //     } catch (error) {
  //       // eslint-disable-next-line no-console
  //       console.error(error)
  //     } finally {
  //       setIsLoading(false)
  //     }
  //   } else {
  //     let msg = 'Proposta ainda não foi gerada. Gere a proposta primeiro!'
  //     if (simulationValues?.id)
  //       msg =
  //         'Documento em PDF sendo gerado. Aguarde um minuto e tente novamente!'
  //     toast.info(msg)
  //   }
  // }
  const getProjectPower = useCallback(() => {
    if (!simulationValues?.power_of_the_project) return 0

    return simulationValues.power_of_the_project.toLocaleString('pt-br', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 3,
    })
  }, [simulationValues])

  const getMonthlyProduction = useCallback(() => {
    if (!simulationValues?.annual_production) return 0

    const { annual_production } = simulationValues
    const yearProduction =
      annual_production > 0 ? annual_production : annual_production * -1

    return `${(yearProduction / 12).toFixed(0)}`.replace('.', ',')
  }, [simulationValues])

  const getAnnualSavings = useCallback(() => {
    const yearSavings = Math.abs(simulationValues?.annual_savings ?? 0)
    const formattedSavings = `${(yearSavings / 1000).toFixed(2)}`.replace(
      '.',
      ',',
    )

    return `${formattedSavings}mil`
  }, [simulationValues])

  const getPowerPrice = useCallback(() => {
    if (
      !simulationValues?.final_value ||
      !simulationValues?.power_of_the_project
    ) {
      return 0
    }

    return `${(
      simulationValues?.final_value /
        (1000 * simulationValues?.power_of_the_project) || 0
    )
      .toFixed(2)
      .replace('.', ',')}`
  }, [simulationValues])

  const getPayback = useCallback(() => {
    if (!simulationValues?.payback) return 0
    return simulationValues.payback
  }, [simulationValues])

  const getKitGeneralValue = useCallback(() => {
    if (!simulationValues) return '0,00'

    const {
      final_value: final,
      total_amount_receivable_franchisee: franchiseeTotal,
      franchisee_commission: commission,
    } = simulationValues

    return final && franchiseeTotal && commission
      ? insertBRNumberMask(final - (franchiseeTotal - commission))
      : '0,00'
  }, [simulationValues])

  return (
    <S.DetailsContainer>
      <S.Subcontainer>
        <BigNumberCard
          card="Potência"
          main={getProjectPower()}
          side="kWp"
          sidePosition={PositionEnum.RIGHT}
        />
        <BigNumberCard
          card="Preço por potência"
          main={getPowerPrice()}
          side="R$/Wp"
          sidePosition={PositionEnum.RIGHT}
        />
        <BigNumberCard
          card="Produção mensal"
          main={getMonthlyProduction()}
          side="kWh"
          sidePosition={PositionEnum.RIGHT}
        />
        <BigNumberCard
          card="Economia anual"
          main={getAnnualSavings()}
          side="R$"
          sidePosition={PositionEnum.LEFT}
        />
        <BigNumberCard
          card="Payback"
          main={getPayback()}
          side="meses"
          sidePosition={PositionEnum.RIGHT}
        />
      </S.Subcontainer>
      <S.SingleCardSubContainer>
        <DetailsCard simulationValues={simulationValues} />
      </S.SingleCardSubContainer>
      <CostInfos totalCost={simulationValues?.final_value || 0} />
      {/* <S.ButtonWrapper>
        <Button
          disabled={isLoading}
          text={isLoading ? 'Baixando...' : 'Baixar PDF'}
          color="#ccc"
          onClick={() => downloadPDF()}
        />
      </S.ButtonWrapper> */}
      <BS.LabelContainer>
        <label>Informações sobre seus ganhos</label>
      </BS.LabelContainer>
      <FeaturesCard header="" style={{ display: 'block' }}>
        <BS.LabelContainer style={{ width: '100%' }}>
          <label>Valor do kit no distribuidor </label>
          <label>
            <S.SmallText>R$ </S.SmallText>
            {getKitGeneralValue()}
          </label>
        </BS.LabelContainer>
        <BS.LabelContainer style={{ width: '100%' }}>
          <label>Valor serviço </label>
          <label>
            <S.SmallText>R$ </S.SmallText>
            {insertBRNumberMask(
              simulationValues?.total_amount_receivable_franchisee -
                simulationValues?.franchisee_commission || 0,
            )}
          </label>
        </BS.LabelContainer>
      </FeaturesCard>
      <BS.TotalLabelContainer>
        <S.TotalCost>Total</S.TotalCost>
        <S.MoneyWrapper>
          <S.MoneySign>R$</S.MoneySign>
          <S.MoneyValue>
            {insertBRNumberMask(simulationValues?.final_value || 0)}
          </S.MoneyValue>
        </S.MoneyWrapper>
      </BS.TotalLabelContainer>
    </S.DetailsContainer>
  )
}

export default GroupPresentation
