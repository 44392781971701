/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { BsPencil } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import moment from 'moment'

import { ReportStepStatusEnum } from '@/@types/clientReportSteps/clientReportSteps'
import { IPermission } from '@/@types/Permission'
import CoordinatesMap from '@/components/CoordinatesMap'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { ApprovalReport } from '@/components/ReportForm/ApprovalReport'
import { ReportFive } from '@/components/ReportForm/ReportFive'
import { ReportSix } from '@/components/ReportForm/ReportSix'
import Button from '@/components/Ui/Button/index'
import Steps from '@/components/Ui/Steps'
import api from '@/services/api'
import { RootState } from '@/store'
import { ReportCreators } from '@/store/ducks/ClientReport'
import { Creators as ClientCreators } from '@/store/ducks/Clients'

import * as S from './styles'

const Installation: React.FC = () => {
  const dispatch = useDispatch()

  const [openCoordinatesDialog, setOpenCoordinatesDialog] = useState(false)
  const [, setUpdatingCoords] = useState(false)

  const client = useSelector((state: RootState) => state.Client.client)
  const technicalVisit = useSelector(
    (state: RootState) => state.Client.client.technical_visit,
  )
  const permission = useSelector((state: RootState) => state.Auth.role)
  const isEditReportFour = useSelector(
    (state: RootState) => state.Visit.isEditReportFour,
  )
  const isEditReportFive = useSelector(
    (state: RootState) => state.ClientReport.isEditReportFive,
  )
  const isEditReportSix = useSelector(
    (state: RootState) => state.ClientReport.isEditReportSix,
  )
  const isEditApprovalReport = useSelector(
    (state: RootState) => state.ClientReport.isEditApprovalReport,
  )
  const isLoading = useSelector(
    (state: RootState) => state.Visit.isLoading || state.ClientReport.isLoading,
  )
  const reportsStatus = useSelector(
    (state: RootState) => state.ClientReport.reports,
  )

  useEffect(() => {
    if (!isEditReportFour) {
      dispatch(ReportCreators.getReportsRequest())
      dispatch(ClientCreators.getClientRequest({ id: client.id }))
    }
    // eslint-disable-next-line
  }, [client.id, isEditReportFour])

  const ddd = technicalVisit?.phone.substr(0, 2)
  const phone = technicalVisit?.phone.substr(2, technicalVisit.phone.length)
  const phoneClient = client?.phone.substr(2, client.phone.length)
  const hasPermission = permission === IPermission.TECHNICIAN

  const reportStepStatus: {
    [key: string]: 'concluded' | 'pending'
  } = {
    APPROVED: 'concluded',
    PENDING: 'pending',
  }

  const reportFive = reportsStatus?.find(item => item.step === 5)
  const reportSix = reportsStatus?.find(item => item.step === 6)
  const approvalReport = reportsStatus?.find(item => item.step === 4)

  // eslint-disable-next-line consistent-return
  const reports = () => {
    if (isEditReportFive) return <ReportFive />
    if (isEditReportSix) return <ReportSix />
    if (isEditApprovalReport)
      return (
        <ApprovalReport
          clientId={client.id}
          isDisabled={permission === IPermission.FRANCHISEE}
        />
      )
  }

  const handleSubmit = () => {
    try {
      api
        .patch(`/admin/finish_installation/${client.id}`)
        .then(res => {
          if (res.data.id) {
            toast.success('Instalação finalizada com sucesso!')
          }
        })
        .catch(() => toast.error('Erro ao finalizar Instalação'))
        .finally(() => window.location.reload())
    } catch (error) {
      toast.error('Erro ao finalizar Instalação')
    }
  }

  const updateCoordinates = (values: {
    lat: string | number
    lng: string | number
  }) => {
    // eslint-disable-next-line
    const coordinates = `${values.lat}, ${values.lng}`
    setUpdatingCoords(true)
    api
      .patch(
        `/admin/customers/scheduling/${client.technical_visit?.id}/coordinates`,
        {
          coordinates,
        },
      )
      .then(res => {
        if (res.data.id) {
          toast.success('Atualizado com sucesso')
          dispatch(ClientCreators.getClientRequest({ id: client.id }))
          setOpenCoordinatesDialog(false)
        }
      })
      .catch(() => toast.error('Erro ao salvar informações'))
      .finally(() => setUpdatingCoords(false))
  }

  return (
    <S.Container>
      {isLoading && <FullScreenLoader />}
      {technicalVisit ? (
        <>
          <S.Header>
            <h1>Dados da Instalação</h1>
          </S.Header>
          <S.ContainerClient>
            <S.ContainerColumn>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Cliente: </S.Font>
                  {client.name}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Telefone: </S.Font>
                  {technicalVisit.phone && `(${ddd}) ${phone}`}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Telefone 2: </S.Font>
                  {client.phone && `(${ddd}) ${phoneClient}`}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Data: </S.Font>
                  {moment(technicalVisit.schedule).format('DD/MM/YYYY')}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Hora: </S.Font>
                  {moment(technicalVisit.schedule).format('HH:mm')}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>CEP: </S.Font>
                  {technicalVisit.cep}
                </S.Fields>
              </S.FieldsLabel>
            </S.ContainerColumn>
            <S.ContainerColumn>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Endereço: </S.Font>
                  {technicalVisit.address}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Bairro: </S.Font>
                  {technicalVisit.neighborhood}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Número: </S.Font>
                  {technicalVisit.number}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Complemento: </S.Font> {technicalVisit.complement}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Cidade: </S.Font> {technicalVisit.city}
                </S.Fields>
              </S.FieldsLabel>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Estado: </S.Font> {technicalVisit.state}
                </S.Fields>
              </S.FieldsLabel>
            </S.ContainerColumn>
            <S.ContainerColumn>
              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Técnico: </S.Font>
                  {technicalVisit.technician?.fullname || 'Víncular Técnico'}
                </S.Fields>
              </S.FieldsLabel>
              {!!technicalVisit.suggested_new_schedule_date && (
                <S.FieldsLabel>
                  <S.Fields>
                    <S.Font>Nova data sugerida: </S.Font>
                    {moment(technicalVisit.suggested_new_schedule_date).format(
                      'DD/MM/YYYY HH:mm',
                    )}
                  </S.Fields>
                </S.FieldsLabel>
              )}

              <S.FieldsLabel>
                <S.Fields>
                  <S.Font>Coordenadas:</S.Font>
                  {technicalVisit?.coordinates ? (
                    <a
                      href={`https://maps.google.com/?q=${technicalVisit?.coordinates}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {technicalVisit?.coordinates}
                    </a>
                  ) : (
                    '-'
                  )}
                  <BsPencil
                    size={19}
                    onClick={() => setOpenCoordinatesDialog(true)}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                  />
                </S.Fields>
              </S.FieldsLabel>
            </S.ContainerColumn>
            <CoordinatesMap
              onSubmit={updateCoordinates}
              defaultCoords={
                technicalVisit?.coordinates
                  ? {
                      lat: parseFloat(
                        technicalVisit?.coordinates.split(', ')[0],
                      ),
                      lng: parseFloat(
                        technicalVisit?.coordinates.split(', ')[1],
                      ),
                    }
                  : undefined
              }
              onCloseDialog={() => setOpenCoordinatesDialog(false)}
              opened={openCoordinatesDialog}
            />
          </S.ContainerClient>

          {isEditReportFive || isEditReportSix || isEditApprovalReport ? (
            reports()
          ) : (
            <>
              <S.ContainerReport>
                <h1>Relátorios</h1>
                <S.ContainerSteps>
                  {!hasPermission && (
                    <div>
                      <Steps
                        descriptionSteps="Relatório fotográfico"
                        titleSteps="Instalação"
                        type={
                          reportStepStatus[
                            reportFive?.status || ReportStepStatusEnum.PENDING
                          ]
                        }
                        onClick={() =>
                          dispatch(ReportCreators.setIsReportFive(true))
                        }
                      />
                    </div>
                  )}
                  {!hasPermission && (
                    <div>
                      <Steps
                        descriptionSteps=""
                        titleSteps="Projeto e Nota Fiscal"
                        type={
                          reportStepStatus[
                            reportSix?.status || ReportStepStatusEnum.PENDING
                          ]
                        }
                        onClick={() =>
                          dispatch(ReportCreators.setIsReportSix(true))
                        }
                      />
                    </div>
                  )}
                  {!hasPermission && (
                    <div>
                      <Steps
                        descriptionSteps=""
                        titleSteps="Aprovação"
                        type={
                          reportStepStatus[
                            approvalReport?.status ||
                              ReportStepStatusEnum.PENDING
                          ]
                        }
                        onClick={() =>
                          dispatch(ReportCreators.setIsApprovalReport(true))
                        }
                      />
                    </div>
                  )}
                </S.ContainerSteps>
              </S.ContainerReport>
              <S.GroupButton>
                <Button color="primary" size="default" onClick={handleSubmit}>
                  Finalizar instalação
                </Button>
              </S.GroupButton>
            </>
          )}
        </>
      ) : (
        <div>
          <h1>Não houve uma visita técnica.</h1>
        </div>
      )}
    </S.Container>
  )
}

export { Installation }
