import { toast } from 'react-toastify'

import jwt_decode from 'jwt-decode'
import { put, call } from 'redux-saga/effects'

import { IPermission } from '@/@types/Permission'
import messages from '@/constants/messages'
import api from '@/services/api'
import history from '@/services/history'

import { Creators } from '../../ducks/Auth'
import { ISignInAction } from './types'

interface IUser {
  email: string
  exp: number
  iat: number
  role: IPermission
  user_id: string
}

/**
 * SignIn
 *
 * @param action
 */
export function* signIn(action: ISignInAction) {
  const { email, password } = action

  try {
    const response = yield call(api.post, '/admin/login', { email, password })
    const { access_token } = response.data

    const user = jwt_decode<IUser>(access_token)

    const userData = {
      token: access_token,
      role: user.role,
      user_id: user.user_id,
    }

    localStorage.setItem('permission', user.role)

    yield put(Creators.signInSuccess(userData))
  } catch (error) {
    const { code } = error.response.data
    const message = messages(`signIn.${code}`)

    yield put(Creators.signInFailure({ message }))
  }
}

/**
 * Logout
 *
 */
export function* logout() {
  try {
    yield call(api.post, '/admin/logout')
    yield put(Creators.logoutSuccess())

    history.push('/')
  } catch (error) {
    toast.error('Erro ao sair do portal admin')
  }
}
