/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import moment from 'moment'

import { IObjectLiteral } from '@/@types/common/object-literal'
import penIcon from '@/assets/images/icons/ic-pen.svg'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { ITableProps, Table } from '@/components/Table'
import { Calendar } from '@/components/Ui/Calendar'
import { IItem, MultSelect } from '@/components/Ui/MultSelect'
import { Pagination } from '@/components/Ui/Pagination'
import { SearchInput } from '@/components/Ui/SearchInput'
import StatusCard from '@/components/Ui/Status/StatusCard'
import api from '@/services/api'
import { RootState } from '@/store'
import { CitiesCreators } from '@/store/ducks/Cities'
import { Creators } from '@/store/ducks/Clients'
import {
  ClientStepTypeEnum,
  IActivityLog,
  IClientStep,
} from '@/store/ducks/Clients/types'
import { StateCreators } from '@/store/ducks/State'
import { insertPhoneMask } from '@/utils/insertNumberMask/insertPhoneMask'
import { UnfoldMore } from '@material-ui/icons'

import { getClientStepStyle } from '../../../utils/getClientStepStyle'
import { getCurrentClientStepType } from '../../../utils/getCurrentClientStepType'
import * as S from './styles'

interface IClientData {
  id: string
  name: string
  email: string
  type?: string
  city: string
  state: string
  steps?: IClientStep[]
  created_at?: string
  logs?: IActivityLog
  bill_value?: number
  bill_id?: string
  bill_status?: string
  technical_status?: string
  last_login?: string
}

interface ISelectedDatesCalendar {
  dateInitial: string
  dateFinal: string
}

const TableView = () => {
  const [isOpenState, setIsOpenState] = useState<boolean>(false)
  const [isOpenCity, setIsOpenCity] = useState<boolean>(false)
  const [isDisable, setIsDisable] = useState<boolean>(true)

  const [page, setPage] = useState<number>(1)
  const [clientType] = useState<string>('CPF')
  const [search, setSearch] = useState<string>('')
  const [billStatus, setBillStatus] = useState<string>('')
  const [proposalStatus, setProposalStatus] = useState<string>('')
  const [technicalVisitStatus, setTechnicalVisitStatus] = useState<string>('')
  const [stateInitials, setStateInitials] = useState<string>('')
  const [cityIds, setCityIds] = useState<string[]>([])
  const [isOpenSituation, setIsOpenSituation] = useState<boolean>(false)
  const [situation, setSituation] = useState<string[]>([])
  const [isOpenHiddenColumn, setIsOpenHiddenColumn] = useState<boolean>(false)
  const [currentSteps] = useState<ClientStepTypeEnum[]>([])
  const [hideKeys, setHideKeys] = useState<string[]>([])
  const [sortData, setSortData] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC')
  const [states, setStates] = useState<any[]>([])

  const availableType =
    clientType === 'CPF'
      ? { is_residential_available: true }
      : { is_commercial_available: true }

  const dispatch = useDispatch()
  const clientList = useSelector(
    (state: RootState) => state.Client.clients.items,
  )
  const pagination = useSelector(
    (state: RootState) => state.Client.clients.meta,
  )

  const citiesList = useSelector(
    (state: RootState) => state.Cities.cities.items,
  )
  const isLoading = useSelector(
    (state: RootState) => state.Client.isClientLoading,
  )

  const statusFilterData = [
    {
      id: ClientStepTypeEnum.BILL,
      label: <StatusCard style={getClientStepStyle(ClientStepTypeEnum.BILL)} />,
    },
    {
      id: ClientStepTypeEnum.PROPOSAL_INITIAL,
      label: (
        <StatusCard
          style={getClientStepStyle(ClientStepTypeEnum.PROPOSAL_INITIAL)}
        />
      ),
    },
    {
      id: ClientStepTypeEnum.TECHNICAL_VISIT,
      label: (
        <StatusCard
          style={getClientStepStyle(ClientStepTypeEnum.TECHNICAL_VISIT)}
        />
      ),
    },
    {
      id: ClientStepTypeEnum.INSTALATION,
      label: (
        <StatusCard
          style={getClientStepStyle(ClientStepTypeEnum.INSTALATION)}
        />
      ),
    },
  ]
  const updateCurrentSteps = (selectedSteps: string[]) => {
    const choiceSteps: IObjectLiteral<ClientStepTypeEnum> = {
      BILL: ClientStepTypeEnum.BILL,
      PROPOSAL_INITIAL: ClientStepTypeEnum.PROPOSAL_INITIAL,
      TECHNICAL_VISIT: ClientStepTypeEnum.TECHNICAL_VISIT,
      INSTALATION: ClientStepTypeEnum.INSTALATION,
    }

    return selectedSteps.map(step => choiceSteps[step])
  }

  const dtFormat = 'DD/MM/YYYY HH[h]mm'

  const getTitle = useCallback(
    (text: string, sort: string) => {
      const handleSort = () => {
        if (sortData === sort)
          setSortDirection(direction => (direction === 'ASC' ? 'DESC' : 'ASC'))
        else {
          setSortDirection('ASC')
          setSortData(sort)
        }
      }

      return (
        <S.SortColumn onClick={handleSort}>
          {text} <UnfoldMore />
        </S.SortColumn>
      )
    },
    [sortData],
  )

  const columns: ITableProps<IClientData>['columns'] = [
    {
      title: getTitle('Nome', 'name'),
      dataIndex: 'name',
      key: 'name',
      width: 120,
    },
    {
      title: getTitle('Email', 'email'),
      dataIndex: 'email',
      align: 'center',
      key: 'email',
      width: 10,
    },
    {
      title: getTitle('Documento', 'document'),
      dataIndex: 'document',
      align: 'center',
      key: 'document',
      width: 60,
    },
    {
      title: getTitle('Integrador', 'integrator'),
      dataIndex: 'integrator',
      align: 'center',
      key: 'integrator',
      width: 60,
    },
    {
      title: getTitle('Telefone', 'phone'),
      dataIndex: 'phone',
      align: 'center',
      key: 'phone',
      width: 120,
    },
    {
      title: getTitle('Valor da Conta', 'bill_value'),
      dataIndex: 'bill_value',
      align: 'center',
      key: 'bill_value',
      width: 10,
      render: (...args) =>
        args[0]
          ? args[0].toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : '',
    },
    {
      title: getTitle('Cidade/UF', 'city'),
      dataIndex: 'city',
      key: 'city',
      align: 'center',
      width: 50,
      render: (...args) => `${args[1].city}-${args[1].state}`,
    },
    {
      title: getTitle('Data de Cadastro', 'created_at'),
      dataIndex: 'created_at',
      key: 'signup_date',
      width: 150,
      align: 'center',
      render: (...args) => (args[0] ? moment(args[0]).format(dtFormat) : ''),
    },
    {
      title: 'Situação',
      dataIndex: 'situation',
      align: 'center',
      key: 'situation',
      width: 150,
      render: (value, client) => {
        const currentStep = getCurrentClientStepType(client.steps)
        return (
          <div>
            {currentStep && (
              <StatusCard centered style={getClientStepStyle(currentStep)} />
            )}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      render: (value, client) => (
        <S.EditIcon
          src={penIcon}
          onClick={() =>
            window.open(`/customer/${client.id}/details`, '_blank')
          }
        />
      ),
    },
  ]

  const getOptions = () => {
    const results: { id: string; label: any }[] = []
    columns.forEach((column: any) => {
      const { key, title } = column
      if (title && key)
        results.push({ id: key, label: title?.props?.children[0] ?? title })
    })
    return results
  }

  useEffect(() => setPage(1), [
    stateInitials,
    clientType,
    cityIds,
    situation,
    currentSteps,
  ])

  const [date, setDate] = useState<string[]>([])

  useEffect(() => {
    const query = {
      page,
      clientType,
      search,
      state: [stateInitials],
      cityIds,
      currentSteps: updateCurrentSteps(situation),
      bill_status: billStatus,
      proposal_status: proposalStatus,
      visit_status: technicalVisitStatus,
      order: sortData,
      direction: sortDirection,
      date,
    }
    dispatch(StateCreators.getStatesRequest())
    dispatch(Creators.getClientsRequest(query))
  }, [
    dispatch,
    page,
    search,
    stateInitials,
    clientType,
    cityIds,
    situation,
    currentSteps,
    date,
    billStatus,
    proposalStatus,
    technicalVisitStatus,
    sortData,
    sortDirection,
  ])

  useEffect(() => {
    const query = {
      states: [stateInitials],
      ...availableType,
    }
    dispatch(CitiesCreators.getCitiesRequest(query))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInitials])

  const clients = clientList?.map(client => {
    return {
      id: client.id,
      name: client.name,
      email: client.email,
      type: client.type.value,
      phone: insertPhoneMask(client.phone.replace('55', '')),
      city: client.city.name,
      state: client.city.state,
      steps: client.steps,
      key: client.id,
      created_at: client.created_at,
      logs: client.logs,
      last_login: client.last_login,
      bill_value: client.bill_value,
      bill_id: client.bill_id,
      bill_status: client.bill?.status || null,
      technical_status: client.technical_visit?.status,
      hasPartnership: client.hasPartnership || null,
      subscription_lending: client.subscription_lending || null,
      document:
        client.bill?.bill_owner?.document_value ||
        client.bill?.bill_owner?.document_cnpj_value ||
        '',
      integrator: client?.franchisee?.fullname,
    }
  })

  useEffect(() => {
    api
      .get('/admin/states')
      .then(({ data }) => {
        const statesList = data?.map((stt: any) => ({
          id: stt.id,
          label: stt.initials,
        }))
        setStates(statesList)
      })
      .catch(() => toast.error('Não foi possível buscar estados!'))
  }, [])

  const cities = citiesList?.map(city => {
    return {
      id: city.id,
      label: city.name,
    }
  })

  const handlePageChange = (value: number) => {
    setPage(value)
  }

  const handleChange = (value: string) => {
    setPage(1)
    setSearch(value)
  }

  const handleChangeStateFilter = (selectedStates: IItem[]) => {
    const selectedState = selectedStates[0]?.label as string
    setStateInitials(selectedState)
    if (selectedState !== undefined) setIsDisable(false)
  }

  const handleChangeCityFilter = (selectedCities: IItem[]) => {
    const selectedCityIds = selectedCities.map(city => city.id)
    setCityIds(selectedCityIds)
  }

  const handleChangeSituationFilter = (selectedSituations: IItem[]) => {
    const selectedSituationFilter = selectedSituations.map(
      situations => situations.id,
    )
    setSituation(selectedSituationFilter)

    // technical
    if (selectedSituationFilter.includes('TECHNICAL_VISIT'))
      setTechnicalVisitStatus('NULL')

    if (selectedSituationFilter.includes('TECHNICAL_VISIT_CONFIRMED_SCHEDULE'))
      setTechnicalVisitStatus('CONFIRMED_SCHEDULE')

    if (
      selectedSituationFilter.includes('TECHNICAL_VISIT_DONE_AND_NOT_APPROVED')
    )
      setTechnicalVisitStatus('DONE_AND_NOT_APPROVED')

    if (selectedSituationFilter.includes('TECHNICAL_VISIT_FULFILLED'))
      setTechnicalVisitStatus('FULFILLED')

    if (
      !selectedSituationFilter.includes('TECHNICAL_VISIT') &&
      !selectedSituationFilter.includes('TECHNICAL_VISIT_FULFILLED') &&
      !selectedSituationFilter.includes(
        'TECHNICAL_VISIT_DONE_AND_NOT_APPROVED',
      ) &&
      !selectedSituationFilter.includes('TECHNICAL_VISIT_CONFIRMED_SCHEDULE')
    )
      setTechnicalVisitStatus('')

    // bill
    if (selectedSituationFilter.includes('BILL_PENDING'))
      setBillStatus('PENDING')
    if (selectedSituationFilter.includes('BILL_DENIED')) setBillStatus('DENIED')
    if (selectedSituationFilter.includes('BILL')) setBillStatus('NOT_DONE')

    if (
      !selectedSituationFilter.includes('BILL_PENDING') &&
      !selectedSituationFilter.includes('BILL_DENIED') &&
      !selectedSituationFilter.includes('BILL')
    )
      setBillStatus('')

    if (selectedSituationFilter.includes('PROPOSAL_INITIAL'))
      setProposalStatus('PROPOSAL_INITIAL')

    if (selectedSituationFilter.includes('PROPOSAL_FINAL'))
      setProposalStatus('PROPOSAL_FINAL')

    if (
      !selectedSituationFilter.includes('PROPOSAL_INITIAL') &&
      !selectedSituationFilter.includes('PROPOSAL_FINAL')
    )
      setProposalStatus('')
  }

  const handleChangeDates = (selectedDates: ISelectedDatesCalendar) => {
    setDate([selectedDates.dateInitial, selectedDates.dateFinal])
  }

  const handleChangeHiddenFilter = (selectedSituations: IItem[]) => {
    const results: string[] = []
    selectedSituations.forEach((column: any) => {
      const { id } = column
      if (id) results.push(id)
    })
    setHideKeys(results)
  }

  const getFilterContainer = useCallback(() => {
    return (
      <S.FilterContainer>
        {states.length ? (
          <>
            <MultSelect
              isOpen={isOpenState}
              onClose={() => setIsOpenState(false)}
              onClick={() => {
                setIsOpenState(!isOpenState)
              }}
              labelButton="Estados"
              items={states}
              onChange={handleChangeStateFilter}
              isMultiSelect={false}
              minWidth="120px"
            />
          </>
        ) : null}
        {cities.length ? (
          <MultSelect
            disabled={isDisable}
            isOpen={isOpenCity}
            onClose={() => setIsOpenCity(false)}
            onClick={() => {
              setIsOpenCity(!isOpenCity)
            }}
            labelButton="Cidade"
            items={cities}
            onChange={handleChangeCityFilter}
            isMultiSelect
          />
        ) : null}

        <MultSelect
          isOpen={isOpenSituation}
          onClose={() => setIsOpenSituation(false)}
          onClick={() => {
            setIsOpenSituation(!isOpenSituation)
          }}
          labelButton="Situação"
          items={statusFilterData}
          onChange={handleChangeSituationFilter}
          isMultiSelect
        />
      </S.FilterContainer>
    )
  }, [
    cities,
    isDisable,
    isOpenCity,
    isOpenSituation,
    isOpenState,
    states,
    statusFilterData,
  ])

  return (
    <S.Container>
      <S.Header>
        <S.SearchContainer>
          <SearchInput onChange={handleChange} />
        </S.SearchContainer>

        {getFilterContainer()}

        <S.CalendarContainer>
          <Calendar onChange={handleChangeDates} />
        </S.CalendarContainer>

        <S.HideContainer>
          <MultSelect
            items={getOptions()}
            isOpen={isOpenHiddenColumn}
            onClose={() => setIsOpenHiddenColumn(false)}
            onClick={() => {
              setIsOpenHiddenColumn(!isOpenHiddenColumn)
            }}
            labelButton="Ocultar Colunas"
            onChange={handleChangeHiddenFilter}
            isMultiSelect
          />
        </S.HideContainer>
      </S.Header>

      <S.TableContainer>
        {isLoading ? (
          <FullScreenLoader />
        ) : (
          <Table<IClientData>
            columns={columns}
            hideKeys={hideKeys}
            data={clients}
          />
        )}
      </S.TableContainer>

      <S.PaginationContainer>
        <Pagination
          count={pagination?.total_pages || 0}
          size="large"
          shape="rounded"
          variant="outlined"
          page={page}
          onChange={handlePageChange}
        />
      </S.PaginationContainer>
    </S.Container>
  )
}

export default TableView
