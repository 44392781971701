import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'
import { TextField, InputLabel } from '@material-ui/core'

type StyledType = {
  selected?: boolean
}

export const StyledDateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`
export const CalendarIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
export const StyledTimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-height: 350px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0b2859;
    border: 1px solid #0b2859;
    border-radius: 5px;
    transition: 0.5s;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-left: 1px solid #f1f1f1;
    border-radius: 30px;
  }
`
export const StyledTime = styled.div<StyledType>`
  display: flex;
  padding: 20px 50px;
  border: 2px ${theme.colors.gray[300]} solid;
  color: ${theme.colors.gray[600]};
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  font-weight: bold;
  cursor: pointer;
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
  }

  &:hover {
    background-color: ${theme.colors.blue[200]};
    border: 2px solid transparent;
    color: ${theme.colors.white};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${theme.colors.primary} !important;
      border: 2px solid transparent;
      color: ${theme.colors.white};
    `}
`
export const ScheduleField = styled.div`
  margin: 2px 0;
  display: flex;
  align-items: flex-end;
  &.span {
    font-size: 1.2rem;
  }
`
export const DualFieldInput = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-auto-flow: column;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 400px;
`

export const WarningImage = styled.img`
  width: 40px;
  height: 40px;
  z-index: 3;
  left: -40px;
  top: -55px;
  position: relative;
  @media only screen and (max-width: 768px) {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 70px;
    top: -15px;
  }
`

export const AuroraContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 50px 0;
  position: relative;
`

export const StatusAction = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 25px;
  button {
    margin-right: 50px;
    &:first-child {
      margin-right: 10px;
    }
  }
`

export const AuroraImage = styled.img`
  z-index: 2;
  max-width: 150px;
  @media (min-width: 300px) and (max-width: 1024px) {
    height: 120px;
    width: 120px;
  }
`

export const Bar = styled.div`
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 60px;
  display: flex;
  height: 110px;
  padding-left: 200px;
  position: absolute;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 120px;
    justify-content: center;
    padding: 10px;
    padding-left: 100px;
    width: 100%;
    text-align: left;
  }
`

export const StatusSeparator = styled.span`
  margin-left: 10px;
  font-size: 30px;
  @media (min-width: 300px) and (max-width: 1024px) {
    display: none;
  }
`

export const ScheduleFieldKey = styled.span`
  color: ${theme.colors.gray[200]};
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 5px;
`

export const ScheduleFieldValue = styled.span`
  color: ${theme.colors.gray[600]};
  font-size: 1.2rem;
`
export const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

export const ModalActions = styled.div`
  display: inline-flex;
`

export const ModalTextContent = styled.span`
  margin-bottom: 20px;
  font-size: 18px;
  text-align: justify;
  font-weight: bold;
`

export const ButtonCancel = styled.button`
  background: #ffd500;
  border-radius: 7px;
  border: 2px solid #ffd500;
  width: 260px;
  padding: 12px;
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  color: ${theme.colors.black};
  font-weight: 800;
  cursor: pointer;
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    width: 70%;
  }
  &:hover {
    background: white;
    border: 2px solid #ffd500;
  }
`

export const StyledTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: auto auto;
  }
`

export const StyledTextFieldContainer = styled.div`
  flex: 1;
  margin-bottom: 10px;

  & > label {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
  }
`

export const CustomInputLabel = styled(InputLabel)``

export const MuiInput = styled(TextField)`
  width: 100%;
  padding-right: 1rem;
  border-radius: 0.25rem;

  input {
    padding: 10px 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.23) !important;
  }
`
