/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useMemo } from 'react'

import { insertPhoneMask } from '@/utils/insertNumberMask/insertPhoneMask'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import AlarmOutlinedIcon from '@material-ui/icons/AlarmOutlined'
import CheckIcon from '@material-ui/icons/Check'
import HourglassEmptyOutlinedIcon from '@material-ui/icons/HourglassEmptyOutlined'

import DoubleColumn from '../DoubleColumn/DoubleColumn'
import Priority from '../Priority/Priority'
import { useStyles } from '../styles'

type InfoCardPropsType = {
  id: string
  fullName: string
  phone: string
  power: string
  proposalValue: string
  priority: number
  time: string
  noProposal: boolean
  hasBill: boolean
}

const InfoCard: FC<InfoCardPropsType> = ({
  id,
  fullName,
  phone,
  power,
  proposalValue,
  priority,
  time,
  noProposal,
  hasBill,
}) => {
  const classes = useStyles()

  const initials = useMemo(() => {
    const splitedName = fullName.split(' ')
    const surnameIndex = splitedName.length - 1
    const nameInitials: string[] = []
    splitedName.forEach((name, index) => {
      if (index === 0 || index === surnameIndex)
        nameInitials.push(name.charAt(0))
    })

    return nameInitials.join('').toUpperCase()
  }, [fullName])

  return (
    <Card className={classes.cardInfo}>
      <Box
        className={classes.infoHeader}
        onClick={() => window.open(`/customer/${id}/details`, '_blank')}
      >
        <Box className={classes.avatar}>{initials}</Box>
        <DoubleColumn title="Nome" content={fullName} />
      </Box>
      <Box className={classes.infoRow}>
        <div className={classes.ContainerSection}>
          <div style={{ width: '55%' }}>
            <DoubleColumn
              title="Telefone"
              content={insertPhoneMask(
                phone.startsWith('55') ? phone.slice(2) : phone,
              )}
            />
          </div>
          <DoubleColumn title="Potência" content={power} />
        </div>
        <div className={classes.ContainerSection}>
          <div style={{ width: '55%' }}>
            <DoubleColumn
              title={noProposal ? 'Conta' : 'Proposta'}
              content={proposalValue}
            />
          </div>
          <DoubleColumn
            title="Prioridade"
            content={<Priority id={id} priority={priority} />}
          />
        </div>
        <div className={classes.ContainerSection}>
          <div style={{ width: '55%' }}>
            <DoubleColumn
              title="Conta de luz"
              content={
                <Box
                  className={
                    hasBill
                      ? classes.selectLikeCardApproved
                      : classes.selectLikeCardWaiting
                  }
                >
                  {hasBill ? (
                    <>
                      <CheckIcon
                        fontSize="small"
                        style={{ color: '#00FF00' }}
                      />
                      Enviada
                    </>
                  ) : (
                    <>
                      <HourglassEmptyOutlinedIcon
                        style={{ color: '#FA7000' }}
                        fontSize="small"
                      />
                      Pendente
                    </>
                  )}
                </Box>
              }
            />
          </div>
          <DoubleColumn
            title="Tempo"
            content={
              <Box className={classes.selectLikeCard}>
                <AlarmOutlinedIcon fontSize="small" /> {time}
              </Box>
            }
          />
        </div>
      </Box>
    </Card>
  )
}

export default InfoCard
