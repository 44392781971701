import styled from 'styled-components'

import { Form as FormComponent } from '@unform/web'

export interface InputProps {
  width?: string | undefined
  marginRight?: string | undefined
  marginLeft?: string | undefined
}

export const Container = styled.div`
  padding: 0px 33px;
  height: 81vh;
  @media only screen and (max-width: 768px) {
    padding: 0px 20px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 565px;
  }
`
export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0px 0 0px;
  height: auto;
`
export const FormLineInputCities = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const FormLineInput = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
export const ContainerButton = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 1rem;
  padding: 0 0 2rem;
  align-self: flex-end;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    margin-top: 1rem;
  }
`
export const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
`
export const ContainerInput = styled.div<InputProps>`
  width: 100%;
`
export const Form = styled(FormComponent)`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
