import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  cardsWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    overflow: 'auto',
    transform: 'rotateX(180deg)',
  },
  cardColumn: {
    minWidth: 275,
    maxWidth: 275,
    height: 'fit-content',
    borderRadius: '1rem',
    margin: '0 0.5rem 1rem',
    transform: 'rotateX(180deg)',
  },
  cardContent: {
    padding: 0,
    paddingBottom: '0 !important',
  },
  columnHeader: {
    height: '6rem',
    backgroundColor: '#0B2859',
    display: 'grid',
    placeItems: 'center',
    textAlign: 'center',
    color: '#ffffff',
    fontFamily: 'Montserrat',
    fontSize: '1.0rem',
    fontWeight: 700,
    lineHeight: '1rem',
  },
  columnSummary: {
    height: '6rem',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    color: '#3B3B3B',
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '2rem',
    padding: '0 2rem',
  },
  skeleton: {
    height: '6rem',
    backgroundColor: '#05132a',
    // display: 'grid',
    placeItems: 'center',
    gridTemplateColumns: '1fr 0',
    textAlign: 'center',
    color: '#3B3B3B',
    fontFamily: 'Montserrat',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '2rem',
    display: 'none',
  },
  columnContent: {
    backgroundColor: '#D9D9D9',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '1rem',
  },
  summaryValue: {
    fontWeight: 300,
  },
  cardInfo: {
    width: '100%',
    height: 270,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, 1fr)',
    backgroundColor: '#ffffff',
    borderRadius: '1rem',
    padding: '1rem',
  },
  infoHeader: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
    columnGap: '1rem',
    cursor: 'pointer',
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    columnGap: '1rem',
  },
  doubleColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '0.2rem',
  },
  doubleTitle: {
    fontWeight: 700,
    fontSize: '0.8rem',
    color: '#787878',
  },
  doubleText: {
    fontSize: '0.8rem',
    color: '#3B3B3B',
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    backgroundColor: '#0B2859',
    color: '#fff',
    fontSize: '1.5rem',
    fontWeight: 500,
    display: 'grid',
    placeItems: 'center',
  },
  formControl: {},
  select: {
    width: '115px',
    height: '3rem',
    borderRadius: '0.5rem',
    backgroundColor: '#F3F3F3',
    '&:before': {
      borderBottom: 'none',
    },
    '& div': {
      padding: '10px',
      fontSize: '0.7rem',
      color: '#3B3B3B',
    },
  },
  selectRed: {
    width: '115px',
    height: '3rem',
    borderRadius: '0.5rem',
    backgroundColor: '#F3F3F3',
    '&:before': {
      borderBottom: 'none',
    },
    '& div': {
      padding: '10px',
      fontSize: '0.7rem',
      color: '#fff',
    },
    '& svg': {
      color: '#fff',
    },
  },
  selectLikeCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.25rem',
    width: '90px',
    height: '2.6rem',
    borderRadius: '0.5rem',
    padding: '10px',
    backgroundColor: '#F3F3F3',
    fontSize: '0.8rem',
    color: '#3B3B3B',
  },
  selectLikeCardPending: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.25rem',
    width: '90px',
    height: '2.6rem',
    borderRadius: '0.5rem',
    padding: '10px',
    backgroundColor: '#F3F3F3',
    fontSize: '0.8rem',
    color: '#3B3B3B',
  },
  selectLikeCardApproved: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.25rem',
    width: '90px',
    height: '2.6rem',
    borderRadius: '0.5rem',
    padding: '10px',
    backgroundColor: '#CAFFD0',
    fontSize: '0.8rem',
    color: '#3B3B3B',
  },
  selectLikeCardWaiting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.25rem',
    width: '90px',
    height: '2.6rem',
    borderRadius: '0.5rem',
    padding: '10px',
    backgroundColor: '#FFD580',
    fontSize: '0.8rem',
    color: '#3B3B3B',
  },
  seeMore: {
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#D9D9D9',
    padding: '1rem 0',
    cursor: 'pointer',
    color: '#3366CC',
  },
  ContainerSection: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    marginTop: 15,
  },
})
