import styled from 'styled-components'

import theme from '@/assets/styles/theme'

type StyledType = {
  disabled?: boolean
}

export const Span = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: ${theme.colors.gray[100]};
  border-radius: 50%;
  background-color: ${theme.colors.white};
  border: 1px solid #000;
  opacity: 0.6;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

export const ContainerLabel = styled.label<StyledType>`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  &:checked input ~ ${Span} {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.black};
  }

  input:checked ~ ${Span} {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.primary};
  }

  input:checked ~ ${Span}:after {
    display: block;
  }

  ${Span}:after {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${theme.colors.primary};
    margin: 2px 0px 0px 2px;
  }
`
