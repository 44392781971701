import React from 'react'
import { toast } from 'react-toastify'

import Button from '@/components/Ui/Button'
import { useState } from '@/hooks/useState'
import api from '@/services/api'

import CouponsTable from './CouponsTable'
import CouponCreateDialog from './CreateDialog'
import * as CouponTypes from './types'

interface ICouponsManagerState {
  coupons: CouponTypes.ICoupon[]
  loading: boolean
  creating: boolean
  showCreateModal: boolean
}

const CouponsManager = () => {
  const { state, setState } = useState<ICouponsManagerState>({
    coupons: [],
    loading: false,
    creating: false,
    showCreateModal: false,
  })

  const getCoupons = () => {
    api.get('/admin/coupons').then(({ data }) => {
      if (data.success) setState({ coupons: data.data })
    })
  }

  const createCoupon = ({
    coupon_code,
    coupon_ref,
  }: {
    coupon_code: CouponTypes.ICoupon['coupon_code']
    coupon_ref: CouponTypes.ICoupon['coupon_ref']
  }): void => {
    setState({ creating: true })
    api
      .post('/admin/coupons', {
        coupon_code: coupon_code.toUpperCase(),
        coupon_ref,
      })
      .then(() => toast.success('Criado com sucesso'))
      .finally(() => {
        setState({ creating: false, showCreateModal: false })
        getCoupons()
      })
  }

  const editCoupon = ({
    id,
    coupon_code,
    coupon_ref,
  }: {
    id: CouponTypes.ICoupon['id']
    coupon_code: CouponTypes.ICoupon['coupon_code']
    coupon_ref: CouponTypes.ICoupon['coupon_ref']
  }) => {
    setState({ creating: true })
    api
      .put(`/admin/coupons/${id}`, { coupon_code, coupon_ref })
      .then(() => toast.success('Atualizado com sucesso'))
      .finally(() => {
        setState({ creating: false })
        getCoupons()
      })
  }

  const deleteCoupon = ({ id }: { id: CouponTypes.ICoupon['id'] }) => {
    setState({ creating: true })
    api
      .delete(`/admin/coupons/${id}`)
      .then(() => toast.success('Deletado com sucesso'))
      .finally(() => {
        setState({ creating: false })
        getCoupons()
      })
  }

  const toggleCouponStatus = ({
    id,
    active,
  }: {
    id: CouponTypes.ICoupon['id']
    active: CouponTypes.ICoupon['active']
  }): void => {
    setState({ creating: true })
    api
      .put(`/admin/coupons/${id}`, { active })
      .then(() => toast.success('Status atualizado'))
      .finally(() => {
        setState({ creating: false })
        getCoupons()
      })
  }

  React.useEffect(() => {
    getCoupons()
    // eslint-disable-next-line
  }, [])

  return (
    <section>
      <div className="new-item-container" style={{ marginBottom: 15 }}>
        <Button onClick={() => setState({ showCreateModal: true })}>
          Adicionar novo
        </Button>
      </div>

      <CouponsTable
        onDelete={deleteCoupon}
        onEditSubmit={editCoupon}
        onEditStatus={toggleCouponStatus}
        creating={state.creating}
        coupons={state.coupons}
      />

      <CouponCreateDialog
        onClose={() => setState({ showCreateModal: false })}
        disabledActions={state.creating}
        onConfirm={createCoupon}
        open={state.showCreateModal}
      />
    </section>
  )
}

export default CouponsManager
