import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.header`
  align-items: center;
  background-color: ${theme.colors.white};
  display: flex;
  grid-area: header;
  justify-content: flex-end;
  padding: 0 25px;

  @media screen and (max-width: ${theme.breakpoints.md}) {
    padding: 0 15px;
  }

  > ul {
    > li {
      cursor: pointer;
    }
  }
`

export const Nav = styled.ul`
  display: flex;
  padding-top: 10px;
`

export const NavItem = styled.li`
  padding-left: 20px;
`

export const NavLink = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 7px;

  > img {
    height: 27px;
    width: 27px;

    @media screen and (max-width: ${theme.breakpoints.sm}) {
      height: 24px;
      width: 24px;
    }
  }
`

export const NavLinkBell = styled.button`
  background: ${theme.colors.primary};
  border: none;
  cursor: pointer;
  padding: 7px 10px;
  border-radius: 100%;
  > img {
    height: 27px;
    width: 27px;
    @media screen and (max-width: ${theme.breakpoints.sm}) {
      height: 24px;
      width: 24px;
    }
  }
`

export const BellContainer = styled.div`
  background: ${theme.colors.primary};
  width: 50px;
  height: 50px;
  border-radius: 100%;
  & img {
    width: 25px;
    height: 25px;
    fill: ${theme.colors.white};
  }
`

export const Badge = styled.span`
  background: ${theme.colors.lightBlue};
  border-radius: 100%;
  color: ${theme.colors.black};
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  margin-left: -5px;
  line-height: 18px;
  min-height: 18px;
  min-width: 18px;
  text-align: center;
  z-index: 1;

  padding: 3px 5px;

  position: absolute;
  top: 30px;

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    top: 7px;
  }
`
