import React, { MouseEventHandler } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { IPermission } from '@/@types/Permission'
import IconCustomer from '@/assets/images/icons/ic-customer.svg'
import IconLogout from '@/assets/images/icons/ic-logout.svg'
import IconProfile from '@/assets/images/icons/ic-profile.svg'
import IconTechnicalVisit from '@/assets/images/icons/ic-technical-visit.svg'
import IconUsers from '@/assets/images/icons/ic-users.svg'
import Installation from '@/assets/images/icons/installation.svg'
import LogoBTG from '@/assets/images/logoBTG.svg'
import { useWindowSize } from '@/hooks/useWindowSize'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Auth'

import { Container, Logo, Nav, NavOptionsContainer } from './styles'

interface SidebarProps {
  isOpen: boolean
  onClick: MouseEventHandler<HTMLElement>
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClick }) => {
  const dispatch = useDispatch()

  const { role: permission, user_id: userId } = useSelector(
    (state: RootState) => state.Auth,
  )

  const handleLogout = () => {
    dispatch(Creators.logoutRequest())
  }

  const { height } = useWindowSize()

  const isAdmin = permission === IPermission.ADMIN
  const isCommercial = permission === IPermission.COMMERCIAL
  const isTechnician = permission === IPermission.TECHNICIAN
  const isFranchisee = permission === IPermission.FRANCHISEE

  return (
    <Container isOpen={isOpen} onClick={onClick}>
      <Logo>
        <img src={LogoBTG} alt="Banco BTG" />
      </Logo>

      <NavOptionsContainer height={height}>
        <Nav style={{ flex: 1 }}>
          {isAdmin && (
            <NavLink to="/users" activeClassName="active">
              <img src={IconUsers} alt="Usuários" />
              <span>Usuários</span>
            </NavLink>
          )}
          {(isAdmin || isCommercial || isFranchisee) && (
            <NavLink to="/customer" activeClassName="active">
              <img src={IconCustomer} alt="Clientes" />
              <span>Clientes</span>
            </NavLink>
          )}
          {(isAdmin || isCommercial || isTechnician || isFranchisee) && (
            <NavLink to="/visit" activeClassName="active">
              <img src={IconTechnicalVisit} alt="Visita Técnica" />
              <span>Visita Técnica</span>
            </NavLink>
          )}
          {(isAdmin || isFranchisee) && (
            <NavLink to="/installation" activeClassName="active">
              <img src={Installation} alt="instalação" />
              <span>Instalação</span>
            </NavLink>
          )}
        </Nav>
        <Nav>
          {isAdmin && (
            <NavLink to={`/users/${userId}/edit`} activeClassName="active">
              <img src={IconProfile} alt="Perfil" />
              <span>Perfil</span>
            </NavLink>
          )}

          <div onClick={handleLogout} aria-hidden="true">
            <img src={IconLogout} alt="Sair" />
            <span>Sair</span>
          </div>
        </Nav>
      </NavOptionsContainer>
    </Container>
  )
}

export default Sidebar
