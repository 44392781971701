import produce from 'immer'
import { createReducer, createActions } from 'reduxsauce'

import {
  ActionTypes,
  TypeInitial,
  ClientReportTypeEnum,
  IClientReport,
  IReportStepThree,
  IEditReportStepThree,
  IReportStepsStatus,
} from './types'

type ClientReportType = typeof ClientReportTypeEnum

export const { Types, Creators: ReportCreators } = createActions<
  ClientReportType,
  ActionTypes
>({
  getReportTwoRequest: { clientId: '' },
  getReportTwoSuccess: ['clientReportTwo'],
  getReportTwoFailure: {},
  getReportThreeRequest: {},
  getReportThreeSuccess: ['clientReportThree'],
  getReportThreeFailure: [],
  setIsReportThree: ['isEditReportThree'],
  editReportThreeRequest: ['editReportStepThree'],
  editReportThreeSuccess: [],
  editReportThreeFailure: [],
  setIsEditReportTwoTechnician: ['isEditReportTwo'],
  editReportStepTwoRequest: ['reportTwo'],
  editReportStepTwoSuccess: {},
  editReportStepTwoFailure: {},
  getReportOneRequest: [],
  getReportOneSuccess: ['clientReportOne'],
  getReportOneFailure: [],
  setIsReportOne: ['isEditReportOne'],
  editReportOneRequest: ['editReportStepOne'],
  editReportSaveImageRequest: ['editReportSaveImage'],
  editReportSavePdfRequest: ['editReportSavePdf'],
  editReportOneSuccess: [],
  editReportOneFailure: [],
  getReportsRequest: [],
  setIsReportFive: ['isEditReportFive'],
  getReportFiveRequest: [],
  getReportFiveSuccess: ['clientReportFive'],
  editReportFiveRequest: ['editReportStepFive'],
  getReportFiveFailure: [],
  editReportFiveSuccess: [],
  editReportFiveFailure: [],
  setIsReportSix: ['isEditReportSix'],
  setIsApprovalReport: ['isEditApprovalReport'],
  getReportSixRequest: [],
  getReportSixSuccess: ['clientReportSix'],
  editReportSixRequest: ['editReportStepSix'],
  getReportSixFailure: [],
  editReportSixSuccess: [],
  editReportSixFailure: [],
  getReportsSuccess: ['reports'],
  getReportsFailure: [],
})

export const INITIAL_STATE: TypeInitial = {
  isEditReportTwo: false,
  isLoading: false,
  clientReportOne: undefined,
  clientReportTwo: undefined,
  clientReportThree: undefined,
  isEditReportThree: false,
  editReportStepThree: undefined,
  isEditReportOne: false,
  isEditReportFive: false,
  isEditReportSix: false,
  reports: [],
  isEditApprovalReport: false,
}

export const setIsEditReportTwoTechnician = (
  state = INITIAL_STATE,
  { isEditReportTwo }: { isEditReportTwo: boolean },
) => ({
  ...state,
  isEditReportTwo,
})

export const getReportTwoRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const getReportTwoSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { clientReportTwo }: { clientReportTwo: IClientReport },
): TypeInitial =>
  produce(state, () => ({
    ...state,
    clientReportTwo,
    isLoading: false,
  }))

export const getReportTwoFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const getReportThreeRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({
  ...state,
  isLoading: true,
})

export const getReportThreeSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { clientReportThree }: { clientReportThree: IReportStepThree },
): TypeInitial => ({
  ...state,
  clientReportThree,
  isLoading: false,
})

export const getReportThreeFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({
  ...state,
  isLoading: false,
})

export const setIsReportThree = (
  state: TypeInitial = INITIAL_STATE,
  { isEditReportThree }: { isEditReportThree: boolean },
): TypeInitial => ({
  ...state,
  isEditReportThree,
})

export const editReportThreeRequest = (
  state: TypeInitial = INITIAL_STATE,
  { editReportStepThree }: { editReportStepThree: IEditReportStepThree },
): TypeInitial => ({
  ...state,
  editReportStepThree,
  isLoading: true,
})

export const editReportThreeSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({
  ...state,
  isLoading: false,
})

export const editReportThreeFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({
  ...state,
  isLoading: false,
})

export const editReportStepTwoRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const editReportStepTwoSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportStepTwoFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const getReportOneRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const getReportOneSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { clientReportOne }: { clientReportOne: IClientReport },
): TypeInitial => ({
  ...state,
  clientReportOne,
  isLoading: false,
})

export const getReportOneFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const setIsReportOne = (
  state: TypeInitial = INITIAL_STATE,
  { isEditReportOne }: { isEditReportOne: boolean },
): TypeInitial => ({
  ...state,
  isEditReportOne,
})

export const setIsReportFive = (
  state: TypeInitial = INITIAL_STATE,
  { isEditReportFive }: { isEditReportFive: boolean },
): TypeInitial => ({
  ...state,
  isEditReportFive,
})

export const getReportFiveRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const getReportFiveSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { clientReportFive }: { clientReportFive: IClientReport },
): TypeInitial => ({
  ...state,
  clientReportFive,
  isLoading: false,
})

export const getReportFiveFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportFiveSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportFiveFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportFiveRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const setIsReportSix = (
  state: TypeInitial = INITIAL_STATE,
  { isEditReportSix }: { isEditReportSix: boolean },
): TypeInitial => ({
  ...state,
  isEditReportSix,
})

export const getReportSixRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const getReportSixSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { clientReportSix }: { clientReportSix: IClientReport },
): TypeInitial => ({
  ...state,
  clientReportSix,
  isLoading: false,
})

export const getReportSixFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportSixSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportSixFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportSixRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const editReportOneRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const editReportSaveImageRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const editReportSavePdfRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const editReportOneSuccess = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const editReportOneFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const getReportsRequest = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: true })

export const getReportsSuccess = (
  state: TypeInitial = INITIAL_STATE,
  { reports }: { reports: IReportStepsStatus[] },
): TypeInitial => ({
  ...state,
  reports,
  isLoading: false,
})

export const getReportsFailure = (
  state: TypeInitial = INITIAL_STATE,
): TypeInitial => ({ ...state, isLoading: false })

export const setIsApprovalReport = (
  state: TypeInitial = INITIAL_STATE,
  { isEditApprovalReport }: { isEditApprovalReport: boolean },
): TypeInitial => ({
  ...state,
  isEditApprovalReport,
})

export const reducer = createReducer<TypeInitial, ActionTypes>(INITIAL_STATE, {
  [Types.SET_IS_EDIT_REPORT_TWO_TECHNICIAN]: setIsEditReportTwoTechnician,
  [Types.GET_REPORT_TWO_REQUEST]: getReportTwoRequest,
  [Types.GET_REPORT_TWO_SUCCESS]: getReportTwoSuccess,
  [Types.GET_REPORT_TWO_FAILURE]: getReportTwoFailure,

  [Types.GET_REPORT_THREE_REQUEST]: getReportThreeRequest,
  [Types.GET_REPORT_THREE_SUCCESS]: getReportThreeSuccess,
  [Types.GET_REPORT_THREE_FAILURE]: getReportThreeFailure,

  [Types.SET_IS_REPORT_THREE]: setIsReportThree,

  [Types.EDIT_REPORT_THREE_REQUEST]: editReportThreeRequest,
  [Types.EDIT_REPORT_THREE_SUCCESS]: editReportThreeSuccess,
  [Types.EDIT_REPORT_THREE_FAILURE]: editReportThreeFailure,

  [Types.EDIT_REPORT_STEP_TWO_REQUEST]: editReportStepTwoRequest,
  [Types.EDIT_REPORT_STEP_TWO_SUCCESS]: editReportStepTwoSuccess,
  [Types.EDIT_REPORT_STEP_TWO_FAILURE]: editReportStepTwoFailure,

  [Types.GET_REPORT_ONE_REQUEST]: getReportOneRequest,
  [Types.GET_REPORT_ONE_SUCCESS]: getReportOneSuccess,
  [Types.GET_REPORT_ONE_FAILURE]: getReportOneFailure,

  [Types.SET_IS_REPORT_ONE]: setIsReportOne,
  [Types.GET_REPORT_FIVE_REQUEST]: getReportFiveRequest,
  [Types.SET_IS_REPORT_FIVE]: setIsReportFive,
  [Types.GET_REPORT_FIVE_SUCCESS]: getReportFiveSuccess,
  [Types.GET_REPORT_FIVE_FAILURE]: getReportFiveFailure,
  [Types.EDIT_REPORT_FIVE_SUCCESS]: editReportFiveSuccess,
  [Types.EDIT_REPORT_FIVE_FAILURE]: editReportFiveFailure,
  [Types.EDIT_REPORT_FIVE_REQUEST]: editReportFiveRequest,
  [Types.GET_REPORT_SIX_REQUEST]: getReportSixRequest,
  [Types.SET_IS_REPORT_SIX]: setIsReportSix,
  [Types.GET_REPORT_SIX_SUCCESS]: getReportSixSuccess,
  [Types.GET_REPORT_SIX_FAILURE]: getReportSixFailure,
  [Types.EDIT_REPORT_SIX_SUCCESS]: editReportSixSuccess,
  [Types.EDIT_REPORT_SIX_FAILURE]: editReportSixFailure,
  [Types.EDIT_REPORT_SIX_REQUEST]: editReportSixRequest,

  [Types.EDIT_REPORT_ONE_REQUEST]: editReportOneRequest,
  [Types.EDIT_REPORT_SAVE_IMAGE_REQUEST]: editReportSaveImageRequest,
  [Types.EDIT_REPORT_SAVE_PDF_REQUEST]: editReportSavePdfRequest,
  [Types.EDIT_REPORT_ONE_SUCCESS]: editReportOneSuccess,
  [Types.EDIT_REPORT_ONE_FAILURE]: editReportOneFailure,

  [Types.GET_REPORTS_REQUEST]: getReportsRequest,
  [Types.GET_REPORTS_SUCCESS]: getReportsSuccess,
  [Types.GET_REPORTS_FAILURE]: getReportsFailure,

  [Types.SET_IS_APPROVAL_REPORT]: setIsApprovalReport,
})
