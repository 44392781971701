import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Container = styled.div`
  margin: ${theme.spacing[12]} ${theme.spacing[6]} 0 ${theme.spacing[12]};
  padding: ${theme.spacing[3]};
  min-height: 60vh;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;

  @media only screen and (max-width: 750px) {
    margin: ${theme.spacing[4]};
    margin-bottom: ${theme.spacing[24]};
    row-gap: 1rem;
  }
`

export const DetailsContainer = styled.div`
  margin: ${theme.spacing[12]} ${theme.spacing[6]} 0 ${theme.spacing[12]};
  padding: ${theme.spacing[3]};
  min-height: 60vh;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 40px;
  width: 100%;

  @media only screen and (max-width: 750px) {
    margin: ${theme.spacing[4]};
    row-gap: 1rem;
  }
`

export const SmallText = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 130%;
`

export const SmallTextBold = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
`

export const Subcontainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`

export const SingleCardSubContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border: 1px solid ${theme.colors.gray[200]};

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`

export const BigNumberWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const Sheet = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2.5rem;
  margin-right: 2rem;
  background-color: ${theme.colors.gray[200]};
  border-radius: ${theme.spacing[2]};

  @media only screen and (max-width: 750px) {
    margin-bottom: 1rem;
    padding: 2rem 1rem;
    margin-right: 0;
  }
`

export const NumberSheet = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-right: 2rem;
  background-color: ${theme.colors.gray[200]};
  border-radius: ${theme.spacing[2]};

  @media only screen and (max-width: 750px) {
    margin-bottom: 1rem;
    margin-right: 0;
  }
`

export const SubTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 130%;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
  }
`

export const UnderlinedText = styled.div`
  border-bottom: 3px solid #05132a;
  width: 7rem;
  margin-bottom: 0.5rem;
  overflow: visible;
  white-space: nowrap;
`

export const Text = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 130%;
`

export const SubText = styled.div`
  height: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 130%;
  padding-bottom: 0.3rem;
`

export const ContentColumns = styled.div`
  width: 100%;
  display: grid;
  gap: 5rem 2rem;
  grid-template-columns: 1fr 30%;

  @media only screen and (max-width: 750px) {
    gap: 3rem 0.5rem;
  }
`

export const DivContent = styled.div`
  width: 100%;
  min-height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const SmallDivContent = styled.div`
  width: 100%;
  min-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const IconWrapper = styled.div`
  margin-bottom: 0.5rem;
`

export const RowIconWrapper = styled.div`
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`

export const IconLeft = styled.img`
  width: 3rem;
  height: 3rem;

  @media only screen and (max-width: 750px) {
    width: 2rem;
    height: 2rem;
  }
`

export const IconRight = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  align-self: center;
  justify-self: center;
`

export const SmallIconRight = styled.img`
  width: 32px;
  height: 32px;
  align-self: center;
  justify-self: center;
  margin-right: 5px;
  margin-bottom: 15px;

  @media only screen and (max-width: 750px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const IconTitle = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 1.8rem;
  line-height: '130%';
  margin-left: 1.8rem;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
  }
`

export const ContentDescription = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 750px) {
    font-size: 1.5rem;
  }
`

export const RowContentDescription = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  padding-bottom: 5px;
  line-height: 18px;
  margin-left: 10px;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
  }
`

export const BigNumberContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const MultiplierText = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 2.5rem;

  @media only screen and (max-width: 750px) {
    font-size: 2rem;
  }
`

export const MultiplierNumber = styled.span`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 4rem;

  @media only screen and (max-width: 750px) {
    font-size: 2rem;
  }
`

export const DescriptioContent = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  column-gap: 1rem;
`

export const SmallDescriptioContent = styled.div`
  display: grid;
  grid-template-columns: 20% 1fr;
  column-gap: 0.5rem;
`

export const DescriptionText = styled.span`
  align-self: center;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 130%;

  @media only screen and (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 750px) {
    font-size: 1.5rem;
  }
`

export const SmallDescriptionText = styled.span`
  align-self: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  max-width: 230px;

  color: #020203;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
  }
`

export const CenterDiv = styled.div`
  display: grid;
  place-items: center;
`

export const MonthsValue = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 43px;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
  }
`

export const MonthsDescription = styled.span`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 16px;
  text-align: center;

  @media only screen and (max-width: 750px) {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
`

export const CostWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2rem;

  @media only screen and (max-width: 750px) {
    margin-right: 0;
  }
`

export const ValueWrapper = styled.div`
  width: 50%;
  display: flex;
  place-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 750px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const MoneyWrapper = styled.div`
  display: flex;
  justify-self: end;
  align-items: flex-end;
  margin-right: 2rem;

  @media only screen and (max-width: 750px) {
    margin-right: 0;
    align-items: center;
  }
`

export const TotalCost = styled.span`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 2rem;
  line-height: 130%;
  padding: 0 1.5rem;

  @media only screen and (max-width: 750px) {
    font-size: 1rem;
    padding: 0;
  }
`

export const MoneySign = styled.span`
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0 0.4rem 0.4rem 0;

  @media only screen and (max-width: 750px) {
    font-size: 1rem;
    margin: 0 0.5rem;
  }
`

export const MoneyValue = styled.span`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 3rem;

  @media only screen and (max-width: 750px) {
    font-size: 2rem;
  }
`

export const BanksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }
`

export const SingleBank = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  cursor: pointer;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }
`

export const FilesDescription = styled.span`
  font-family: Roboto;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.25;
`

export const FileContainer = styled.div`
  margin-right: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;

  @media only screen and (max-width: 750px) {
    margin-right: 0;
  }
`

export const ModalWrapper = styled.div`
  width: 100%;
  height: 68vh;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  font-size: 1.5rem;
`

export const FakeInfoIcon = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #05132a;
  display: grid;
  place-items: center;
  font-size: 2rem;
  font-weight: 500;
`

export const ModalHeader = styled.div`
  font-family: 'Roboto';
  width: 100%;
  min-height: 3rem;
  background-color: #05132a;
  display: grid;
  place-items: center;
  font-size: 1.6rem;
  font-weight: bold;
`

export const ProposaModalWrapper = styled.div`
  font-family: 'Roboto';
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const CloseButton = styled.button`
  position: absolute;
  right: 1.5rem;
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
`

export const BoldSpan = styled.span`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 1.5rem;
`

export const UnderlinedBox = styled.div`
  border-bottom: 3px solid #05132a;
  margin-bottom: 0.5rem;
  width: fit-content;
  padding-right: 5rem;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const SpaceBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ContentUnit = styled.span`
  font-family: 'Roboto';
  font-size: 1.4rem;
`

export const IconHeader = styled.img`
  margin-left: 8px;
  height: 28px;
  width: auto;
`
