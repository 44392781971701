import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import Button from '@/components/Ui/Button'
import { CheckBox as Check } from '@/components/Ui/CheckBox/index'

interface IContainer {
  isOpen: boolean
  minWidth: string
}

interface IButtonCheck {
  width: string
}

export const Container = styled.div<IContainer>`
  background-color: ${theme.colors.white};
  border-radius: 5px;
  box-shadow: 0 0 50px 5px #ededed;
  position: absolute;
  z-index: 998;
  padding: 20px;
  display: ${props => (props.isOpen ? 'column' : 'none')};
  min-width: ${props => props.minWidth};
`

export const ContainerCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 300px;

  & > div {
    margin-bottom: 10px;
    &:last-child {
      margin: 0;
    }
  }
`

export const ButtonCheck = styled(Button)`
  background-color: ${theme.colors.gray[200]};
  border: none;
  opacity: 0.7;
  width: 100%;
  height: 40px;
  font-weight: 400;
  color: ${theme.colors.black};

  &:hover {
    background-color: ${theme.colors.gray[100]};
  }
`
export const ArrowImage = styled.img`
  width: 6px;
  margin-left: 10px;
`
export const CheckBox = styled(Check)`
  & > div {
    &:last-child {
      font-weight: 400;
    }
  }
`
export const ContainerButton = styled.div<IButtonCheck>`
  width: ${props => props.width};
`

export const ContainerMultSelect = styled.div`
  position: relative;
`

export const SearchBoxWrapper = styled.div`
  width: 100%;
  margin: 0 0 10px;
`
