import React, { FC } from 'react'

const CloudUploadIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 18.0001L12.41 19.4101L15 16.8301V29.0001H17V16.8301L19.59 19.4101L21 18.0001L16 13.0001L11 18.0001ZM23.5 22.0001H23V20.0001H23.5C25.9853 20.0995 28.0806 18.1653 28.18 15.6801C28.2794 13.1948 26.3453 11.0995 23.86 11.0001H23L22.9 10.1801C22.4411 6.69768 19.4725 4.09461 15.96 4.09461C12.4475 4.09461 9.4789 6.69768 9.01999 10.1801L8.99999 11.0001H8.13999C5.65471 11.0995 3.72058 13.1948 3.81999 15.6801C3.91941 18.1653 6.01471 20.0995 8.49999 20.0001H8.99999V22.0001H8.49999C5.17911 21.979 2.40813 19.458 2.07413 16.1539C1.74012 12.8498 3.9505 9.82528 7.19999 9.14006C8.08961 4.99091 11.7566 2.02686 16 2.02686C20.2434 2.02686 23.9104 4.99091 24.8 9.14006C28.0495 9.82528 30.2599 12.8498 29.9259 16.1539C29.5919 19.458 26.8209 21.979 23.5 22.0001Z"
        fill="#32353C"
      />
    </svg>
  )
}

export default CloudUploadIcon
