import styled from 'styled-components'

interface LabelColors {
  backgroundColor?: string
  width?: string
}

export const Container = styled.div<LabelColors>`
  background-color: ${props => props.backgroundColor};
  width: ${props => props.width};
  text-align: center;
  border-radius: 8px;
  padding: 11px;
`

export const Label = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.color};
`
