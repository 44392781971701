import styled from 'styled-components'

import { Form as FormComponent } from '@unform/web'

export interface InputProps {
  width?: string | undefined
  marginRight?: string | undefined
  marginLeft?: string | undefined
}

export const Container = styled.div`
  padding: 0px 33px;
  height: 71vh;

  @media only screen and (max-width: 768px) {
    padding: 0px 25px;
  }
`
export const ContainerForm = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 35px 0px 0 0px;
  height: auto;
`
export const FormLineInput = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 35px;
  margin-left: 25px;
  label {
    display: block;
    margin-bottom: 21px;
  }

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`
export const ContainerButton = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 1rem;
  padding: 0 0 2rem;
  align-self: flex-end;

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    row-gap: 1rem;
    margin-top: 1rem;
  }
`
export const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
`
export const ContainerInput = styled.div<InputProps>`
  width: 100%;
`
export const SwitchContainer = styled.div`
  display: flex;
  justify-content: start;
  min-width: 170px;
  > p {
    font-size: 15px;
    margin-top: 44px;
    margin-left: 12px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-left: 18px;
    min-width: 150px;
  }
`
export const SelectSwitchContanier = styled.div`
  display: flex;
  width: 100%;
`

export const Form = styled(FormComponent)`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
