import { shade, tint } from 'polished'
import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

interface Props {
  size?: 'small' | 'default' | 'big'
  color?: 'primary' | 'cancel' | 'cancelWithoutWidth' | 'error' | 'success'
  full?: boolean
  loading?: boolean
  inline?: boolean
}

const sizes = {
  small: css`
    border-radius: 8px;
    font-size: 14px;
    padding: 8px;
  `,

  default: css`
    border-radius: 8px;
    font-size: 16px;
    padding: 15px;
  `,

  big: css`
    border-radius: 10px;
    font-size: 20px;
    padding: 17px;
  `,
}

const colors = {
  primary: css`
    background: ${theme.colors.blue[200]};
    color: ${theme.colors.white};

    &:hover {
      background: ${shade(0.1, theme.colors.primary)};
      border: 1px solid ${theme.colors.primary};
    }
  `,

  cancel: css`
    background: transparent;
    border: 1px solid ${theme.colors.gray[400]};
    color: ${theme.colors.gray[400]};
    &:hover {
      background: ${tint(0.8, theme.colors.gray[400])};
    }
  `,
  error: css`
    background: #fe0001;
    border: 1px solid ${theme.colors.gray[400]};
    color: #fff;
    &:hover {
      background: #cb0000;
    }
  `,
  success: css`
    background: #6b9658;
    border: 1px solid ${theme.colors.gray[400]};
    color: #fff;
    &:hover {
      background: #557846;
    }
  `,
  cancelWithoutWidth: css`
    background: transparent;
    border: 1px solid ${theme.colors.gray[400]};
    color: ${theme.colors.gray[400]};

    &:hover {
      background: ${tint(0.8, theme.colors.gray[400])};
    }
  `,
}

const ButtonStyled = styled.button.attrs<Props>(props => ({
  disabled: props.disabled || !!props.loading,
  loading: props.loading ? 1 : 0,
}))<Props>``

export default styled(ButtonStyled)`
  align-items: center;
  background: ${theme.colors.primary};
  border: 1px solid ${theme.colors.blue[200]};
  color: ${theme.colors.gray[700]};
  cursor: pointer;
  font-weight: bold;
  display: ${props => (props.inline ? 'inline-block' : 'flex')};
  justify-content: center;
  transition: background-color 0.2s, color 0.2s;
  width: ${props => (props.full ? '100%' : '')};
  height: fit-content;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${props => (props.size ? sizes[props.size] : sizes.default)}
  ${props => (props.color ? colors[props.color] : colors.primary)}
`
