import styled from 'styled-components'

import theme from '@/assets/styles/theme'

export const Wrapper = styled.div`
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`

export const HeaderText = styled.h4`
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.25;
  margin-bottom: 10px;
`

export const DropArea = styled.div`
  width: 100%;
  height: 110px;
  border-radius: 0.5rem;
  border: 1px dashed #aaa;
  padding: ${theme.spacing[4]} 0;
  background-color: #d8d8d8;
  display: grid;
  grid-template-columns: 80px 1fr;
  cursor: pointer;
`

export const IconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > input {
    display: none;
  }
`

export const TextArea = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const InfoText = styled.h5`
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.25;
`

export const MimeText = styled.h5`
  font-family: Roboto;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.25;
`

export const FileArea = styled.div`
  width: 100%;
  height: 110px;
  border: 1px solid #22c9ae;
  border-radius: 0.5rem;
  padding: ${theme.spacing[4]} 0;
  background-color: ${theme.colors.white};
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  align-items: center;
`

export const FileName = styled.h5`
  font-family: Roboto;
  font-weight: 400;
  font-size: 1.2rem;
  color: #918f8f;
  line-height: 1.25;
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const RemoveButton = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border: none;

  &:hover {
    background-color: #d8d8d8;
  }
`
