/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import api from '@/services/api'
import Box from '@material-ui/core/Box'

import CardColumn from './CardColumn/CardColumn'
import { useStyles } from './styles'

type LeadsType = {
  id: string
  fullName: string
  phone: string
  power: string
  proposalValue: string
  priority: number
  time: string
  status: string
  step: string
  hasBill: boolean
}

type StepsType = 'bill' | 'initial' | 'visit' | 'instalation'

type CardsColumnsType = {
  id: number
  title: string
  steps: string[]
  currentStep: StepsType
  noProposal: boolean
}

const CARDS_COLUMNS: CardsColumnsType[] = [
  {
    id: 0,
    title: 'Novos Leads',
    steps: ['BILL'],
    currentStep: 'bill',
    noProposal: true,
  },
  {
    id: 1,
    title: 'Proposta Inicial',
    steps: ['PROPOSAL_INITIAL'],
    currentStep: 'initial',
    noProposal: false,
  },
  {
    id: 2,
    title: 'Proposta Final',
    steps: ['TECHNICAL_VISIT'],
    currentStep: 'visit',
    noProposal: false,
  },
  {
    id: 3,
    title: 'Instalação',
    steps: ['INSTALATION'],
    currentStep: 'instalation',
    noProposal: false,
  },
]

type StateType = { count: number; previous: number }

type CountStateType = {
  [x in StepsType]: StateType
}

const COUNT_STATE: CountStateType = {
  bill: { count: 10, previous: 10 },
  initial: { count: 10, previous: 10 },
  visit: { count: 10, previous: 10 },
  instalation: { count: 10, previous: 10 },
}

type ValueType = {
  value: number | null
  count: number
}

const CardView = () => {
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [leads, setLeads] = useState<LeadsType[]>([])
  const [leadCount, setLeadCount] = useState<CountStateType>(COUNT_STATE)
  const [totalValues, setTotalValues] = useState<any>({})
  const [meta, setMeta] = useState<any[]>([])

  const getHasMore = useCallback(
    (column: CardsColumnsType) => {
      if (!meta.length) return undefined

      let prevCount = 0
      meta.forEach(mt => {
        if (column.steps.includes(mt.step)) prevCount += mt.count
      })

      return leadCount[column.currentStep].count < prevCount
    },
    [meta, leadCount],
  )

  const getDaysFromCreation = useCallback((dateString: string) => {
    const date_1 = new Date()
    const date_2 = new Date(dateString)

    const difference = date_1.getTime() - date_2.getTime()
    const days = Math.ceil(difference / (1000 * 3600 * 24))

    if (days <= 1) {
      const hours = Math.ceil(difference / (1000 * 3600))
      return `${hours} hora${hours > 1 ? 's' : ''}`
    }

    return `${days} dias`
  }, [])

  const getCurrentStatus = useCallback((bill: any) => {
    switch (bill?.status) {
      case 'NOT_DONE':
        return 'Pendente'
      case 'PENDING':
        return 'Análise'
      case 'APPROVED':
        return 'Aprovada'
      case 'DENIED':
        return 'Reprovada'
      default:
        return 'Pendente'
    }
  }, [])

  const toLocale = useCallback((value: number) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }, [])

  const getProposalValue = useCallback(
    (lead: any) => {
      const currentStep = lead?.steps.find(
        (step: any) => step.status === 'PENDING',
      )
      if (currentStep?.type === 'BILL')
        return lead.bill_value ? `R$ ${toLocale(lead.bill_value)}` : 'R$ 0,00'
      return lead.proposal?.final_value
        ? `R$ ${toLocale(lead.proposal.final_value)}`
        : 'R$ 0,00'
    },
    [toLocale],
  )

  const getPower = useCallback(
    (lead: any, step: string) => {
      if (step === 'BILL') return '-'
      return lead.proposal?.power_of_the_project
        ? `${toLocale(lead.proposal.power_of_the_project)}kW`
        : '-'
    },
    [toLocale],
  )

  const getLeads = useCallback(
    (column: any) => {
      const { steps } = column
      let currentStepCount = 0
      switch (steps[0]) {
        case 'BILL':
          currentStepCount = leadCount.bill.count
          break
        case 'PROPOSAL_INITIAL':
          currentStepCount = leadCount.initial.count
          break
        case 'TECHNICAL_VISIT':
          currentStepCount = leadCount.visit.count
          break
        case 'INSTALATION':
          currentStepCount = leadCount.instalation.count
          break
        default:
          currentStepCount = 10
          break
      }

      const filteredLeads = leads
        .filter(lead => steps.includes(lead.step))
        .slice(0, currentStepCount)

      return filteredLeads
    },
    [leads, leadCount],
  )

  const getTotalValue = useCallback(
    (column: CardsColumnsType) => {
      const { steps } = column
      if (!Object.keys(totalValues).includes(steps[0]))
        return `R$ ${toLocale(0)}`

      let value = 0
      steps.forEach(step => {
        value += totalValues[step]
      })
      return `R$ ${toLocale(value)}`
    },
    [toLocale, totalValues],
  )

  useEffect(() => {
    if (leadCount.bill.count === leadCount.bill.previous) return
    setLeadCount(prev => ({
      ...prev,
      bill: { count: leadCount.bill.count, previous: leadCount.bill.count },
    }))
    setIsLoading(true)
    api
      .get(`/admin/customers_cards/by_step/BILL/${leadCount.bill.count}`)
      .then(({ data }) => {
        const newLeads = leads.filter(lead => lead.step !== 'BILL')
        const billLeads = data.items.map((lead: any) => {
          const step = lead.steps[0].type
          const time = getDaysFromCreation(lead?.created_at)
          const status = getCurrentStatus(lead?.bill)
          const proposalValue = getProposalValue(lead)
          const power = getPower(lead, step)
          const hasBill = lead?.bill !== null

          return {
            id: lead.id,
            fullName: lead.name,
            phone: lead.phone,
            power,
            proposalValue,
            priority: lead.priority,
            time,
            status,
            step,
            hasBill,
          }
        })

        setLeads([...newLeads, ...billLeads])
      })
      .catch(err => console.error('Error getting more bill cards: ', err))
    setIsLoading(false)
  }, [
    leads,
    leadCount.bill,
    getCurrentStatus,
    getDaysFromCreation,
    getPower,
    getProposalValue,
  ])

  useEffect(() => {
    if (leadCount.initial.count === leadCount.initial.previous) return
    setLeadCount(prev => ({
      ...prev,
      initial: {
        count: leadCount.initial.count,
        previous: leadCount.initial.count,
      },
    }))
    setIsLoading(true)
    api
      .get(
        `/admin/customers_cards/by_step/PROPOSAL_INITIAL/${leadCount.initial.count}`,
      )
      .then(({ data }) => {
        const newLeads = leads.filter(lead => lead.step !== 'PROPOSAL_INITIAL')
        const initialLeads = data.items.map((lead: any) => {
          const step = lead.steps[0].type
          const time = getDaysFromCreation(lead?.created_at)
          const status = getCurrentStatus(lead?.bill)
          const proposalValue = getProposalValue(lead)
          const power = getPower(lead, step)
          const hasBill = lead?.bill !== null

          return {
            id: lead.id,
            fullName: lead.name,
            phone: lead.phone,
            power,
            proposalValue,
            priority: lead.priority,
            time,
            status,
            step,
            hasBill,
          }
        })

        setLeads([...newLeads, ...initialLeads])
      })
      .catch(err =>
        console.error('Error getting more proposal initial cards: ', err),
      )
    setIsLoading(false)
  }, [
    leads,
    leadCount.initial,
    getCurrentStatus,
    getDaysFromCreation,
    getPower,
    getProposalValue,
  ])

  useEffect(() => {
    if (leadCount.visit.count === leadCount.visit.previous) return
    setLeadCount(prev => ({
      ...prev,
      visit: { count: leadCount.visit.count, previous: leadCount.visit.count },
    }))
    setIsLoading(true)
    api
      .get(
        `/admin/customers_cards/by_step/TECHNICAL_VISIT/${leadCount.visit.count}`,
      )
      .then(({ data }) => {
        const newLeads = leads.filter(lead => lead.step !== 'TECHNICAL_VISIT')
        const visitLeads = data.items.map((lead: any) => {
          const step = lead.steps[0].type
          const time = getDaysFromCreation(lead?.created_at)
          const status = getCurrentStatus(lead?.bill)
          const proposalValue = getProposalValue(lead)
          const power = getPower(lead, step)
          const hasBill = lead?.bill !== null

          return {
            id: lead.id,
            fullName: lead.name,
            phone: lead.phone,
            power,
            proposalValue,
            priority: lead.priority,
            time,
            status,
            step,
            hasBill,
          }
        })

        setLeads([...newLeads, ...visitLeads])
      })
      .catch(err =>
        console.error('Error getting more technical visit cards: ', err),
      )
    setIsLoading(false)
  }, [
    leads,
    leadCount.visit,
    getCurrentStatus,
    getDaysFromCreation,
    getPower,
    getProposalValue,
  ])

  useEffect(() => {
    if (leadCount.instalation.count === leadCount.instalation.previous) return
    setLeadCount(prev => ({
      ...prev,
      instalation: {
        count: leadCount.instalation.count,
        previous: leadCount.instalation.count,
      },
    }))
    setIsLoading(true)
    api
      .get(
        `/admin/customers_cards/by_step/INSTALATION/${leadCount.instalation.count}`,
      )
      .then(({ data }) => {
        const newLeads = leads.filter(lead => lead.step !== 'INSTALATION')
        const instalationLeads = data.items.map((lead: any) => {
          const step = lead.steps[0].type
          const time = getDaysFromCreation(lead?.created_at)
          const status = getCurrentStatus(lead?.bill)
          const proposalValue = getProposalValue(lead)
          const power = getPower(lead, step)
          const hasBill = lead?.bill !== null

          return {
            id: lead.id,
            fullName: lead.name,
            phone: lead.phone,
            power,
            proposalValue,
            priority: lead.priority,
            time,
            status,
            step,
            hasBill,
          }
        })

        setLeads([...newLeads, ...instalationLeads])
      })
      .catch(err =>
        console.error('Error getting more instalation cards: ', err),
      )
    setIsLoading(false)
  }, [
    leads,
    leadCount.instalation,
    getCurrentStatus,
    getDaysFromCreation,
    getPower,
    getProposalValue,
  ])

  useEffect(() => {
    const steps = ['BILL', 'PROPOSAL_INITIAL', 'TECHNICAL_VISIT', 'INSTALATION']
    Promise.all(
      steps.map(step => api.get(`/admin/customers_cards/by_step/${step}/10`)),
    )
      .then(res => {
        const metaData = res.map(({ data }) => ({
          count: data?.meta?.total_items || 0,
          step: data?.items[0]?.steps[0]?.type || '',
        }))
        setMeta(metaData)
        const [...leads] = res.map(({ data }) => data.items)
        const plainLeads = leads.flat()
        setLeads(
          plainLeads.map((lead: any) => {
            const step = lead.steps[0].type
            const time = getDaysFromCreation(lead?.created_at)
            const status = getCurrentStatus(lead?.bill)
            const proposalValue = getProposalValue(lead)
            const power = getPower(lead, step)
            const hasBill = lead?.bill !== null

            return {
              id: lead.id,
              fullName: lead.name,
              phone: lead.phone,
              power,
              proposalValue,
              priority: lead.priority,
              time,
              status,
              step,
              hasBill,
            }
          }),
        )
        setIsLoading(false)
      })
      .catch(err => console.error('Error getting cards by step: ', err))
  }, [getCurrentStatus, getDaysFromCreation, getPower, getProposalValue])

  useEffect(() => {
    const steps = ['BILL', 'PROPOSAL_INITIAL', 'TECHNICAL_VISIT', 'INSTALATION']
    Promise.all(
      steps.map(step => api.get(`/admin/customers_cards/total_value/${step}`)),
    )
      .then((res: any) => {
        let valuesData = {}
        res.forEach((element: any, resIndex: number) => {
          steps.forEach((step: string, stepIndex: number) => {
            if (resIndex === stepIndex)
              valuesData = { ...valuesData, [step]: element.data || 0 }
          })
        })
        setTotalValues(valuesData)
      })
      .catch(err => console.error('Error getting cards by step: ', err))
  }, [getCurrentStatus, getDaysFromCreation, getPower, getProposalValue])

  return isLoading ? (
    <FullScreenLoader />
  ) : (
    <Box className={classes.cardsWrapper}>
      {CARDS_COLUMNS.map(column => (
        <CardColumn
          key={column.id}
          title={column.title}
          totalValue={getTotalValue(column)}
          leads={getLeads(column)}
          noProposal={column.noProposal}
          clickMore={setLeadCount}
          currentStep={column.currentStep}
          hasMore={getHasMore(column)}
        />
      ))}
    </Box>
  )
}

export default CardView
