import styled from 'styled-components'

import theme from '@/assets/styles/theme'
import { InputLabel, TextField, withStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { Form } from '@unform/web'

export const FormDocumentScore = styled(Form)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 355px;
  height: 65px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 750px) {
    width: 100%;
    column-gap: 0.5rem;
    justify-content: flex-start;
  }
`

export const StyledTextFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;

  @media only screen and (max-width: 750px) {
    display: grid;
    grid-template-columns: auto auto;
  }
`

export const StyledTextFieldContainer = styled.div`
  flex: 1;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
    margin-left: 10px;
  }

  & > label {
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
  }
`

export const CustomInputLabel = styled(InputLabel)``

export const CustomTextField = styled(TextField)`
  && {
    width: 100%;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

export const Title = styled.div`
  color: ${theme.colors.gray[600]};
  font-size: 1.2rem;
  font-weight: bold;
`

export const ContainerBillInfo = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  display: flex;
  margin-top: 10px;
  background-color: ${theme.colors.gray[100]};
  justify-content: flex-start;
  padding: 2rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
    padding: 2rem 1rem 1rem;
  }
`

export const BillInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  @media only screen and (max-width: 750px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1rem;
  }
`

export const Font = styled.b`
  font: Bold 16px/22px Roboto;
  opacity: 1;
  color: ${theme.colors.gray[600]};
  margin-right: 5px;
`

export const ContainerIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 1rem;
  width: 100%;
  justify-content: flex-end;
  padding-top: 2rem;
  padding-bottom: 25px;

  @media only screen and (max-width: 750px) {
    width: 100%;
    flex-direction: column;
    column-gap: 0;
    gap: 1rem 0;
  }
`

export const ContainerSaveButton = styled.div`
  display: flex;
  width: 175px;
  justify-content: flex-end;

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`

export const ContainerCreditInfo = styled.div`
  display: flex;
  flex-direction: row;
`

export const ConsumptionContainer = styled.div`
  border: 1px solid #ccc5;
  border-radius: 6px;
  margin: 0 10px;
  padding: 1px 6px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.primary};

    svg {
      color: ${theme.colors.white} !important;
    }
  }
`

export const ConsumptionInput = styled.input`
  border-radius: 6px;
  text-align: center;
  width: 120px;
`

export const MUITooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: theme.colors.gray[100],
    color: theme.colors.black,
    maxWidth: 250,
    fontSize: '16px',
    border: `1px solid ${theme.colors.gray[300]}`,
    textAlign: 'center',
  },
}))(Tooltip)

type IconProps = {
  hasTooltip?: boolean
}

export const InfoIcon = styled(InfoOutlinedIcon)<IconProps>`
  color: ${theme.colors.red[200]};
  opacity: ${({ hasTooltip }) => (hasTooltip ? 1 : 0)};
  cursor: ${({ hasTooltip }) => (hasTooltip ? 'help' : 'default')};
`

export const PartnershipContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TableHeaderContainer = styled.div`
  width: 100%;
  padding: 16px 8px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ccc;
  font-weight: 600;
`

export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 8px;
  background-color: f2f2f2;
`

export const ProposalContainer = styled.div`
  margin-top: 15px;
`

export const TitleProposal = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  display: flex;
  align-items: flex-end;

  /* light/text/primary */

  color: #25282d;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  @media only screen and (max-width: 768px) {
    width: 95%;
    font-size: 20px;
  }
`

export const NoBillContainer = styled.div`
  width: 100%;

  & > h1 {
    font-family: Montserrat;
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  & > label {
    font-family: Montserrat;
  }
`

export const SpecificProductionContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333;
  width: 100%;
  display: grid;
  grid-template-columns: 250px auto;
  margin-bottom: 2rem;

  @media only screen and (max-width: 750px) {
    grid-template-columns: 1fr;
  }
`

export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  margin-bottom: 1rem;
`

export const MuiInput = styled(TextField)`
  width: 100%;
  padding-right: 1rem;
  border-radius: 0.25rem;

  input {
    padding: 18.5px 14px;
  }

  fieldset {
    border-color: rgba(0, 0, 0, 0.23) !important;
  }
`
