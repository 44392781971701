import styled, { css } from 'styled-components'

import theme from '@/assets/styles/theme'

interface ContainerProps {
  isErrored: boolean
  width?: string
  marginRight?: string
}

export const Container = styled.div<ContainerProps>`
  margin-bottom: 25px;
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};

  label {
    display: block;
    margin-bottom: 8px;
  }

  > small {
    font-size: 13px;
    display: block;
    margin-bottom: 11px;
    opacity: 0.6;
  }

  > input,
  > textarea {
    border: 1px solid ${theme.colors.gray[300]};
    color: ${theme.colors.gray[700]};
    display: block;
    margin-top: 3px;
    padding: 18px;
    resize: vertical;
    transition: border-color 0.2s;
    width: 100%;
    border-radius: 4px;

    &:focus {
      border-color: ${props =>
        props.isErrored ? theme.colors.red[200] : theme.colors.gray[300]};

      ${props =>
        !props.isErrored &&
        css`
          box-shadow: ${theme.colors.gray[300]} 0px 0px 0px 1px;
        `}
    }

    &[disabled] {
      background: ${theme.colors.gray[200]};
      cursor: not-allowed;
    }

    &::placeholder {
      color: ${theme.colors.gray[500]};
    }

    ${props =>
      props.isErrored &&
      css`
        border-color: ${theme.colors.red[200]};
      `}
  }

  > textarea {
    min-height: 200px;
  }

  > span {
    color: ${theme.colors.red[200]};
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  @media only screen and (max-width: 750px) {
    margin-bottom: 1rem;

    label {
      font-size: 0.8rem;
    }
  }
`
