/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import React, { useState, useCallback, ChangeEvent, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { isEmpty, last } from 'lodash'

import Button from '@/components/Ui/Button'
import api from '@/services/api'
import { RootState } from '@/store'
import { Form } from '@unform/web'

import * as S from './styles'

export type ProposalType = {
  id: string
  created_at: string
  updated_at: string
  power_of_the_project: number
  client_id: string
  distributor_kit_cost: number
  sale_value: number
  franchisee_commission: number
  final_value: number
  final_installed_wp: number
  value_kit_for_customer: number
  nf_value: number
  tax_solar21: number
  contribution_margin: number
  total_amount_receivable_franchisee: number
  inverter_quantity: number
  inverter_power: number
  inverter_brand: string
  inverter_warranty: string
  module_quantity: number
  module_brand: string
  module_power: number
  annual_production: number
  annual_savings: number
  payback: number
  module_warranty: number
  type: string
  can_edit?: boolean
}

function NumberFormatCustom(props: {
  [x: string]: any
  name?: any
  inputRef?: any
  onChange?: any
}) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={2}
      prefix="R$"
    />
  )
}
interface IClientEditProps {
  onClickToCancel: () => void
}

const EditClientCommissionInstallationCost: React.FC<IClientEditProps> = ({
  onClickToCancel,
}) => {
  const client = useSelector((state: RootState) => state.Client.client)

  const [kit_price, setKitPrice] = useState<string | undefined>('')
  const [installationMaterialCost, setInstallationMaterialCost] = useState<
    string | undefined
  >('')
  const [proposal, setProposal] = useState<ProposalType | undefined>()

  useEffect(() => {
    const { client_project_info } = client
    setKitPrice(client_project_info?.commission.toFixed(2).replace('.', ','))
    setInstallationMaterialCost(
      client_project_info?.installation_material_cost
        .toFixed(2)
        .replace('.', ','),
    )
  }, [client])

  useEffect(() => {
    if (isEmpty(proposal)) {
      api
        .get(`admin/customers/${client.id}/proposal`)
        .then(({ data }) => {
          const filteredData = data.filter(
            (item: ProposalType) => item.type === 'INITIAL',
          )
          setProposal(last(filteredData))
        })
        .catch(() => toast.error('Erro ao rucuperar a proposta!'))
    }
  }, [proposal, client])

  const handleSubmit = useCallback(async () => {
    const payload = {
      commission: parseFloat(kit_price?.replaceAll('R$', '') || '0'),
      installation_material_cost: parseFloat(
        installationMaterialCost?.replaceAll('R$', '') || '0',
      ),
    }

    await api
      .post(`/admin/customers/${client.id}/project-infos`, payload)
      .then(() => {
        toast.success('Valores atualizados com sucesso!')
        setTimeout(() => {
          window.location.reload()
        }, 5000)
      })
      .catch(() => toast.error('Erro ao atualizar valores!'))
  }, [client.id, kit_price, installationMaterialCost])

  const handleCommissionChange = (event: ChangeEvent<HTMLInputElement>) =>
    setKitPrice(event.target.value)

  const handleInstallationChange = (event: ChangeEvent<HTMLInputElement>) =>
    setInstallationMaterialCost(event.target.value)

  return (
    <S.Container>
      <Form onSubmit={handleSubmit}>
        <S.ContainerForm>
          <S.FormLineInput>
            <S.ContainerInput width="775px" marginRight="35px">
              <S.CustomInputLabel htmlFor="valorKitDistribuidor">
                Valor do kit no distribuidor:
              </S.CustomInputLabel>
              <S.MuiInput
                variant="outlined"
                value={kit_price}
                onChange={handleCommissionChange}
                id="valorKitDistribuidor"
                name="valorKitDistribuidor"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </S.ContainerInput>
            <S.ContainerInput width="775px" marginRight="0px">
              <S.CustomInputLabel htmlFor="valorServico">
                Valor serviço:
              </S.CustomInputLabel>
              <S.MuiInput
                variant="outlined"
                value={installationMaterialCost}
                onChange={handleInstallationChange}
                id="valorServico"
                name="valorServico"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </S.ContainerInput>
          </S.FormLineInput>
        </S.ContainerForm>
        <S.ContainerButton>
          <Button type="button" size="default" onClick={onClickToCancel}>
            Voltar
          </Button>
          <Button
            size="default"
            color="primary"
            type="button"
            onClick={handleSubmit}
          >
            Salvar alterações
          </Button>
        </S.ContainerButton>
      </Form>
    </S.Container>
  )
}

export { EditClientCommissionInstallationCost }
