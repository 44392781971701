/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react'
import InputMask from 'react-input-mask'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import { UserRoleEnum } from '@/@types/user'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { Select as SelectComponent } from '@/components/Ui/Select'
import { useState } from '@/hooks/useState'
import history from '@/services/history'
import { RootState } from '@/store'
import { Creators } from '@/store/ducks/Users'

import './css.css'
import {
  Container,
  FormLineInput,
  ContainerButton,
  SelectContainer,
  ContainerForm,
  ContainerInput,
  Form,
} from './styles'

const options = [
  { value: UserRoleEnum.ADMIN, label: 'Administrador' },
  { value: UserRoleEnum.FRANCHISEE, label: 'Integrador' },
]

interface IUserCreateState {
  selectedRole: string
}

const UserCreate: React.FC = () => {
  const { state, setState } = useState<IUserCreateState>({
    selectedRole: '',
  })

  const dispatch = useDispatch()
  const isLoading = useSelector((state: RootState) => state.User.loading.post)

  interface FormData {
    fullname: string
    firstname: string
    lastname: string
    email: string
    role: UserRoleEnum
    password: string
    confirm_password: string
    cpf: string
    phone: string
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => setState({ selectedRole: e.value })

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        const names = data.fullname.split(' ')

        const { cpf, password, confirm_password, email, phone } = data
        const { selectedRole: role } = state

        const createUserProfile = {
          firstname: names[0],
          lastname: names[names.length - 1],
          email,
          role,
          password,
          confirm_password,
          cpf: cpf.replace(/\D/g, ''),
          phone: phone.replace(/\D+/g, ''),
        }
        const msgPwd =
          'A senha deve conter no mínimo 8 caracteres, com letras e números'
        const regexPwd = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

        const userSchema = Yup.object().shape({
          email: Yup.string()
            .email('Email inválido')
            .required('Email obrigatório'),
          firstname: Yup.string().required('Nome obrigatório'),
          password: Yup.string()
            .required('Senha obrigatório')
            .min(8, msgPwd)
            .matches(regexPwd, msgPwd),
          role: Yup.string().required('Escolha um perfil'),
          confirm_password: Yup.string()
            .required('Senha obrigatório')
            .min(8, msgPwd)
            .matches(regexPwd, msgPwd),
          cpf: Yup.string().required('CPF obrigatório'),
          phone: Yup.string().required('Telefone obrigatório'),
        })

        await userSchema.validate(createUserProfile, {
          abortEarly: false,
        })
        dispatch(Creators.createUserRequest(createUserProfile))
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          toast.error(err.errors[0])
        } else {
          toast.error('Erro ao criar usuário!')
        }
      }
    },
    [dispatch, state],
  )

  const handleCancel = () => {
    history.push('/users')
  }

  return (
    <PageContent
      title="Adicionar Usuário"
      breadcrumbsItems={[
        {
          name: 'Usuários',
        },
        {
          name: 'Adicionar Usuário',
        },
      ]}
    >
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <Container>
          <Form onSubmit={handleSubmit}>
            <ContainerForm>
              <FormLineInput>
                <ContainerInput>
                  <Input
                    name="fullname"
                    label="Nome completo *"
                    width="100%"
                    placeholder="Digite o nome completo"
                  />
                </ContainerInput>
                <SelectContainer>
                  <SelectComponent
                    label="Perfil *"
                    size="custom"
                    options={options}
                    value={options.find(
                      obj => obj.value === state.selectedRole,
                    )}
                    onChange={handleChange}
                    isSearchable={false}
                  />
                </SelectContainer>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput>
                  <Input
                    name="password"
                    label="Senha *"
                    width="100%"
                    type="password"
                    placeholder="Digite sua senha"
                    note="(Pelo menos 8 caracteres, entre letras e números)"
                  />
                </ContainerInput>
                <ContainerInput>
                  <Input
                    name="confirm_password"
                    label="Confirme sua Senha *"
                    width="100%"
                    type="password"
                    placeholder="Digite sua senha"
                    note="Repita a senha"
                  />
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput>
                  <InputMask mask="999.999.999-99">
                    {() => (
                      <Input
                        name="cpf"
                        label="CPF *"
                        width="100%"
                        placeholder="Digite seu CPF"
                      />
                    )}
                  </InputMask>
                </ContainerInput>
                <ContainerInput>
                  <Input
                    name="email"
                    label="Email *"
                    width="100%"
                    placeholder="Digite seu email"
                  />
                </ContainerInput>
              </FormLineInput>
              <FormLineInput>
                <ContainerInput>
                  <InputMask mask="(99) 99999-9999">
                    {() => (
                      <Input
                        name="phone"
                        label="Telefone *"
                        width="100%"
                        placeholder="Digite seu telefone"
                      />
                    )}
                  </InputMask>
                </ContainerInput>
              </FormLineInput>
            </ContainerForm>
            <ContainerButton>
              <Button
                full
                type="button"
                size="default"
                color="cancel"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button full size="default" color="primary" type="submit">
                Adicionar usuário
              </Button>
            </ContainerButton>
          </Form>
        </Container>
      )}
    </PageContent>
  )
}

export { UserCreate }
