import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import shortid from 'shortid'
import validator from 'validator'

import { ReportStepStatusEnum } from '@/@types/clientReportSteps/clientReportSteps'
import { FullScreenLoader } from '@/components/FullScreenLoader'
import { deleteFiles } from '@/components/ReportForm/providers/deleteReportFiles/deleteReportFiles'
import Button from '@/components/Ui/Button'
import { GenericModal } from '@/components/Ui/GenericModal'
import ManageFiles, { IFile } from '@/components/Ui/ManageFiles'
import { StepDetailTitle } from '@/components/Ui/StepDetailTitle'
import { uploadFiles } from '@/providers/uploadFiles/uploadFiles'
import api from '@/services/api'
import { RootState } from '@/store'
import { ReportCreators } from '@/store/ducks/ClientReport'
import { IClientReportStepData } from '@/store/ducks/ClientReport/types'

import { Container, ContainerButton, Header, EmptyImage } from './styles'

interface IResponseUploadFile {
  key: string
  url: string
}

interface IAttibutesDeleteStepImage {
  step: number
  deleteFile: IFile
}

interface IReportOneProps {
  // eslint-disable-next-line
  isDisabled?: boolean
}

const ReportOne: React.FC<IReportOneProps> = ({ isDisabled }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [attributesDeleteStepImage, setAttributesDeleteStepImage] = useState<
    IAttibutesDeleteStepImage
  >()
  const [newSteps, setNewSteps] = useState<
    IClientReportStepData[] | undefined
  >()

  useEffect(() => {
    dispatch(ReportCreators.getReportOneRequest())
  }, [dispatch])

  const reportOne = useSelector(
    (state: RootState) => state.ClientReport.clientReportOne,
  )
  const isGetLoading = useSelector(
    (state: RootState) => state.ClientReport.isLoading,
  )
  const client = useSelector((state: RootState) => state.Client.client)

  const handleSubmitReport = useCallback(() => {
    api
      .patch(`/admin/customers/${client.id}/reports/1`, {
        status: ReportStepStatusEnum.APPROVED,
      })
      .then(() => {
        toast.success('Relatório atualizado com sucesso!')
        setTimeout(() => {
          dispatch(ReportCreators.setIsReportOne(false))
        }, 3000)
      })
      .catch(() => toast.error('Não foi possível editar o relatório!'))
  }, [client, dispatch])

  useEffect(() => {
    reportOne && setNewSteps(reportOne.steps)
  }, [reportOne])

  const handleSaveImage = (r: IClientReportStepData[] | undefined) => {
    try {
      const clientReportOne = {
        status: ReportStepStatusEnum.PENDING,
        steps: r?.map(step => {
          const images = step.images.map(image => {
            return {
              id: validator.isUUID(image.id) ? image.id : undefined,
              url: image.url,
              client_report_step_id: step.id,
            }
          })
          return {
            images,
          }
        }),
      }
      dispatch(ReportCreators.editReportSaveImageRequest(clientReportOne))
    } catch (error) {
      toast.error('Não foi possível editar o relatório')
    }
  }

  const handleSendFile = async (step: number, file: File | null) => {
    try {
      if (file !== null) {
        setIsLoading(true)
        const formData = new FormData()
        formData.append('file', file)
        const uploadResponse: IResponseUploadFile = await uploadFiles(formData)
        const uploadFile = {
          id: shortid.generate(),
          url: uploadResponse.url,
        }

        const r = newSteps?.map(newStep => {
          const images =
            newStep.step === step
              ? [...newStep.images, uploadFile]
              : newStep.images
          return {
            ...newStep,
            images,
          }
        })

        setNewSteps(
          prevNewSteps =>
            prevNewSteps &&
            prevNewSteps.map(newStep => {
              const images =
                newStep.step === step
                  ? [...newStep.images, uploadFile]
                  : newStep.images
              return {
                ...newStep,
                images,
              }
            }),
        )
        handleSaveImage(r)
      }
    } catch (error) {
      toast.error('Não foi possível fazer o upload do arquivo.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteFile = async (step: number, deleteFile: IFile) => {
    try {
      setIsLoading(true)
      if (validator.isUUID(deleteFile.id)) {
        await deleteFiles(deleteFile.id)
      }
      setNewSteps(
        prevNewSteps =>
          prevNewSteps &&
          prevNewSteps.map(newStep => {
            const images =
              newStep.step === step
                ? newStep.images.filter(image => image.id !== deleteFile.id)
                : newStep.images
            return {
              ...newStep,
              images,
            }
          }),
      )
    } catch (error) {
      toast.error('Não foi possível apagar o arquivo.')
    } finally {
      setIsLoading(false)
    }
  }

  const steps = newSteps?.map(item => {
    return (
      <div>
        <StepDetailTitle number={item.step.toString()} title={item.description}>
          <>
            {isDisabled && !item.images?.length ? (
              <EmptyImage>Sem imagem.</EmptyImage>
            ) : (
              <ManageFiles
                isDisabled={isDisabled}
                onDeleteFile={deleteFile => {
                  setAttributesDeleteStepImage({
                    step: item.step,
                    deleteFile,
                  })
                  setIsOpenDeleteModal(true)
                }}
                onUploadFile={file => handleSendFile(item.step, file)}
                fileList={item.images.map(image => {
                  return {
                    id: image.id,
                    type: 'image',
                    url: image.url,
                  }
                })}
              />
            )}
          </>
        </StepDetailTitle>
      </div>
    )
  })

  const handleOnClose = () => {
    setIsOpenDeleteModal(false)
  }
  const handleOnClickConfirm = () => {
    setIsOpenDeleteModal(false)
    if (attributesDeleteStepImage) {
      handleDeleteFile(
        attributesDeleteStepImage.step,
        attributesDeleteStepImage.deleteFile,
      )
    }
  }

  return (
    <>
      {(isGetLoading || isLoading) && <FullScreenLoader />}
      <Container>
        {isOpenDeleteModal && (
          <GenericModal
            onClose={handleOnClose}
            openModal={isOpenDeleteModal}
            onClickConfirm={handleOnClickConfirm}
            label="Deseja deletar a imagem?"
          />
        )}
        <Header>
          <h1>Relatório - Etapa 1</h1>
        </Header>
        {steps}
        {isDisabled ? (
          <ContainerButton>
            <Button
              full
              size="default"
              color="primary"
              type="submit"
              onClick={() => dispatch(ReportCreators.setIsReportOne(false))}
            >
              Voltar
            </Button>
          </ContainerButton>
        ) : (
          <ContainerButton>
            <Button
              full
              size="default"
              color="cancel"
              type="button"
              onClick={() => dispatch(ReportCreators.setIsReportOne(false))}
            >
              Voltar
            </Button>
            <Button
              full
              color="primary"
              size="default"
              onClick={handleSubmitReport}
            >
              Concluir Etapa 1
            </Button>
          </ContainerButton>
        )}
      </Container>
    </>
  )
}

export { ReportOne }
