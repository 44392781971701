import React from 'react'

import {
  LinearProgress,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import * as CouponTypes from './types'

interface ICouponCreateDialog {
  open: boolean
  disabledActions: boolean
  onClose: () => void
  onConfirm: ({
    coupon_code,
    coupon_ref,
  }: {
    coupon_code: CouponTypes.ICoupon['coupon_code']
    coupon_ref: CouponTypes.ICoupon['coupon_ref']
  }) => void
}

const CouponCreateDialog: React.FC<ICouponCreateDialog> = ({
  open,
  onClose,
  onConfirm,
  disabledActions,
}) => {
  const [code, setCode] = React.useState<string>('')
  const [refCode, setRefCode] = React.useState<string>('')

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Criar cupom</DialogTitle>
        <DialogContent>
          <TextField
            label="Código do Cupom *"
            value={code}
            variant="outlined"
            onChange={e => setCode(e.target.value)}
            disabled={disabledActions}
          />

          <TextField
            label="Referência *"
            value={refCode}
            variant="outlined"
            onChange={e => setRefCode(e.target.value)}
            disabled={disabledActions}
          />
        </DialogContent>

        {disabledActions && <LinearProgress />}

        <DialogActions>
          <Button disabled={disabledActions} onClick={onClose} color="primary">
            Cancelar
          </Button>

          <Button
            disabled={disabledActions || !code || !refCode}
            onClick={() =>
              onConfirm({ coupon_code: code, coupon_ref: refCode })
            }
            color="primary"
            autoFocus
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CouponCreateDialog
