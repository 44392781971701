/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'

import * as Yup from 'yup'

import { FullScreenLoader } from '@/components/FullScreenLoader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Ui/Button'
import Input from '@/components/Ui/Form/Input'
import { InputNumberMask } from '@/components/Ui/InputNumberMask'
import { Select } from '@/components/Ui/Select'
import { useWindowSize } from '@/hooks/useWindowSize'
import api from '@/services/api'
import history from '@/services/history'

import * as S from './styles'

type FormData = {
  bill_value: string
  city: number
  coupon: string | null
  email: string
  hasPartnership: boolean
  name: string
  phone: string
  unit_type: string
}

type StateSelectType = {
  value: string
  label: string
}

const estadosSP: StateSelectType[] = [
  { value: 'SP', label: 'SP' },
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
]

const CustomerCreate: FC = () => {
  const { width } = useWindowSize()

  const [cities, setCities] = useState<any[]>([])
  const [city, setCity] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [bill_value, setBillValue] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleChangeAmount = (value: number) => {
    setBillValue(value)
  }

  const handleSubmit = async (data: FormData) => {
    try {
      setIsLoading(true)
      const { email, name, phone } = data

      const createUserProfile = {
        bill: bill_value,
        city,
        state,
        email,
        name,
        phone: `55${phone}`,
        coupon: null,
        hasPartnership: false,
        unit_type: 'Casa com telhado livre',
      }

      const userSchema = Yup.object().shape({
        email: Yup.string()
          .email('Email inválido')
          .required('Email obrigatório'),
        name: Yup.string().required('Nome obrigatório'),
        bill: Yup.string().required('Valor da conta de luz obrigatória'),
        phone: Yup.string().required('Telefone obrigatório'),
        city: Yup.string().required('Cidade obrigatório'),
      })

      await userSchema.validate(createUserProfile, {
        abortEarly: false,
      })

      await api
        .post(`/portal/customers/franchisee`, createUserProfile)
        .then(() => {
          history.push('/customer')
          toast.success('Cliente cadastrado com sucesso!')
          setIsLoading(false)
        })
        .catch(() => {
          toast.error('Cliente já cadastrado!')
          setIsLoading(false)
        })
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setIsLoading(false)
        toast.error(err.errors[0])
      }
    }
  }

  const getCities = async (state: string) => {
    await api.get(`/portal/cities/${state}`).then(({ data }) => {
      setCities(data.data)
    })
  }

  const handleCancel = () => {
    history.push('/customer')
  }

  const handleChangeState = (state: StateSelectType) => {
    getCities(state.value)
    setState(state.value)
  }

  return (
    <PageContent
      title="Adicionar novo cliente"
      breadcrumbsItems={[
        {
          name: 'Cliente',
        },
        {
          name: 'Adicionar novo cliente',
        },
      ]}
    >
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <S.Container>
          <S.Form onSubmit={handleSubmit}>
            <S.ContainerForm>
              <S.FormLineInput>
                <S.ContainerInput width="700px" marginRight="35px">
                  <Input
                    name="name"
                    label="Nome completo *"
                    width="100%"
                    placeholder="Digite o nome completo"
                  />
                </S.ContainerInput>
                <S.ContainerInput width="700px">
                  <Input
                    name="email"
                    label="Email *"
                    width="100%"
                    placeholder="Digite seu email"
                  />
                </S.ContainerInput>
              </S.FormLineInput>
            </S.ContainerForm>
            <S.ContainerForm>
              <S.FormLineInput>
                <S.ContainerInput width="700px" marginRight="35px">
                  <InputMask mask="(99) 99999-9999">
                    {() => (
                      <Input
                        name="phone"
                        label="Telefone celular*"
                        width="100%"
                        placeholder="Digite o telefone celular"
                      />
                    )}
                  </InputMask>
                </S.ContainerInput>
                <S.ContainerInput width="700px">
                  <InputNumberMask
                    label="Valor da conta *"
                    onChange={handleChangeAmount}
                    prefix="R$ "
                    decimalSymbol=","
                    decimalLimit={2}
                  />
                </S.ContainerInput>
              </S.FormLineInput>
            </S.ContainerForm>
            <S.ContainerForm>
              <S.FormLineInputCities>
                <S.SelectContainer>
                  <Select
                    label="Estado"
                    placeHolder="Selecione o estado"
                    size={width && width > 1100 ? 'medium' : 'custom'}
                    options={estadosSP}
                    onChange={handleChangeState as any}
                    isSearchable={false}
                  />
                </S.SelectContainer>

                <S.ContainerInput width="350px">
                  <label>Cidade</label>
                  <S.AutoCompleteStyled
                    disabled={!cities.length}
                    options={cities}
                    getOptionLabel={(option: any) => {
                      return option.name
                    }}
                    onChange={(event, value: any) => {
                      setCity(value.city_id)
                    }}
                    getOptionSelected={(option: any) => option.city_id}
                    renderInput={params => {
                      return (
                        <S.CssTextField
                          placeholder="Selecione a cidade"
                          {...params}
                          variant="outlined"
                        />
                      )
                    }}
                  />
                </S.ContainerInput>
              </S.FormLineInputCities>
            </S.ContainerForm>
            <S.ContainerButton>
              <Button
                full={!!width && width <= 1100}
                size="default"
                color="primary"
                onClick={handleCancel}
              >
                Voltar
              </Button>
              <Button
                full={!!width && width <= 1100}
                size="default"
                color="primary"
                type="submit"
              >
                Adicionar cliente
              </Button>
            </S.ContainerButton>
          </S.Form>
        </S.Container>
      )}
    </PageContent>
  )
}

export { CustomerCreate }
