import styled from 'styled-components'

import theme from '@/assets/styles/theme'

interface NavProps {
  bottom?: boolean
}

interface ContainerProps {
  isOpen: boolean
}

interface NavOptionsContainerProps {
  height?: number
}

export const Container = styled.aside<ContainerProps>`
  background-color: ${theme.colors.gray[600]};

  color: ${theme.colors.white};
  grid-area: sidenav;
  height: 100%;
  overflow-y: hidden;
  position: fixed;
  transition: all 0.6s ease-in-out;
  transform: ${props =>
    props.isOpen ? 'translateX(0)' : 'translateX(-350px)'};
  z-index: 2;
  width: 350px;

  @media only screen and (min-width: ${theme.breakpoints.sm}) {
    position: relative;
    transform: translateX(0);
  }
`

export const Logo = styled.div`
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.white};
  display: flex;
  min-height: 105px;

  @media only screen and (max-width: 749px) {
    min-height: 0;
    height: 65px;

    & > img {
      display: none;
    }
  }
`

export const NavOptionsContainer = styled.div<NavOptionsContainerProps>`
  display: flex;
  flex-direction: column;

  /* Fills the entire screen height minus the header height */
  height: calc(
    100vh - ${({ height }) => (height && height < 700 ? '64px' : '105px')}
  );
  overflow-y: auto;

  /* Hide default scrollbar on WebKit browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Customize the thumb of the scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  /* Thumb color on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #666;
  }

  /* Customize the track of the scrollbar */
  &::-webkit-scrollbar-track {
    background-color: #3b3b3b;
  }

  /* For Firefox */
  scrollbar-width: thin;

  /* Thumb and track colors for Firefox */
  scrollbar-color: #888 #3b3b3b;
`

// Add defaultProps to set a default value for the height prop
NavOptionsContainer.defaultProps = {
  height: 0, // Replace 0 with the default height value you want
}

export const Nav = styled.div<NavProps>`
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  > a,
  div {
    display: flex;
    align-items: center;
    color: ${theme.colors.gray[200]};
    padding: 18px 18px 18px 40px;
    text-decoration: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    &.active {
      background-color: ${theme.colors.blue[200]};
      transition: all 0.2s ease-in-out;
    }

    > img {
      height: 30px;
      width: 30px;
    }

    > span {
      font: 500 18px Raleway;
      padding-left: 20px;
    }
  }
`
